import React from 'react';
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardImage,  CFormCheck,
    CForm, CFormInput, CSpinner, CInputGroupText,
} from '@coreui/react';
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from '../../../../config/constant.json';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

export default function CreateUser(props) {
    const [loader, setLoader] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] =React.useState(false);

    const userSchema = yup.object().shape({
        name: yup.string()
            .required('Name is required'),
        email: yup.string()
            .email('Invalid email')
            .required('Email is required'),
        number: yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .required('Number is required'),
        gender: yup.string()
            .required('Gender is Required'),
        password: yup.string()
            .required('Password is required')
            .min(6, 'Password is too short - should be 6 chars minimum.')
            .matches(/(?=.*[0-9])/, "Password must contain a number.")
            .matches(/(?=.*[a-z])/, "Password must contain a lowercase letter.")
            .matches(/(?=.*[A-Z])/, "Password must contain an uppercase letter.")
            .matches(/(?=.*[!@#$%^&*])/, "Password must contain a special character."),
        confirmpassword: yup.string()
            .required('Confirm Password is required')
            .when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf(
                    [yup.ref("password")],
                    "Both passwords need to be the same"
                )
            }),
        roleType: yup.string()
            .required('Role is required'),
    });

    const handleCreateUser = async (values) => {
        try {
            setLoader(true);

            let data = {
                name: values.name,
                email: values.email,
                number: values.number,
                gender:  values.gender,
                password: values.password,
                roleType: values.roleType
            };

            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/user/createUser`, { data });
            if (response.data.status === true) {
                setLoader(false);
                props.addToaster({
                    "status": true,
                    "body": "User Created Successfully",
                    "message": "Success"
                });
                props.onClose();
            } else {
                setLoader(false);
                props.addToaster({
                    "status": false,
                    "body": response.data.message,
                    "message": "Error"
                });
            }
        } catch (error) {
            console.log(error.message);
            props.addToaster({
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            });
            props.onClose();
        }
    };

    return (
        <CModal size="lg" visible={props.openCreateUser} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Create User</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CCardBody>
                    <Formik
                        initialValues={{ name: '', email: '', number: '', gender: '', password: '', confirmpassword: '', roleType: '' }}
                        validationSchema={userSchema}
                        onSubmit={(values) => {
                            handleCreateUser(values);
                        }}
                    >
                        {(formikProps) => (
                            <CForm>
                                <CRow className="mb-3">
                                    <CCol md="6">
                                        <CInputGroup >
                                            <legend>Full Name</legend>
                                            <CFormInput
                                                placeholder="Enter name"
                                                autoComplete="name"
                                                onChange={formikProps.handleChange('name')}
                                                value={formikProps.values.name}
                                                onBlur={formikProps.handleBlur('name')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.name && formikProps.errors.name}</p>
                                        </CCol>
                                        <CCol md="6">
                                        <CInputGroup>
                                            <legend>Email</legend>
                                            <CFormInput
                                                placeholder="Enter email"
                                                autoComplete="email"
                                                type='email'
                                                onChange={formikProps.handleChange('email')}
                                                value={formikProps.values.email}
                                                onBlur={formikProps.handleBlur('email')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.email && formikProps.errors.email}</p>
                                        </CCol>
                                        </CRow>

                                <CRow className="mb-3">
                                    <CCol md="6">
                                        <CInputGroup >
                                            <legend>Number</legend>
                                            <CFormInput
                                                placeholder="Enter number"
                                                autoComplete="number"
                                                type='number'
                                                onChange={formikProps.handleChange('number')}
                                                value={formikProps.values.number}
                                                onBlur={formikProps.handleBlur('number')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.number && formikProps.errors.number}</p>
                                        </CCol>
                                        <CCol md="6">
                                        <CInputGroup >
                                            <legend>Gender</legend>
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Male')} checked={formikProps.values.gender === 'Male'} type="radio" inline name="flexRadioDefault" id="Male" label="Male" />
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Female')} checked={formikProps.values.gender === 'Female'} type="radio" inline name="flexRadioDefault" id="Female" label="Female" />
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Other')} checked={formikProps.values.gender === 'Other'} type="radio" inline name="flexRadioDefault" id="Transgender" label="Other" />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.gender && formikProps.errors.gender}</p>
                                        </CCol>
                                </CRow>

                                  
                                <CRow className="mb-3">
                                <CCol md="6">
                                        <CInputGroup >
                                            <legend>Password</legend>
                                            <CFormInput
                                                type={showPassword ? 'text' : 'password'}
                                                autoComplete="password"
                                                onChange={formikProps.handleChange('password')}
                                                value={formikProps.values.password}
                                                onBlur={formikProps.handleBlur('password')}
                                            />
                                            <CInputGroupText onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                                            </CInputGroupText>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.password && formikProps.errors.password}</p>
                                        </CCol>
                                        <CCol md="6">
                                        <CInputGroup >
                                            <legend>Confirm Password</legend>
                                            <CFormInput
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                autoComplete="confirmpassword"
                                                onChange={formikProps.handleChange('confirmpassword')}
                                                value={formikProps.values.confirmpassword}
                                                onBlur={formikProps.handleBlur('confirmpassword')}
                                            />
                                            <CInputGroupText onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: 'pointer' }}>
                                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </CInputGroupText>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.confirmpassword && formikProps.errors.confirmpassword}</p>
                                    </CCol>
                                </CRow>

                                {props.allRoles && props.allRoles.length > 0 &&
                                        <CRow className="mb-3">
                                        <CCol md="12">
                                            <CInputGroup >
                                                <legend>Role</legend>
                                                <CFormSelect
                                                    onChange={formikProps.handleChange('roleType')}
                                                    value={formikProps.values.roleType}
                                                    onBlur={formikProps.handleBlur('roleType')}
                                                >
                                                    <option value="" disabled>Select Role</option>
                                                    {props.allRoles.map((role, index) => (
                                                        <option key={index} value={role.roleName}>{role.roleName}</option>
                                                    ))}
                                                </CFormSelect>
                                            </CInputGroup>
                                            <p style={{ color: 'red' }}>{formikProps.touched.roleType && formikProps.errors.roleType}</p>

                                            </CCol>
                                    </CRow>
                                }
                                    
                                <CRow className="mb-3">
                                    <CCol className="d-flex justify-content-center">
                                        {!loader ?
                                            <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary btn-block">Submit</button>
                                            :
                                            <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary btn-block">
                                                <CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit
                                            </button>
                                        }
                                    </CCol>
                                </CRow>
                            </CForm>
                        )}
                    </Formik>
                </CCardBody>
            </CModalBody>
        </CModal>
    );
}
