import React, { useEffect, useState, useRef, useCallback } from "react";
import { Formik } from "formik";
import * as yup from 'yup';
import {
    CForm, CCard, CRow, CCol, CFormLabel, CFormSelect, CFormInput, CButton, CCardBody, CAlert, CFormTextarea
} from '@coreui/react';
import userApi from 'src/utils/axios';
import ConfigData from 'src/config/constant.json';
import LoadingScreen from "src/views/shared/Loading";
import CIcon from '@coreui/icons-react';
import { cilBurn, cilCheckCircle } from '@coreui/icons';
import Toaster from 'src/views/shared/Toaster';
import Select from "react-select";


// Custom debounce hook
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout if value changes or on unmount
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
};

const AddOfflinePayment = () => {
    const formRef = useRef(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [courseData, setCourseData] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [amount, setAmount] = useState();
    const [discountedAmount, setDiscountedAmount] = useState();
    const [payableAmount, setPayableAmount] = useState();
    const [couponMessage, setCouponMessage] = useState(null);
    const [couponErrorMessage, setCouponErrorMessage] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const [toast, addToast] = useState(false)
    const [options, setoptions] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State to manage loading
    const [couponId, setCouponId] = useState('');


    const debouncedInputValue = useDebounce(inputValue, 1000); // Use custom hook

    useEffect(() => {
        const fetchData = async () => {
            await getUsers('');
            await getAllCourses();
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Fetch users when the debounced input value changes
        if (debouncedInputValue !== '') {
            getUsers(debouncedInputValue); // Call API once after debounce
        }
    }, [debouncedInputValue]);

    useEffect(() => {
        // Reset remark and other fields when paymentType changes
        formRef.current && formRef.current.setValues({
            ...formRef.current.values,
            remark: '',
            utrNumber: '',
            chequeDate: '',
            chequeNumber: '',
        });
    }, [paymentType]);

    // Fetch payment data when debounced search term changes
    useEffect(() => {
        if (debouncedValue !== '') {
            handleApplyCoupon(debouncedValue);
        }

    }, [debouncedValue]);

    const moduleSchema = yup.object({
        courseId: yup.string().required('Course is required'),
        batchId: yup.string().required('Batch is required'),
        userId: yup.string().required('User is required'),
        remark: yup.string().required('remark is required'),
        couponCode: yup.string(),
        utrNumber: paymentType === 'RTGS' || paymentType === 'NEFT' ? yup.string().required('UTR Number is required') : yup.string(),
        chequeDate: paymentType === 'Cheque' ? yup.date().required('Cheque Date is required') : yup.date(),
        chequeNumber: paymentType === 'Cheque' ? yup.string().required('Cheque Number is required') : yup.string(),
    });

    const getAllCourses = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/course/getcourses`);
            if (response.data.data) {
                setCourseData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };


    const getUsers = async (value) => {
        setIsLoading(true); // Start loading
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/user/getUserData?searchTerm=${value}`);
            if (response.data.data) {
                setoptions(response.data.data.map(user => ({
                    value: user.userId,
                    label: user.fullName
                })));
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const fetchCourseData = async (courseId) => {
        try {
            setSelectedCourse(courseId);
            setBatchData([]);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getBatchByCourseId?courseId=${courseId}`);
            if (response.data.data) {
                setBatchData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchBatchData = async (batchId) => {
        try {
            setSelectedBatch(batchId);
            if (batchId && selectedCourse && selectedUser) {
                const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getAmountByBatchAndCourse?batchId=${batchId}&courseId=${selectedCourse}&userId=${selectedUser}`);
                setAmount(response.data.data);
                setPayableAmount(response.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleApplyCoupon = async (coupon) => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/couponCheck/checkCoupon?coupon=${coupon}&courseId=${selectedCourse}`);
            if (response.data.data) {
                const discount = parseInt(response.data.data[0].amount, 10);
                setDiscountedAmount(discount);

                const newAmount = parseInt(amount, 10) - discount;
                setPayableAmount(newAmount);

                setCouponErrorMessage(null);
                setCouponMessage('Coupon Applied Successfully!')
                setCouponId(response.data.data[0]._id);
            } else {
                setCouponMessage(null);
                setCouponErrorMessage(response.data.message);
                formRef.current && formRef.current.setValues({
                    ...formRef.current.values,
                    couponCode: ''
                });
                setCouponId('');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPaymentMethod = (type) => {
        setPaymentType(type);
    }

    // Handle coupon input change with debounce
    const handleCoupon = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }


    const handleSave = async (values) => {

        const data = {
            amount: payableAmount,
            merchant_param1: values.userId, //userId
            merchant_param2: values.courseId, //courseId
            merchant_param3: values.batchId,  //batchId
            merchant_param4: couponId ? couponId : 'noCoupon', //couponCode
            merchant_param5: 'LMS',
            paymentMode: paymentType,
            cancel_url: `${ConfigData.paymentRedirectUrl}/v1/payment/cancel-payment`,
            redirect_url: `${ConfigData.paymentRedirectUrl}/v1/payment/offline-payment`,
            paydate: values.chequeDate ? values.chequeDate : '',
            tracking_id: paymentType === 'CHEQUE' ? values.chequeNumber : (paymentType === 'NEFT' ? values.utrNumber : (paymentType === 'RTGS' ? values.utrNumber : '')),
            remark: values.remark,
            portal: 'LMS',
            college_code: 1,
        }
        const response = await userApi.post(`${ConfigData.paymentUrl}`, data);
        if (response.data.message === 'DONE') {
            resetForm();  //reset form after submitting
            setAmount("");
            setDiscountedAmount("");
            setPayableAmount("");
            setInputValue('');
            setSelectedUser('');
            setSelectedCourse('');
            setCouponId('');
            let toast = {
                "status": true,
                "body": "Payment Done Successfully",
                "message": "Success"
            }

            addToaster(toast)
        }
    };
    // reset form function to handle form reset
    const resetForm = () => {
        formRef.current.resetForm();
        setInputValue(''); // Clear search input state
        setSelectedUser(''); // Clear selected user state
        setSelectedCourse('')
    };

    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    return (
        <>
            {
                <CCard>
                    <CCardBody>
                        <Formik
                            initialValues={{ courseId: '', batchId: '', userId: '', couponCode: '', paymentType: '', remark: '', chequeNumber: '', chequeDate: '', utrNumber: '' }}
                            validationSchema={moduleSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                handleSave(values);
                            }}
                            innerRef={formRef}
                        >
                            {(formikProps) => (
                                <CForm onSubmit={formikProps.handleSubmit}>
                                    <CRow>
                                        <CCol xs={4}>
                                            <CFormLabel htmlFor="user"><b>Select Student :</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                            <Select
                                                id='selectUser'
                                                options={options}
                                                value={options.find(option => option.value === formikProps.values.userId) || null}
                                                onChange={(selectedOption) => {
                                                    formikProps.setFieldValue('userId', selectedOption ? selectedOption.value : '');
                                                    setSelectedUser(selectedOption ? selectedOption.value : '');
                                                }}
                                                isClearable={true} // Allow clearing the selection
                                                isLoading={isLoading} // Show loading spinner
                                                onInputChange={input => setInputValue(input)} // Filter on input change
                                                placeholder='Select Student'
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.userId && formikProps.errors.userId}</p>

                                        </CCol>
                                        <CCol xs={4}>
                                            <CFormLabel htmlFor="course"><b>Select Course :</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                            <CFormSelect
                                                id='selectCourse'
                                                onChange={(event) => {
                                                    formikProps.setFieldValue('courseId', event.target.value);
                                                    fetchCourseData(event.target.value);
                                                }}
                                                value={formikProps.values.courseId}
                                                disabled={!selectedUser}
                                                style={{ cursor: !selectedUser ? 'not-allowed' : 'auto' }}
                                            >
                                                <option disabled value=''>Select Course</option>
                                                {courseData.map((course, index) => (
                                                    <option key={index} value={course._id}>{course.courseName}</option>
                                                ))}
                                            </CFormSelect>
                                            <p style={{ color: 'red' }}>{formikProps.touched.courseId && formikProps.errors.courseId}</p>
                                        </CCol>
                                        <CCol xs={4}>
                                            <CFormLabel htmlFor="batch"><b>Select Batch :</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                            <CFormSelect
                                                id='selectBatch'
                                                onChange={(event) => {
                                                    formikProps.setFieldValue('batchId', event.target.value);
                                                    fetchBatchData(event.target.value);
                                                }}
                                                value={formikProps.values.batchId}
                                                disabled={!selectedCourse}
                                                style={{ cursor: !selectedCourse ? 'not-allowed' : 'auto' }}
                                            >
                                                <option disabled value=''>Select Batch</option>
                                                {batchData.map((batch, index) => (
                                                    <option key={index} value={batch._id}>{batch.batchName}</option>
                                                ))}
                                            </CFormSelect>
                                            <p style={{ color: 'red' }}>{formikProps.touched.batchId && formikProps.errors.batchId}</p>
                                        </CCol>
                                    </CRow>
                                    {amount && <>
                                        <CRow>
                                            <CCol xs={4}>
                                                <CFormLabel><b>Enter Coupon Code:</b></CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    placeholder="Enter Coupon Code"
                                                    aria-label="default input example"
                                                    name="couponCode"
                                                    onChange={(event) => { formikProps.setFieldValue('couponCode', event.target.value), handleCoupon(event) }}
                                                    value={formikProps.values.couponCode}
                                                />
                                            </CCol>
                                            <CCol xs={8}>
                                                <div style={{ paddingTop: '15px' }}>
                                                    {couponMessage &&
                                                        <CAlert color="success" className="d-flex align-items-center">
                                                            <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
                                                            <div>{couponMessage}</div>
                                                        </CAlert>
                                                    }
                                                    {couponErrorMessage &&
                                                        <CAlert color="danger" className="d-flex align-items-center">
                                                            <CIcon icon={cilBurn} className="flex-shrink-0 me-2" width={24} height={24} />
                                                            <div>{couponErrorMessage}</div>
                                                        </CAlert>
                                                    }
                                                </div>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol xs={4}>
                                                <CFormLabel><b>Total Amount:</b></CFormLabel>
                                                <CFormInput
                                                    type="number"
                                                    placeholder="Total Amount"
                                                    readOnly
                                                    value={amount}
                                                />
                                            </CCol>
                                            <CCol xs={4}>
                                                <CFormLabel><b>Discounted Amount:</b></CFormLabel>
                                                <CFormInput
                                                    type="number"
                                                    placeholder="Discounted Amount"
                                                    readOnly
                                                    value={discountedAmount || 0}
                                                />
                                            </CCol>
                                            <CCol xs={4}>
                                                <CFormLabel><b>Total Payable Amount:</b></CFormLabel>
                                                <CFormInput
                                                    type="number"
                                                    placeholder="Payable Amount"
                                                    readOnly
                                                    value={payableAmount}
                                                />
                                            </CCol>

                                        </CRow>
                                        <CRow>
                                            <CCol xs={4}>
                                                <CFormLabel><b>Select Payment Type:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                                <CFormSelect
                                                    aria-label="Default select example"
                                                    name="paymentType"
                                                    onChange={(event) => { formikProps.setFieldValue('paymentType', event.target.value), fetchPaymentMethod(event.target.value) }}
                                                    value={formikProps.values.paymentType}
                                                >
                                                    <option disabled value=''>Select Payment Type</option>
                                                    <option value="CASH">Cash</option>
                                                    <option value="CHEQUE">Cheque</option>
                                                    <option value="NEFT">NEFT</option>
                                                    <option value="RTGS">RTGS</option>
                                                    <option value="UPI">UPI</option>
                                                </CFormSelect>
                                                <p style={{ color: 'red' }}>{formikProps.touched.paymentType && formikProps.errors.paymentType}</p>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            {
                                                (paymentType === 'RTGS' || paymentType === 'NEFT') &&
                                                (<CCol xs={4}>
                                                    <CFormLabel><b>UTR Number :</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                                    <CFormInput
                                                        type="text"
                                                        placeholder="Enter UTR Number"
                                                        aria-label="default input example"
                                                        name="utrNumber"
                                                        onChange={formikProps.handleChange}
                                                        value={formikProps.values.utrNumber}
                                                    />
                                                </CCol>)
                                            }
                                            {
                                                paymentType === 'CHEQUE' &&
                                                <>

                                                    <CCol xs={4}>
                                                        <CFormLabel><b>Cheque Date :</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                                        <CFormInput
                                                            type="date"
                                                            placeholder="Cheque Date"
                                                            aria-label="default input example"
                                                            name="chequeDate"
                                                            onChange={formikProps.handleChange}
                                                            value={formikProps.values.chequeDate}
                                                        />
                                                    </CCol>
                                                    <CCol xs={4}>
                                                        <CFormLabel><b>Cheque Number :</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                                        <CFormInput
                                                            type="text"
                                                            placeholder="Enter Cheque Number"
                                                            aria-label="default input example"
                                                            name="chequeNumber"
                                                            onChange={formikProps.handleChange}
                                                            value={formikProps.values.chequeNumber}
                                                        />
                                                    </CCol>
                                                </>
                                            }
                                            {
                                                paymentType &&
                                                <CCol xs={4}>
                                                    <CFormLabel><b>Remark:</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                                    <CFormTextarea
                                                        id="exampleFormControlTextarea1"
                                                        placeholder="Enter Remark"
                                                        rows={3}
                                                        onChange={formikProps.handleChange}
                                                        name="remark"
                                                        value={formikProps.values.remark}
                                                    ></CFormTextarea>

                                                </CCol>
                                            }

                                        </CRow>
                                        {
                                            paymentType &&
                                            <CRow >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
                                                    <CButton
                                                        type="submit"
                                                        style={{
                                                            width: '200px',
                                                            cursor: !formikProps.isValid ? 'not-allowed' : 'auto'
                                                        }} color="success" disabled={!formikProps.isValid}>Submit</CButton>
                                                </div>
                                                {formikProps.isValid}
                                            </CRow>
                                        }
                                    </>
                                    }


                                </CForm>

                            )}

                        </Formik>
                    </CCardBody>
                </CCard>

            }
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>



    );
};

export default AddOfflinePayment;
