import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from './../../../../config/constant.json'
import { useHistory } from "react-router-dom";
import {
    CCard, CButton, CCardBody, CCol, CRow, CNav, CNavItem, CNavLink, CTabContent, CTabPane,
    CCardTitle, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CTable,
    CTableHead, CTableBody, CTableHeaderCell, CTableRow, CTableDataCell
} from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Link } from 'react-router-dom';
import AddModule from './AddModule';
import DeleteDailog from '../DeleteDailog';
import EditModule from './EditModule';
import Resources from '../Video/Resources'
import EditVideo from '../Video/EditVideo';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import "./style.css"
import CIcon from '@coreui/icons-react'
import { cilPencil, cilMinus } from '@coreui/icons'
import AddVideo from '../Video/AddVideo';
import Batch from '../Batch/Batch';
import CourseResources from './CourseResources';
import moment from 'moment';
import Page500 from '../../page500/Page500';


const Module = (props) => {
    if (!props.location.state) {
        return <Page500></Page500>
    }
    const [modalState, setModalState] = React.useState(false);
    const [batchState, setBatchState] = React.useState(false);
    const [editModalState, setEditModalState] = React.useState(false);
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [moduleId, setModuleId] = React.useState([]);
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [published, setPublished] = React.useState(props.location.state.published)
    const [addResources, setAddResources] = React.useState(false);
    const [previewState, setPreviewState] = React.useState(false)
    const [teacherData, setTeacherData] = React.useState(null)
    const [previewData, setPreviewData] = React.useState([])
    const [editModal, setEditModal] = React.useState(false)
    const [videoDetails, setVideoDetails] = React.useState(null)
    const [videoId, setVideoId] = React.useState([]);
    const [moduleIndex, setModuleIndex] = React.useState(null)
    const [videoIndex, setVideoIndex] = React.useState(null)
    const [deleteVideoDailog, setDeleteVideoDailog] = React.useState(false);
    const [videoForm, setVideoForm] = React.useState(false);
    const [showTable, setShowTable] = React.useState(true);
    const [resourceModal, setResourceModal] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState(props.location.state.activekey == 2 ? 2 : 1);
    const [role, setRole] = React.useState(true);


    let courseId = props.location.state.courseId
    let courseName = props.location.state.courseName
    let instituteId = props.location.state.instituteId
    let instituteName = props.location.state.instituteName

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])


    React.useEffect(async () => {
        try {
            // Gets all the courses by Course id
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/module/getpreviewdata`, { courseId })
            setPreviewData(response.data.data)
            setTeacherData(response.data.teachers)

            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    // Delete module 
    const handleDelete = async () => {
        try {

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/module/deletemodule`, { moduleId })


            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setPreviewData(previewData.filter((item) => { return item.module._id !== moduleId._id }))
            setDeleteDailog(false)
        } catch (e) {
            console.log(e)
        }

    }
    // Delete module with all associated data
    const handleAllDelete = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/module/deletemodulealldata`, { moduleId })


            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setPreviewData(previewData.filter((item) => { return item.module._id !== moduleId._id }))
            setDeleteDailog(false)

        } catch (e) {
            console.log(e)

        }


    }
    // Delete video route
    const handleDeleteVideo = async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/video/deletevideo`, { videoId })
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            setPreviewData(previewData.map((element) => { return { ...element, videos: element.videos.filter((video) => video._id !== videoId._id) } }))
            setDeleteVideoDailog(false)
        } catch (e) {
            console.log(e)
        }

    }

    const handlePublish = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/course/coursepublish`, { courseId, published })
            setPublished(!published)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
        } catch (e) {
            console.log(e)
        }
    }



    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Add Institute Callback
    let addModuleCallBack = (newmodule) => {
        let data = { module: { ...newmodule }, videos: [] }
        setPreviewData(previewData => [...previewData, data])
        setModalState(false)
    }



    let deleteModule = (option) => {
        setModuleId(option)
        setDeleteDailog(true)
    }

    let editModule = (option, index) => {
        setModuleId(option)
        setModuleIndex(index)
        setEditModalState(true)
    }

    let editModuleCallBack = (editedModule) => {
        let tempArray = [...previewData]
        let tempElement = { ...previewData[moduleIndex].module, ...editedModule }
        tempArray[moduleIndex].module = tempElement
        setPreviewData(tempArray)
        setEditModalState(false)
    }


    let history = useHistory()

    // New card menu item states & function
    const [openIndex, setOpenIndex] = React.useState(-1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenIndex(index); // set current menu index to open

    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    let [selectedUser, setSelectedUser] = React.useState({});

    const callBackVideos = async (videos) => {
        setSelectedUser(videos);
        setAddResources(!addResources)

    }

    let editVideo = (item, index, index2) => {
        setEditModal(true)
        setVideoDetails(item)
        setModuleIndex(index)
        setVideoIndex(index2)
    }

    let editVideoCallBack = (editedVideo) => {
        let tempArray = [...previewData]
        let tempElement = { ...previewData[moduleIndex].videos[videoIndex], ...editedVideo }
        tempArray[moduleIndex].videos[videoIndex] = tempElement
        setPreviewData(tempArray)
        setEditModal(false)
    }

    let deleteVideo = (item) => {
        setDeleteVideoDailog(true)
        setVideoId(item)
    }

    // video from function
    const handleVideoForm = (moduleId, index) => {
        setModuleId(moduleId)
        setShowTable(false)
        setVideoForm(true)
        setModuleIndex(index)
    }


    const addVideoCallBack = (newvideo) => {
        let tempArray = [...previewData]
        let newVideos = [...previewData[moduleIndex].videos, newvideo]
        tempArray[moduleIndex].videos = newVideos
        setPreviewData(tempArray)
    }

    // view resources function
    const viewResources = (item) => {
        setVideoId(item._id)
        setResourceModal(true)
    }


    return (

        <div style={{ margin: 20 }}>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "#fff" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={1}><CButton onClick={() => history.push({ pathname: '/pages/master/course', state: { instituteName: instituteName, instituteId: instituteId } })} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Back</CButton></CCol>
                                <CCol sm={10}> <CCardTitle style={{ color: "#000", textAlign: 'center', textTransform: "capitalize" }}>{courseName}</CCardTitle></CCol>
                                <CCol sm={1}><IconButton aria-label="settings" onClick={handleClick()} style={{ color: 'rgb(214, 178, 49)' }}>
                                    <MoreVertIcon
                                        id="fade-button"
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}

                                    />
                                </IconButton>
                                </CCol>
                                {role &&

                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        {activeKey == 1 && <MenuItem onClick={() => { setModalState(true), setAnchorEl(null) }}>Add Module</MenuItem>}
                                        {activeKey == 2 && <MenuItem onClick={() => { setBatchState(true), setAnchorEl(null) }}>Add batch</MenuItem>}
                                    </Menu>
                                }


                                {
                                    modalState &&
                                    <AddModule
                                        visible={modalState}
                                        onClose={() => setModalState(false)}
                                        addToaster={addToaster}
                                        addModuleCallBack={addModuleCallBack}
                                        courseId={courseId}
                                    >
                                    </AddModule>
                                }

                                {
                                    editModalState &&
                                    <EditModule
                                        visible={editModalState}
                                        onClose={() => setEditModalState(false)}
                                        addToaster={addToaster}
                                        modalId={moduleId}
                                        editModuleCallBack={editModuleCallBack}
                                        courseId={courseId}
                                    >
                                    </EditModule>
                                }
                                {
                                    deleteDailog && <DeleteDailog
                                        visible={deleteDailog}
                                        onClose={() => setDeleteDailog(false)}
                                        delete={handleDelete}
                                        allDelete={handleAllDelete}
                                    ></DeleteDailog>
                                }
                                {/* curent changes */}
                                {
                                    addResources &&
                                    <Resources
                                        visible={addResources}
                                        onClose={() => setAddResources(false)}
                                        addToaster={addToaster}
                                        currentUser={selectedUser}
                                    >
                                    </Resources>
                                }
                                {
                                    editModal &&
                                    <EditVideo
                                        visible={editModal}
                                        onClose={() => setEditModal(false)}
                                        addToaster={addToaster}
                                        videoDetails={videoDetails}
                                        editVideoCallBack={editVideoCallBack}
                                        teacherData={teacherData}
                                    >
                                    </EditVideo>
                                }
                                {
                                    deleteVideoDailog && <DeleteDailog
                                        visible={deleteVideoDailog}
                                        onClose={() => setDeleteVideoDailog(false)}
                                        delete={handleDeleteVideo}
                                    ></DeleteDailog>
                                }
                                {
                                    resourceModal &&
                                    <CourseResources                    // location   /pages/Master/Module/CourseResources
                                        visible={resourceModal}
                                        onClose={() => setResourceModal(false)}
                                        courseCompo='courseCompo'
                                        video_id={videoId}>
                                    </CourseResources>
                                }
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <br />
            <CNav variant="underline" layout="fill">
                <CNavItem>
                    <CNavLink
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                            fontWeight: activeKey === 1 ? '700' : '500',
                            fontSize: activeKey === 1 ? '20px' : '18px',
                        }}
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                    >
                        Available Modules
                    </CNavLink>
                </CNavItem>

                <CNavItem>
                    <CNavLink
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                            fontWeight: activeKey === 2 ? '700' : '500',
                            fontSize: activeKey === 2 ? '20px' : '18px',
                        }}
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                    >
                        Available Batches
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 1}>

                    {!previewState && <div style={{ marginTop: "2%" }}>
                        <CRow className="justify-content-center">
                            <CCol md={11}>
                                {!videoForm && <h3>Available Modules:-</h3>}
                                {videoForm && <h3>Add Videos :-</h3>}
                            </CCol>
                            <CCol md={1}>
                                {videoForm && <button className="btn btn-outline-danger" style={{ width: '100%' }} title='back to module list' onClick={() => { setShowTable(true), setVideoForm(false) }} >Back</button>}
                            </CCol>
                        </CRow>
                    </div>}
                    {previewState && <div>
                        <br></br>
                        <h3>Preview Course:-</h3>
                        <h6>({courseName})</h6>
                        <br></br></div>}
                    {
                        videoForm &&
                        <>
                            <br />
                            <AddVideo
                                visible={videoForm}
                                onClose={() => setVideoForm(false)}
                                addToaster={addToaster}
                                addVideoCallBack={addVideoCallBack}
                                courseId={courseId}
                                moduleId={moduleId}
                                teacherData={teacherData}
                            />
                        </>
                    }

                    <CRow>
                        {
                            loading && <LoadingScreen></LoadingScreen>
                        }
                        {
                            previewData && showTable && previewData.map((option, index) => (


                                <CAccordion flush key={index} width={"100%"}>
                                    <CAccordionItem itemKey={1} >
                                        <CAccordionHeader style={{ border: '1px solid black' }}>

                                            <div style={{ flex: 1 }}>

                                                {option.module.module_serial_number} {option.module.module_name.substring(0, 50)}{option.module.module_name.length > 50 ? "..." : ""}
                                            </div>
                                            {
                                                role &&
                                                <div style={{ marginRight: 10 }} title='Add videos in Module' onClick={() => handleVideoForm(option.module._id, index)} className="btn btn-outline-primary">Add Video</div>

                                            }

                                            {/* <div style={{ marginRight: 10 }} title='Add quiz in Module' className="btn btn-outline-primary">View Quiz</div> */}
                                            {/* <Link style={{ textDecoration: "none", marginRight: 10 }} className="btn btn-outline-primary"
                                                to={{
                                                    pathname: '/pages/master/quizCourseView',
                                                    state: { courseId: courseId, moduleId: option.module._id, moduleName: option.module.module_name, teacherData: teacherData, published: published, instituteId: instituteId, instituteName: instituteName }
                                                }}>
                                                View Quiz
                                            </Link> */}
                                            {
                                                role &&
                                                <>
                                                    <CIcon style={{ marginRight: 10, color: 'blue' }} onClick={() => editModule(option.module, index)} title='Edit Module' size='lg' icon={cilPencil} />
                                                    <CIcon style={{ marginRight: 10, color: 'red' }} onClick={() => deleteModule(option.module)} title='Delete Module' size='xxl' icon={cilMinus} />
                                                </>
                                            }
                                        </CAccordionHeader>
                                        <div className='container'>
                                            {option.videos &&
                                                <CAccordionBody key={index}>
                                                    <div style={{ overflow: "auto" }}>
                                                        <CTable striped hover >
                                                            <CTableHead>
                                                                <CTableRow>
                                                                    <CTableHeaderCell scope="col">Sr. No</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">original name</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">Taught By</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">Schedule Videos</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col">Resources</CTableHeaderCell>
                                                                    {
                                                                        role &&
                                                                        <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                                                                    }

                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                {
                                                                    option.videos.length > 0 && option.videos.map((item, index2) => (
                                                                        <CTableRow key={item._id}>
                                                                            <CTableDataCell  >{item.sr_no}</CTableDataCell>
                                                                            <CTableDataCell  >{item.title}</CTableDataCell>
                                                                            <CTableDataCell  >{item.originalName}</CTableDataCell>
                                                                            <CTableDataCell  >{item.description}</CTableDataCell>
                                                                            {item.teacher_id == null ? <CTableDataCell  >null</CTableDataCell> : <CTableDataCell  >{item.teacher}</CTableDataCell>}
                                                                            <CTableDataCell  >{moment(item.scheduleDate).format('DD/MM/YYYY || HH:mm A')}</CTableDataCell>
                                                                            <CTableDataCell onClick={() => viewResources(item)} style={{ color: 'blue', cursor: 'pointer' }} ><u>View</u></CTableDataCell>
                                                                            {
                                                                                role &&

                                                                                <CTableDataCell  >
                                                                                    <button onClick={() => callBackVideos(item)} className="btn btn-outline-primary">Add Resourses</button> &nbsp;&nbsp;
                                                                                    <button onClick={() => editVideo(item, index, index2)} className="btn btn-outline-primary">Edit</button> &nbsp;&nbsp;
                                                                                    <button onClick={() => deleteVideo(item)} className="btn btn-outline-primary" >Delete</button>
                                                                                </CTableDataCell>
                                                                            }


                                                                        </CTableRow>
                                                                    ))
                                                                }
                                                            </CTableBody>
                                                        </CTable>
                                                    </div>

                                                </CAccordionBody>}

                                            {option.videos == 0 &&
                                                <CAccordionBody > No Videos are Added</CAccordionBody>
                                            }
                                        </div>
                                    </CAccordionItem>

                                </CAccordion>

                            ))

                        }

                        {
                            !loading && previewData.length == 0 && !error && <h1> No Modules Added</h1>
                        }
                        {
                            !loading && error && <h1>{error}</h1>
                        }

                    </CRow>

                    {
                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>

                    }

                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="resource-tab" visible={activeKey === 2}>
                    <Batch
                        visible={batchState}
                        onClose={() => setBatchState(false)}
                        addToaster={addToaster}
                        courseId={courseId}
                        teacherData={teacherData}
                        published={published}
                        instituteId={instituteId}
                        instituteName={instituteName}

                    ></Batch>
                </CTabPane>
            </CTabContent>
        </div>


    );
}


export default Module;