import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from './../../../../config/constant.json'
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CAccordion, CTooltip, CAccordionItem, CAccordionHeader, CAccordionBody, CTable, CTableHead, CTableBody, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ManageQuiz from './ManageQuiz';
import CIcon from '@coreui/icons-react'
import { cibIndeed } from '@coreui/icons'
import DeleteDailog from '../DeleteDailog';



const QuizCourseView = (props) => {
    const [toast, addToast] = React.useState(false)
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [teacherData, setTeacherData] = React.useState(props.location.state.teacherData)
    const [loading, setLoading] = React.useState(true);
    const [quizs, setQuizs] = React.useState([])
    const [QuizForm, setQuizForm] = React.useState(false)
    const [quizId, setQuizId] = React.useState(null)
    const [showaddquiz, setshowaddquiz] = React.useState(true);
    let history = useHistory()

    const courseId = props.location.state.courseId
    const moduleId = props.location.state.moduleId
    const moduleName = props.location.state.moduleName
    const published = props.location.state.published
    const instituteId = props.location.state.instituteId
    const instituteName = props.location.state.instituteName

    React.useEffect(async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/quiz/getquizs?module=${moduleId}`)
            if (response.data.status == false) throw Error(response.data.message)
            setQuizs(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [QuizForm])
    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    const handleQuizForm = () => {
        setQuizForm(true)
        setshowaddquiz(false)
    }
    const handleQuizTable = () => {
        setshowaddquiz(true)
        setQuizForm(false)
    }

    const handleDelete = async () => {

        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/deletequiz`, { quizId })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setQuizs(quizs.filter((item) => { return item._id !== quizId }))
            setDeleteDailog(false)
            let toast = {
                "status": true,
                "body": response.data.message,
                "message": "success"
            }
            addToaster(toast)
        } catch (e) {
            console.log(e)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    const deleteQuiz = (id) => {
        setQuizId(id)
        setDeleteDailog(true)
    }




    const isQuizVisible = async (value, id, modal_id, index) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/quizvisible`, { visible: value, id: id, modalid: modal_id })
            if (!response.data.status == true) {
                throw Error("Something went wrong");
            }
            let curentArray = [...quizs];
            for (let item of curentArray) item.visible = false
            let changedArray = { ...curentArray[index].visible = !value }
            changedArray[index] = curentArray
            setQuizs(curentArray)
            let toast = {
                "status": true,
                "body": response.data.message,
                "message": "Success"
            }
            addToaster(toast);
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast);
        }
    }

    return (
        <>
            <div style={{ margin: 20 }}>
                <CRow>
                    <CCol sm={12}>
                        <CCard style={{ background: "#3c4b64" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={1}><CButton onClick={() => history

                                        .push({
                                            pathname: '/pages/master/module',
                                            state: { courseId: courseId, moduleId: moduleId, moduleName: moduleName, published: published, instituteId: instituteId, instituteName: instituteName }
                                        })

                                    } style={{ color: "black", backgroundColor: "white", width: '100%' }} >Back</CButton></CCol>
                                    <CCol sm={9}> <CCardTitle style={{ color: "white", textAlign: 'center' }}>{moduleName}</CCardTitle></CCol>
                                    {
                                        showaddquiz ?
                                            <CCol sm={2}><CButton onClick={handleQuizForm} style={{ color: "black", backgroundColor: "white", width: '100%' }} >Add Quiz</CButton></CCol>
                                            : <CCol sm={2}><CButton onClick={handleQuizTable} style={{ color: "black", backgroundColor: "white", width: '100%' }} > Manage Quiz</CButton></CCol>
                                    }
                                </CRow >
                            </CCardBody >
                        </CCard >
                    </CCol >
                </CRow >
                <br></br>
                {
                    QuizForm &&
                    <>
                        <br />
                        <ManageQuiz
                            visible={QuizForm}
                            onClose={() => setQuizForm(false)}
                            courseId={courseId}
                            moduleId={moduleId}
                            teacherData={teacherData}
                        />
                    </>
                }
                {
                    deleteDailog && <DeleteDailog
                        visible={deleteDailog}
                        onClose={() => setDeleteDailog(false)}
                        delete={handleDelete}
                    ></DeleteDailog>
                }
                {quizs.length == 0 && !QuizForm && <><br />
                    <h2>Please add quiz</h2>
                    <br /></>}
                {!loading && !QuizForm &&
                    <>
                        {quizs && quizs.map((item, index) => (
                            <CAccordion flush key={index} width={"100%"}>
                                <CAccordionItem itemKey={1} >
                                    <CAccordionHeader style={{ border: '1px solid black' }}>

                                        <div style={{ marginRight: "1%" }}>
                                            Quiz No {index + 1}
                                        </div>

                                        <div title="Make visible quiz" className="form-check form-switch " style={{ color: 'black', fontWeight: 'bold' }} >
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl" onChange={() => isQuizVisible(item.visible, item._id, item.model_id, index)} checked={item.visible} /></div>
                                        <CIcon style={{ marginRight: 10, color: 'blue' }} title='Help to make visible to quiz on app side' size='lg' icon={cibIndeed} />
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ marginRight: 10 }} title='Delete Quiz' onClick={() => deleteQuiz(item._id)} className="btn btn-outline-danger">Delete</div>

                                    </CAccordionHeader>
                                    <div className='container'>
                                        {quizs &&
                                            <CAccordionBody key={index}>
                                                <div style={{ overflow: "auto" }}>
                                                    <CTable striped hover >
                                                        <CTableHead>
                                                            <CTableRow>
                                                                <CTableHeaderCell scope="col">Sr. No</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Question</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Correct Answer</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        <CTableBody>
                                                            {
                                                                item.questions.map((item1, index1) => (
                                                                    <CTableRow key={item1._id}>
                                                                        <CTableDataCell  >{index1 + 1}</CTableDataCell>
                                                                        <CTableDataCell  >{item1.questionTitle}</CTableDataCell>
                                                                        {item1.answerOptions.map((item2, index) => (
                                                                            item2.isCorrectAnswer && <CTableDataCell key={index}>{item2.answerBody}</CTableDataCell>
                                                                        ))}

                                                                    </CTableRow>
                                                                ))
                                                            }
                                                        </CTableBody>
                                                    </CTable>
                                                </div>

                                            </CAccordionBody>}

                                        {quizs == 0 &&
                                            <CAccordionBody > No quizs are Added</CAccordionBody>
                                        }
                                    </div>
                                </CAccordionItem>

                            </CAccordion>))}
                    </>
                }


            </div >
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    );
}



export default QuizCourseView;