import React from "react";
import userApi from "src/utils/axios";
import ConfigData from './../../../../config/constant.json'
import LoadingScreen from 'src/views/shared/Loading';
import { CCard, CCol, CRow } from '@coreui/react'
import DeleteDailog from '../DeleteDailog';
import EditBatch from "./EditBatch";
import AddBatch from "./AddBatch";
import { Link } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import moment from "moment";
import ScheduleMeeting from "./Meeting/ScheduleMeeting";
import ViewScheduledMeetings from "./Meeting/ViewScheduledMeetings";

const Batch = (props) => {
    const [batch, setBatch] = React.useState('');
    const [batchId, setBatchId] = React.useState('');
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [editDailog, setEditDailog] = React.useState(false);
    const [batchIndex, setBatchIndex] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const courseId = props.courseId
    const instituteId = props.instituteId
    const instituteName = props.instituteName
    const published = props.published
    const [batchData, setBatchData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [viewMeet, setViewMeet] = React.useState(false);
    const [role, setRole] = React.useState(true);


    // New card menu item states & function
    const [openIndex, setOpenIndex] = React.useState(-1);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenIndex(index); // set current menu index to open

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    React.useEffect(() => {
        getBatch();
    }, [])

    const getBatch = async () => {
        try {
            setLoading(true);
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/getbatch`, { courseId })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setBatch(response.data.data);
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
        }
    }

    const isPublished = async (value, id) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/ispublished`, { isPublished: value, id: id })
            if (!response.data.status == true) {
                throw Error("Something went wrong");
            }
            let toast = {
                "status": true,
                "body": response.data.message,
                "message": "Success"
            }
            props.addToaster(toast);
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            props.addToaster(toast);
        }
    }


    const handleDelete = async (id) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/deleteBatch`, { batchId })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setBatch(batch.filter((item) => { return item._id != batchId }));
            setDeleteDailog(false);
            let toast = {
                "status": true,
                "body": "deleted successfully",
                "message": "Success"
            }

            props.addToaster(toast)
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }

            props.addToaster(toast)
        }

    }


    let deleteBatch = (option) => {
        setBatchId(option._id)
        setDeleteDailog(true)
    }

    const editbatchCallback = (newbatch) => {
        let currentArray = [...batch];
        let changedArray = { ...currentArray[batchIndex] = newbatch }
        changedArray[batchIndex] = currentArray;
        setBatch(currentArray)
    }

    let editBatch = (option, index) => {
        setBatchId(option)
        setBatchIndex(index)
        setEditDailog(true)
    }

    let handleispublished = (option, index, _id) => {
        isPublished(option, _id);
        let curentArray = [...batch];
        let changedArray = { ...curentArray[index].isPublished = !option }
        changedArray[index] = curentArray;
        setBatch(curentArray)
    }

    let addbatchCallback = (newbatch) => {
        let tempArray = [...batch, newbatch];
        setBatch(tempArray)
    }

    const scheduleMeet = async (item) => {
        setBatchData(item)
        setVisible(true)
    }

    const viewMeets = (item) => {
        setBatchData(item)
        setViewMeet(true)
    }

    return (
        <>
            {batch.length > 0 && <h3>Available Batch:-</h3>}
            {batch.length <= 0 && <h3>Please add batches ....</h3>}
            <CRow>
                {
                    loading && <LoadingScreen></LoadingScreen>
                }
                {
                    <div className='container'>
                        <div className='row'>
                            {
                                batch && batch.length > 0 &&
                                batch.map((option, index) => (
                                    <div className="col-md-6 p-2" key={index}>
                                        <CCard key={index} style={{ backgroundColor: "#B0F3AF", boxShadow: "10px 10px 5px lightblue" }}>
                                            <CRow >

                                                <CCol sm={13}>
                                                    <CardHeader
                                                        title={
                                                            <Link style={{ textDecoration: "none" }}
                                                                to={{
                                                                    pathname: '/pages/master/batch',
                                                                    state: { courseId: option.courseId, batchName: option.batchName, batchId: option._id, startDate: option.startDate, endDate: option.endDate, batchFees: option.batchFees, instituteId: instituteId, instituteName: instituteName, published: published }
                                                                }}>
                                                                <span style={{ color: "white" }}>{`${option.batchName}`}</span>
                                                            </Link>
                                                        }

                                                        action={
                                                            <IconButton aria-label="settings" onClick={handleClick(index)} style={{ color: 'black' }}>
                                                                <MoreVertIcon
                                                                    id="fade-button"
                                                                    aria-controls={open ? 'fade-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}

                                                                />
                                                            </IconButton>
                                                        }
                                                    />
                                                    {role &&
                                                        <Menu
                                                            id="fade-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'fade-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open && index === openIndex}
                                                            onClose={handleClose}
                                                            TransitionComponent={Fade}
                                                        >
                                                            <MenuItem onClick={() => { editBatch(option, index), setAnchorEl(null) }}>Edit</MenuItem>
                                                            {/* <MenuItem onClick={() => { scheduleMeet(option), setAnchorEl(null) }}>Schedule Meeting</MenuItem>
                                            <MenuItem onClick={() => { viewMeets(option), setAnchorEl(null) }}>View Schedule Meetings</MenuItem> */}
                                                            <MenuItem onClick={() => { deleteBatch(option), setAnchorEl(null) }}>Delete Batch</MenuItem>
                                                        </Menu>
                                                    }

                                                    <CCol sm={12}><div className="form-check form-switch " style={{ color: 'black', fontWeight: 'bold' }} >
                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl" onChange={() => handleispublished(option.isPublished, index, option._id)} checked={option.isPublished} />Published</div>
                                                    </CCol>

                                                    <CCol className="m-2">
                                                        <Link style={{ textDecoration: "none" }}
                                                            to={{
                                                                pathname: '/pages/master/batch',
                                                                state: { courseId: option.courseId, batchName: option.batchName, batchId: option._id, startDate: option.startDate, endDate: option.endDate, batchFees: option.batchFees, instituteId: instituteId, instituteName: instituteName, published: published }
                                                            }}>
                                                            {option.isInHouse === true && <>
                                                                <span style={{ color: "black" }}> In House Student Fees : <b>{option.inHousePayment}</b> </span><br />
                                                                {option.inHousePayment === 'paid' && <>
                                                                    <span style={{ color: "black" }}> In House Amount : <b>{option.inHouseFees}</b> rupee</span><br />
                                                                </>}
                                                            </>
                                                            }
                                                            {option.isInHouse === true && <>
                                                                <span style={{ color: "black" }}> Outer Student Fees : <b>{option.outHousePayment}</b> </span><br />
                                                                {option.outHousePayment === 'paid' && <>
                                                                    <span style={{ color: "black" }}> Outer Student Amount : <b>{option.outHouseFees}</b> rupee</span><br />
                                                                </>}
                                                            </>
                                                            }

                                                            {option.isTeacher === true && <>
                                                                <span style={{ color: "black" }}> Teacher Fees : <b>{option.teacherPayment}</b> </span><br />
                                                                {option.teacherPayment === 'paid' && <>
                                                                    <span style={{ color: "black" }}> Teacher Amount : <b>{option.teacherFees}</b> rupee</span><br />
                                                                </>}
                                                            </>
                                                            }
                                                            <span style={{ color: "black" }}> Is Featured : <b>{option.isFeatured.toString().toUpperCase()}</b></span><br />
                                                            <span style={{ color: "black", }}>Starting From <b>{moment(option.startDate).format('DD-MMM-YYYY')}</b></span>
                                                            <span style={{ color: "black", }}> Till <b> {moment(option.endDate).format('DD-MMM-YYYY')}</b></span>
                                                            <br />
                                                            <span style={{ color: "black" }}>Registration Start Date :<b>{option.registrationStartDate ? moment(option.registrationStartDate).format('DD-MMM-YYYY') : ''}</b></span>
                                                            <br />
                                                            <span style={{ color: "black" }}>Registration End Date :<b>{option.registrationEndDate ? moment(option.registrationEndDate).format('DD-MMM-YYYY') : ''}</b></span>
                                                            <br />
                                                        </Link>
                                                    </CCol>
                                                </CCol>
                                            </CRow>
                                        </CCard>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                }

                {
                    props.visible && <AddBatch
                        visible={props.visible}
                        onClose={props.onClose}
                        addToaster={props.addToaster}
                        addbatchCallback={addbatchCallback}
                        courseId={props.courseId}
                    />
                }

                {
                    editDailog &&
                    <EditBatch
                        visible={editDailog}
                        onClose={() => setEditDailog(false)}
                        addToaster={props.addToaster}
                        editbatchCallback={editbatchCallback}
                        courseId={props.courseId}
                        batch={batchId}
                        index={batchIndex}
                    />
                }

                {
                    deleteDailog && <DeleteDailog
                        visible={deleteDailog}
                        onClose={() => setDeleteDailog(false)}
                        delete={handleDelete}
                    // allDelete={handleAllDelete}
                    />
                }

                {
                    visible &&
                    <ScheduleMeeting
                        visible={visible}
                        onClose={() => setVisible(false)}
                        data={batchData}
                        addToaster={props.addToaster}
                    />
                }

                {
                    viewMeet &&
                    <ViewScheduledMeetings
                        visible={viewMeet}
                        onClose={() => setViewMeet(false)}
                        data={batchData}
                        addToaster={props.addToaster}
                    />
                }

            </CRow>
            {
                !loading && batch.length == 0 && !error && <h1> No Batch Added</h1>
            }
            {
                !loading && error && <h1>{error}</h1>
            }
        </>
    );
}

export default Batch;