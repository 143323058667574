import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios'
import ConfigData from './../../../../config/constant.json'
import {
    CModalHeader, CModal, CModalFooter,
    CModalTitle, CModalBody, CButton, CFormLabel,
    CForm, CFormInput, CAlert
} from '@coreui/react'
import { Table } from 'react-bootstrap'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Link } from 'react-router-dom';

const CourseResources = (props) => {
    const [resources, setResources] = React.useState(false)
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    React.useEffect(async () => {
        try {
            // Gets all the Sponsors
            if (props.courseCompo == 'courseCompo') {
                const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/resources/getCourseResources?videoId=${props.video_id}`)
                if (response.data.status == false) {
                    throw Error(response.data.message);
                }
                setResources(response.data.resources)
                setLoading(false)
            }else{
                const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/resources/getBatchResources?videoId=${props.video_id}`)
                if (response.data.status == false) {
                    throw Error(response.data.message);
                }
                setResources(response.data.resources)
                setLoading(false)
            }

        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    const handleDeleteResources = async (id, fileName, extension) => {
        try {
            if (props.courseCompo == 'courseCompo') {
                let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/resources/deleteResources`, { id, fileName, extension })
                if (response.data.status == false) throw Error(response.data.message)
                setResources(resources.filter(resource => resource._id != id))
            }else{
                let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/resources/deleteBatchResources`, { id, fileName, extension })
                if (response.data.status == false) throw Error(response.data.message)
                setResources(resources.filter(resource => resource._id != id))
            }
        } catch (e) {
            console.log(e)
        }
    }

    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }


    return (
        <>
            <CModal size="lg" alignment="center" visible={props.visible} onClose={props.onClose} backdrop='static'>
                <CModalHeader>
                    <CModalTitle>Resources</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {
                        loading && <LoadingScreen></LoadingScreen>
                    }
                    {!loading &&
                        resources.length > 0 &&
                        <>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Image</th>
                                        <th>File Name</th>
                                        <th>File Extension</th>
                                        <th>Download</th>
                                        <th className="text-center">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resources.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {
                                                        (item.extension == 'pdf' || item.extension == 'PDF')
                                                            ?
                                                            <img style={{ width: 40, height: 50 }} src={`${ConfigData.SERVER_URL}/images/pdf_logo.png`} />
                                                            :
                                                            (item.extension == 'zip' || item.extension == '7zip' || item.extension == 'rar')
                                                                ?
                                                                <img style={{ width: 40, height: 50 }} src={`${ConfigData.SERVER_URL}/images/zip_logo.png`} />
                                                                :
                                                                <img style={{ width: 40, height: 50 }} src={`${ConfigData.SERVER_URL}/resources/${item.FileName}.${item.extension}`} />

                                                    }
                                                </td>
                                                <td>{item.FileName}</td>
                                                <td>{item.extension}</td>
                                                <td><a href={`${ConfigData.SERVER_URL}/resources/${item.FileName}.${item.extension}`} target="_blank" download={true}>
                                                    {item.extension == 'zip' ? 'Download' : 'View'}
                                                </a></td>
                                                <td> <button onClick={() => handleDeleteResources(item._id, item.FileName, item.extension)} type="submit" className="btn btn-outline-danger">Delete</button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </>
                    }
                    {
                        !loading && resources.length == 0 && !error && <h5> No Resources Added</h5>
                    }
                    {
                        !loading && error && <h1>{error}</h1>
                    }
                </CModalBody>

            </CModal>
        </>
    );
}

export default CourseResources;