import React from 'react'
import { CButton, CModalHeader, CModal, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'

const DeleteDailog = (props) => {


    const handleDeletes = async () => {
        props.delete()
    }

    const handleAllDeletes = async () => {
        props.allDelete()
    }




    return (
        <>
            <CModal visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>Delete </CModalTitle>
                </CModalHeader>
                <CModalBody>Do you really want to Delete ??</CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={handleDeletes}>Confirm Delete</CButton>
                    {props.allDelete && <CButton color="danger" onClick={handleAllDeletes}>Delete All Associated Data</CButton>}
                </CModalFooter>
            </CModal>

        </>
    );
}

export default DeleteDailog;