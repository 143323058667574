import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from './../../../../config/constant.json'
import {
    CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CCardImage,
} from '@coreui/react'
import AddInstitute from './AddInstitute';
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Link } from 'react-router-dom';
import DeleteDailog from '../DeleteDailog';
import EditInstitute from './EditInstitute';
import "./style.css"
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import WarningBox from 'src/views/shared/WarningBox';

const Institute = () => {
    const [modalState, setModalState] = React.useState(false)
    const [editInstituteState, setEditInstituteState] = React.useState(false)
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [institutes, setInstitutes] = React.useState(false)
    const [instituteDetails, setInstituteDetails] = React.useState(null)
    const [institutesId, setInstitutesId] = React.useState(null)
    const [toast, addToast] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [warningBox, setWarningBox] = React.useState(false)
    const [openIndex, setOpenIndex] = React.useState(-1);
    let description = "Deleting Institute will delete all the courses, modules, batches containing analytics, videos, resources and quiz. Also if a student has purchased the selected course it will also get deleted permanently."
    const [role, setRole] = React.useState(true);

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])


    React.useEffect(async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/institute/getinstitutes`)
            setInstitutes(response.data.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(`${e.message} :(`)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    const handleAllDelete = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/institute/deleteinstitutealldata`, { institutesId })

            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            setInstitutes(institutes.filter((mode) => { return mode._id !== instituteDetails._id }))
            setDeleteDailog(false)
        } catch (e) {
            console.log(e)
        }

    }

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Add Institute Callback
    let addinstituteCallback = (newinstitute) => {
        setInstitutes([...institutes, newinstitute])
        setModalState(false)
    }

    let deleteInstitute = (option) => {
        setInstitutesId(option._id)
        setInstituteDetails(option)
        setWarningBox(true)
    }

    // this fuction enable delete modal
    let confirmDeleteCallBack = () => {
        setDeleteDailog(true)
    }
    let editInstitute = (option) => {
        setInstituteDetails(option)
        setEditInstituteState(true)
    }

    let editinstituteCallback = (editedInstitute) => {
        const newList = institutes.map((item) => {
            if (item._id === editedInstitute._id) {
                const updatedItem = {
                    ...editedInstitute
                };
                return updatedItem;
            }
            return item;
        });
        setInstitutes(newList);
        setEditInstituteState(false)
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenIndex(index); // set current menu index to open

    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <div style={{ margin: 20 }}>
                <CRow>
                    <CCol sm={12}>
                        <CCard style={{ background: "white" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={4}> <CCardTitle style={{ color: "black" }}>HSNCU Institutes</CCardTitle></CCol>
                                    <CCol sm={6}></CCol>
                                    {
                                        role &&
                                        <CCol sm={2}><CButton onClick={() => setModalState(true)} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Add Institute</CButton></CCol>
                                    }
                                    {
                                        modalState &&
                                        <AddInstitute
                                            visible={modalState}
                                            onClose={() => setModalState(false)}
                                            addToast={addToast}
                                            addinstituteCallback={addinstituteCallback}
                                        ></AddInstitute>
                                    }
                                    {
                                        editInstituteState &&
                                        <EditInstitute
                                            visible={editInstituteState}
                                            onClose={() => setEditInstituteState(false)}
                                            institute={instituteDetails}
                                            addToaster={addToaster}
                                            editinstituteCallback={editinstituteCallback}
                                        ></EditInstitute>
                                    }
                                    {
                                        deleteDailog && <DeleteDailog
                                            visible={deleteDailog}
                                            onClose={() => setDeleteDailog(false)}
                                            instituteId={institutesId}
                                            instituteDetails={instituteDetails}
                                            delete={handleAllDelete}
                                        ></DeleteDailog>}
                                    {
                                        <WarningBox
                                            onClose={() => setWarningBox(false)}
                                            visible={warningBox}
                                            description={description}
                                            confirmDeleteCallBack={confirmDeleteCallBack}
                                        >
                                        </WarningBox>
                                    }
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <br></br>
                <h3>Available Institutes:-</h3>
                <h6>(Select Institute to Add Course)</h6>
                <br></br>

                <CRow>
                    {
                        loading && <LoadingScreen></LoadingScreen>
                    }
                    {
                        <div className='container'>
                            <div className='row'>
                                {institutes && !loading && institutes.length > 0 &&
                                    institutes.map((option, index) => (
                                        <div className="col-md-6 p-2" key={index}>
                                            <CCard key={index} style={{ backgroundColor: "#B0F3AF", boxShadow: "10px 10px 5px lightblue", paddingBottom: '10px' }}>
                                                <CRow >
                                                    <CCol sm={3} style={{ marginTop: "2%", paddingLeft: "5%" }}>
                                                        <Link style={{ textDecoration: "none" }}
                                                            to={{
                                                                pathname: '/pages/master/course',
                                                                state: { instituteId: option._id, instituteName: option.instituteName }
                                                            }}>
                                                            <CCardImage style={{ width: '100%', height: '100%' }} src={`${ConfigData.SERVER_URL}/images/institud.png`} />
                                                        </Link>
                                                    </CCol>
                                                    <CCol sm={9}>
                                                        <CardHeader
                                                            title={<Link style={{ color: 'white', textDecoration: 'none' }}
                                                                to={{
                                                                    pathname: '/pages/master/course',
                                                                    state: { instituteId: option._id, instituteName: option.instituteName }
                                                                }}>
                                                                {`${option.instituteName}`}
                                                            </Link>

                                                            }
                                                            action={
                                                                <IconButton aria-label="settings" onClick={handleClick(index)} style={{ color: 'rgb(214, 178, 49)' }}>
                                                                    <MoreVertIcon
                                                                        id="fade-button"
                                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open ? 'true' : undefined}

                                                                    />
                                                                </IconButton>
                                                            }
                                                        />{role &&

                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open && index === openIndex}
                                                                onClose={handleClose}
                                                                TransitionComponent={Fade}
                                                            >
                                                                <MenuItem onClick={() => { editInstitute(option), setAnchorEl(null) }}>Edit</MenuItem>


                                                                <MenuItem onClick={() => { deleteInstitute(option), setAnchorEl(null) }}>Delete</MenuItem>
                                                            </Menu>
                                                        }

                                                        <Link style={{ textDecoration: "none" }}
                                                            to={{
                                                                pathname: '/pages/master/course',
                                                                state: { instituteId: option._id, instituteName: option.instituteName }
                                                            }}>
                                                            <span style={{ color: "black", fontWeight: "bold", }}> {option.instituteName.substring(0, 70)}{option.instituteName.length > 70 ? "..." : ""}</span>
                                                        </Link>
                                                    </CCol>
                                                </CRow>
                                            </CCard>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }


                    {
                        !loading && institutes.length == 0 && !error && <h1> No Institute Added</h1>
                    }
                    {

                        !loading && error && <h1>{error}</h1>
                    }
                </CRow>

            </div>
            {toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>}
        </>
    );
}


export default Institute;