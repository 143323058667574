import React from 'react'
import userApi from 'src/utils/axios';
import { Formik, FieldArray, ErrorMessage } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import { Table, ProgressBar } from 'react-bootstrap'
import { CButton, CCol, CRow, CFormTextarea, CFormSelect, CInputGroup, CFormLabel, CSpinner, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CAlert, CInputGroupText } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilWarning } from '@coreui/icons'

const AddVideo = ({ visible, addVideoCallBack, courseId, moduleId, teacherData }) => {
    const [radio, setradio] = React.useState(false)
    const [isDate, setDate] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [show, setshow] = React.useState(true);
    const [teacherDataArray, setTeacherDataArray] = React.useState(teacherData)
    const re = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

    function handleTransfer(bool) {
        setDate(bool)
        setradio(bool)
    }

    const VideoSchema = yup.object().shape({
        videos: yup.array()
            .of(yup.object().shape({

                serial: yup.number()
                    .required('Serial number is required'),

                title: yup.string()
                    .required('title is required').min(3),

                teacher_id: yup.string()
                    .required('Teacher is required')
                    .test('is-valid', 'Please select a Teacher', (val) => {
                        return val != '1'
                    }),

                description: yup.string()
                    .required('About is required').min(10),

                fileName: yup.string()
                    .required('please select the file'),
                sampleVideo: yup.string()
                    .required('Sample video is Required'),
                // live_meeting_Link: yup.string()
                //     .matches(re, "Please enter a valid link, Example://https://your domain.com")
            }))
    })

    const initialValues = {
        videos: [{
            serial: '',
            title: '',
            fileName: '',
            description: '',
            date: '',
            teacher_id: '',
            live_meeting_Link: '',
            sampleVideo: ''
        }]
    }

    const [VideoStatus, setVideoStatus] = React.useState(false);
    const [fileData, setFileData] = React.useState([])
    const [networkdown, setnetworkdown] = React.useState(false)
    const [retry, setretry] = React.useState(false)
    const arrVideo = [];

    React.useEffect(() => {
        setIsLoading(true);
        setTeacherDataArray(teacherData)
        setIsLoading(false);
        if (VideoStatus) {
            VideoStatus.map(async (videodata, index) => {
                await singleUpload(videodata, index)
            })
        }
    }, [VideoStatus])

    const handleAddVideo = async (values) => {
        try {
            setshow(false)
            setIsLoading(true);

            if (values.videos) {
                setVideoStatus(values.videos)
            } else {
                setVideoStatus(false);
            }
        }
        catch (e) {
            // If Error Change the Status to False
            setIsLoading(false)
            console.log(e);
        }
    }

    let singleUpload = async (videodata, index) => {
        arrVideo.push(videodata)
        const formData = new FormData()
        formData.append('serial', videodata.serial)
        formData.append('title', videodata.title)
        formData.append('fileName', videodata.fileName);
        formData.append('teacher_id', videodata.teacher_id);
        formData.append('description', videodata.description);
        formData.append('date', videodata.date);
        formData.append("courseId", courseId);
        formData.append("moduleId", moduleId);
        formData.append("videoStatus", radio);
        formData.append("sampleVideo", videodata.sampleVideo);
        // formData.append("live_meeting_Link", videodata.live_meeting_Link ? videodata.live_meeting_Link : '');

        setFileData(arrVideo);

        let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/video/videoupload`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent) {

                        //set network down false 
                        setnetworkdown(false)

                        // Get Percentage
                        const percentcompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                        // Create copy of file
                        let newFile = videodata

                        // ... and set the progress
                        newFile.progress = percentcompleted;

                        // Create copy of files array
                        const filesCopy = [...VideoStatus];

                        // Find the index of the file object
                        let fileIndex = filesCopy.findIndex((el) => el.title === videodata.title);

                        //... and replace the object with the new one containing the [progress] key
                        filesCopy[fileIndex] = newFile;

                        // Finally, update the state
                        setFileData([...filesCopy])
                        videodata.progress = percentcompleted
                    }
                },
                withCredentials: true
            }).catch(function (error) {
                if (!error.response) {
                    setnetworkdown(true)
                }
            })

        if (response.data.status == false) {
            setIsLoading(false)
            throw Error(response.data.message)
        }
        else {
            setIsLoading(false)
            addVideoCallBack(response.data.data)
        }
    }

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    return (
        <div>
            {
                visible = { visible } && show &&

                <div style={{ position: "relative" }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={VideoSchema}
                        onSubmit={(values) => handleAddVideo(values)}
                    >
                        {(formikProps) => (
                            <div>
                                <FieldArray name="videos">
                                    {({ insert, remove, push }) => (
                                        <div>
                                            {formikProps.values.videos &&
                                                formikProps.values.videos.map((video, index) =>
                                                (
                                                    <div key={index}>
                                                        <CAccordion style={{ width: "100%" }} activeItemKey={1}>
                                                            <CAccordionItem itemKey={1}>
                                                                <CAccordionHeader>
                                                                    Form - {index + 1}
                                                                </CAccordionHeader>
                                                                <CAccordionBody >
                                                                    <div className="mb-3">
                                                                        <CFormLabel htmlFor={`videos.${index}.serial`}>Video Sequence Number</CFormLabel>
                                                                        <CFormInput
                                                                            name={`videos.${index}.serial`}
                                                                            placeholder="Serial Number"
                                                                            onChange={formikProps.handleChange(`videos.${index}.serial`)}
                                                                            onBlur={formikProps.handleBlur(`videos.${index}.serial`)}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.serial`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />

                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <CFormLabel htmlFor={`videos.${index}.title`}>Title</CFormLabel>
                                                                        <CFormInput
                                                                            type="text"
                                                                            name={`videos.${index}.title`}
                                                                            placeholder="Video Title"
                                                                            onChange={formikProps.handleChange(`videos.${index}.title`)}
                                                                            // value={formikProps.values.title}
                                                                            onBlur={formikProps.handleBlur(`videos.${index}.title`)}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.title`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />

                                                                    </div>

                                                                    <div className={`videos.${index}.fileName`}>
                                                                        <CFormLabel htmlFor="formFile">Select Video</CFormLabel>
                                                                        <CFormInput
                                                                            accept=".mp4,.mov,.flv,.wmv,.avi,.webm,.mkv"
                                                                            type="file" id="formFile"
                                                                            name={`videos.${index}.fileName`}
                                                                            onChange={(event) => formikProps.setFieldValue(`videos.${index}.fileName`, event.target.files[0])}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.fileName`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />

                                                                    </div>



                                                                    {teacherDataArray && <div className="mb-3">
                                                                        <CInputGroup className="mb-3">
                                                                            <legend htmlFor={`videos.${index}.teacher_id`}>Taught By</legend>
                                                                            <CFormSelect
                                                                                value={formikProps.values.teacher_id}
                                                                                onChange={formikProps.handleChange(`videos.${index}.teacher_id`)}
                                                                                size="lg" className="mb-3"
                                                                                aria-label="Taught By">

                                                                                <option
                                                                                    key={1}
                                                                                    value={"1"}
                                                                                >Select Teacher
                                                                                </option>

                                                                                {teacherDataArray.map((option) =>
                                                                                    <option
                                                                                        key={option._id}
                                                                                        value={option.userId}
                                                                                    >{option.fullName ? option.fullName : (option.firstName ? `${option.firstName} ${option.lastName}` : option.name)}
                                                                                    </option>
                                                                                )}

                                                                            </CFormSelect>
                                                                        </CInputGroup>
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.teacher_id`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    </div>}

                                                                    <div className="mb-3">

                                                                        <CFormLabel htmlFor={`videos.${index}.description`}>Description</CFormLabel>
                                                                        <CFormTextarea
                                                                            onChange={formikProps.handleChange(`videos.${index}.description`)}
                                                                            // value={formikProps.values.description}
                                                                            name={`videos.${index}.description`} rows="3"
                                                                            onBlur={formikProps.handleBlur(`videos.${index}.description`)}
                                                                        ></CFormTextarea>
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.description`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    </div>

                                                                    {/* <div className="mb-3">
                                                                        <div>
                                                                            <CFormLabel >Live Meeting Link</CFormLabel>
                                                                        </div>

                                                                        <input type="radio" value={formikProps.values.live_meeting_Link} name="live_meeting_Link" onChange={() => formikProps.setFieldValue('live_meeting_Link', true)} />
                                                                        &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <input type="radio" value={formikProps.values.live_meeting_Link} name="live_meeting_Link" onChange={() => formikProps.setFieldValue('live_meeting_Link', false)} />
                                                                        &nbsp;<label >No</label>

                                                                        {
                                                                            formikProps.values.live_meeting_Link &&
                                                                            <div>
                                                                                <CFormInput
                                                                                    type="text"
                                                                                    name={`videos.${index}.live_meeting_Link`}
                                                                                    placeholder="https://meet.google.com/lookup/..."
                                                                                    onChange={formikProps.handleChange(`videos.${index}.live_meeting_Link`)}
                                                                                    onBlur={formikProps.handleBlur(`videos.${index}.live_meeting_Link`)}
                                                                                />

                                                                                <ErrorMessage
                                                                                    name={`videos.${index}.live_meeting_Link`}
                                                                                    component="div"
                                                                                    className="field-error"
                                                                                    style={{ color: 'red' }} />
                                                                            </div>
                                                                        }

                                                                    </div> */}

                                                                    {/* <div className="mb-3">
                                                                        <div>
                                                                            <div> <span>Do you want to Schedule this video??</span>&nbsp;&nbsp;
                                                                                {isDate &&
                                                                                    <input type="date"
                                                                                        onChange={formikProps.handleChange(`videos.${index}.date`)} >
                                                                                    </input>
                                                                                }
                                                                            </div>
                                                                            <input type="radio" value={radio} name="transferRadio" onChange={() => handleTransfer(true)} />
                                                                            &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <input type="radio" value={radio} name="transferRadio" selected={true} onChange={() => handleTransfer(false)} />
                                                                            &nbsp;<label >No</label>

                                                                        </div>

                                                                    </div> */}
                                                                    <div className="mb-3">
                                                                        <div>
                                                                            <div> <span>This is sample video?</span>

                                                                            </div>
                                                                            <input type="radio" name="sampleVideo" onChange={() => formikProps.setFieldValue(`videos.${index}.sampleVideo`, true)}
                                                                                onBlur={formikProps.handleBlur(`videos.${index}.sampleVideo`)} value={formikProps.values.sampleVideo} />
                                                                            &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <input type="radio" name="sampleVideo" onChange={() => formikProps.setFieldValue(`videos.${index}.sampleVideo`, false)}
                                                                                onBlur={formikProps.handleBlur(`videos.${index}.sampleVideo`)} value={formikProps.values.sampleVideo} />
                                                                            &nbsp;<label >No</label>

                                                                        </div>
                                                                        <ErrorMessage
                                                                            name={`videos.${index}.sampleVideo`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    </div>
                                                                    <hr style={{
                                                                        color: 'red',
                                                                        backgroundColor: 'red',
                                                                        height: .5,
                                                                        borderColor: '#000000'
                                                                    }} />

                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                                        <CButton onClick={() => push({ serial: '', title: '', fileName: '', description: '', date: '', teacher_id: '' })}>Add More</CButton>
                                                                        &nbsp;&nbsp;&nbsp;<CButton onClick={() => remove(index)}>Cancel Accordion</CButton>
                                                                    </div>

                                                                </CAccordionBody>
                                                            </CAccordionItem>
                                                        </CAccordion>

                                                    </div>
                                                ))}
                                            <br />

                                            <CRow>
                                                <CCol xs={5}> </CCol>
                                                {!isLoading && <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>}
                                                {isLoading && <CCol xs={7}> <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary"><CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit</button> </CCol>}
                                            </CRow>

                                        </div>
                                    )
                                    }
                                </FieldArray >
                            </div>

                        )}
                    </Formik>
                </div>
            }

            {
                fileData.length >= 0 && VideoStatus &&

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th scope="col">Sr No.</th>
                            <th scope="col">Video Name</th>
                            <th scope="col">Size</th>
                            <th scope="col">Upload Status</th>
                        </tr>
                    </thead>

                    {
                        fileData.map((data, index) =>

                        (
                            <tbody key={index}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{data.fileName.name}</td>
                                    <td>{bytesToSize(data.fileName.size)}</td>
                                    <td>
                                        {
                                            networkdown == false && data.progress != 100 &&
                                            <ProgressBar now={data.progress} label={`${data.progress}%`} />
                                        }

                                        {
                                            data.progress === 100 &&
                                            <CAlert color="success" className="d-flex align-items-center">
                                                <CIcon icon={cilCheckCircle} className="flex-shrink-0 me-2" width={24} height={24} />
                                                <div>
                                                    Done
                                                </div>
                                            </CAlert>
                                        }
                                        {
                                            networkdown && data.progress != 100 &&
                                            // <p ><BsXLg style={{color:'red'}} />   Error</p>
                                            <CAlert color="warning" className="d-flex align-items-center">
                                                <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
                                                <CButton
                                                    onClick={() => singleUpload(data, index)}>
                                                    Retry
                                                </CButton>
                                            </CAlert>
                                        }
                                    </td>
                                </tr>
                            </tbody>

                        )
                        )
                    }
                </Table>
            }
        </div >
    );
}

export default AddVideo;