import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from '../../../../config/constant.json'
import { CModalHeader, CModal, CModalTitle, CModalBody, CRow, CCol,CSpinner,CButton } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';





const UserReport = (props) => {


    // let user_id = props.location.state.selectedUserId
    // let index = props.location.state.index
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [userdata, setUserData] = React.useState(null);
    const [batchdetails, setBatchDetails] = React.useState(null);


    React.useEffect(async () => {
        try {
            setLoading(true)
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getUserReport?userId=${props.selectedUserId}`)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setUserData(response.data)
            setBatchDetails(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])


    

    async function download_file(fileURL, fileName) {
        let test = fileURL.split('/public')
        let real = ConfigData.SERVER_URL + test[1]

        fetch(real).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })



    }

const getPDF = async () => {
    try {
        setLoading(true)
        const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/getUserReportPDF`, { userdata: userdata.userData[0]._id, batchdetails: batchdetails})
        if (response.data.status == false) {
            throw Error(response.data.message);
        }
        await download_file(response.data.pdf, `${userdata.userData[0].name}.pdf`)
        setLoading(false)
    } catch (e) {
        setError(`${e.message} :(`)
        setLoading(false)
        let toast = {
            "status": false,
            "body": e.message,
            "message": "Failed"
        }
        addToaster(toast)
    }
}

// Convert milliseconds to minutes
const millisToMinutesAndSeconds = (millis) => {
    var d = new Date(1000 * Math.round(millis / 1000)); // round to nearest second
    function pad(i) { return ('0' + i).slice(-2); }
    var str = d.getUTCHours() + ':' + pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds());
    return str;
}

// Function to Add Toaster
let addToaster = (toast) => {
    addToast(toast)
    setTimeout(() => {
        addToast(false)
    }, 3000)
}
// get module percentage
const modulePercentage = (array) => {
    let videoDuration = 0;
    let watchedDuration = 0;
    let moduleWatched = 0;
    for (let data of array) {
        videoDuration = videoDuration + data.duration;
        watchedDuration = watchedDuration + data.durationWatched
    }
    moduleWatched = watchedDuration * 100 / videoDuration
    return moduleWatched.toFixed();
}

return (
    <>
        <CModal scrollable size="lg" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static' >
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>User Profile</CModalTitle>
            </CModalHeader>
            <CModalBody>

                {userdata ?
                    <div >
                        {/* Mentor Profile  */}
                        <div id="divToPrint">
                            <p style={{ fontSize: 20, textAlign: 'center', color: 'blue', border: '2px solid blue' }}><b>Profile</b></p>
                            <div  >
                                <table style={{ ...styles.table, width: '100%' }} >

                                    <tbody>

                                        <tr >
                                            <th style={styles.table} >Profile Image</th>
                                            <td style={styles.table} ><img style={{ width: 150, height: 100, textAlign: 'center' }} src={`${ConfigData.SERVER_URL}/images/PIcon.png `} /></td>
                                        </tr>

                                        <tr  >
                                            <th style={styles.table}>Name</th>
                                            <td style={styles.table}>{userdata.userData[0].name}</td>
                                        </tr>
                                        <tr >
                                            <th style={styles.table}>email</th>
                                            <td style={styles.table}>{userdata.userData[0].email}</td>

                                        </tr>
                                        <tr >

                                            <th style={styles.table} >Phone</th>
                                            <td style={styles.table}>{userdata.userData[0].number}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <br />
                            {/* EducationDetails */}

                            <p style={{ fontSize: 20, textAlign: 'center', color: 'blue' }}><b>Batch Report</b></p>
                            {batchdetails && batchdetails.map((item, index) => (<div key={index} style={{ border: '2px solid black', marginBottom: '4%', breakInside: 'avoid' }}>
                                <p style={{ borderBottom: '2px solid black' }}>{index + 1}. Batch name :-  {item.batch.batch_id.batchName}</p>
                                <div  >
                                    <div>Module Report : - </div>

                                    {item.moduledetails && item.moduledetails.map((item1, index1) => (

                                        <div key={index1} style={{ border: '1px solid black', padding: "1%", margin: "1%" }}>


                                            <div>{index1 + 1}. Module name :- {item1.module.module_name} :{modulePercentage(item1.videos)} %  </div>


                                            <div style={{ margin: '1%' }}>
                                                <div>Videos Report : - </div>
                                                <table style={{ ...styles.table, width: '100%' }} >
                                                    <thead >
                                                        <tr >
                                                            <th style={styles.table}>Sr. No</th>
                                                            <th style={styles.table}>Title</th>
                                                            <th style={styles.table}>Video Duration</th>
                                                            <th style={styles.table}>Watched Duration</th>
                                                            <th style={styles.table}>Is Completed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Search bar logic */}
                                                        {item1.videos.map((item3, index3) => (
                                                            <tr key={index3}>
                                                                <td style={styles.table}>{index3 + 1}</td>
                                                                <td style={styles.table}>{item3.title}</td>
                                                                <td style={styles.table}>{item3.durationMinutes}</td>
                                                                <td style={styles.table}>{millisToMinutesAndSeconds(item3.durationWatched)}</td>
                                                                <td style={styles.table}>{(item3.durationWatched * 100 / item3.duration).toFixed(2)} % </td>

                                                            </tr>
                                                        ))

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{ margin: '1%' }} >
                                                <div>Quiz Report : -</div>
                                                {item1.quiz.length > 0 ? <table style={{ ...styles.table, width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={styles.table}>Sr. No</th>
                                                            <th style={styles.table}>Module Name</th>
                                                            <th style={styles.table}>Max Score</th>
                                                            <th style={styles.table}>Low Score</th>
                                                            <th style={styles.table}>Out Of</th>
                                                            <th style={styles.table}>Numbers of Attempt</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Search bar logic */}
                                                        {item1.quiz.map((item2, index2) => (
                                                            <tr key={index2}>
                                                                <td style={styles.table}>{index2 + 1}</td>
                                                                <td style={styles.table}>{item2.moduleName}</td>
                                                                <td style={styles.table}>{item2.MaxScore}</td>
                                                                <td style={styles.table}>{item2.LowScore}</td>
                                                                <td style={styles.table}>{item2.OutOf}</td>
                                                                <td style={styles.table}>{item2.numberOfAttemp}</td>

                                                            </tr>
                                                        ))

                                                        }
                                                    </tbody>
                                                </table> : <div style={{ fontWeight: 'bold' }}> No Data</div>}
                                            </div>
                                        </div>))}

                                </div>

                            </div>))}
                        </div>

                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CButton
                                id="pdfButton"
                                color="primary"
                                className="px-4"
                                type="submit"
                                disabled={loading}
                                onClick={() => getPDF()}
                            >
                                {loading ? <CSpinner color="light" size="sm" /> : "Download pdf"}
                            </CButton>
                        </div>

                        <br />

                    </div>
                    :
                    <h1>No Data</h1>
                }

            </CModalBody>
        </CModal>


    </>
);
}
const styles = {
    table: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'center'
    }
}


export default UserReport;
