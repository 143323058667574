
import { useEffect } from 'react';
import Keycloak from 'keycloak-js';
import { useHistory } from 'react-router-dom';
import userApi from './axios';
import config from '../config/constant.json';
const useKeycloak = () => {
    const history = useHistory();

    const initOptions = {
        url: config.REACT_APP_KEYCLOAK_URL,
        realm: config.REACT_APP_KEYCLOAK_REALM,
        clientId: config.REACT_APP_KEYCLOAK_CLIENTID,
        onLoad: 'check-sso',
        responseType: 'code',
    };

    const keycloak = new Keycloak(initOptions);

    const initKeycloak = async () => {
        try {
            const authenticated = await keycloak.init({
                onLoad: initOptions.onLoad,
                responseType: initOptions.responseType,
                silentCheckSsoRedirectUri: window.location.origin + '/admin/silent-check-sso.html',
                checkLoginIframe: false,
                pkceMethod: 'S256',
            });
            if (!authenticated) {
                keycloak.login()
            } else {
                console.info('Authenticated');
                const token = keycloak.token;
                const tokenParsed = keycloak.tokenParsed;
                const user_data = {
                    username: tokenParsed?.preferred_username,
                    email: tokenParsed?.email,
                    role: tokenParsed?.resource_access?.[initOptions.clientId]?.roles[0],
                    keycloakUserId: tokenParsed?.sub,
                    fullName: tokenParsed?.given_name,
                };

                localStorage.setItem('token', token);
                localStorage.setItem('UserDetail', JSON.stringify(user_data));


                keycloak.onTokenExpired = () => {
                    // Handle token expiration as needed
                    localStorage.removeItem('token');
                    localStorage.removeItem('userDetail');
                    history.push('/');
                };
                // Check if the user is authenticated before navigating
                if (keycloak.authenticated) {
                    let response = await userApi.post(`${config.SERVER_URL}/admin/user/roleAccessByKeyCloakId`, { keycloakUserId: user_data.keycloakUserId })
                    localStorage.setItem('userRoles', response.data.data.roleAccess)
                    localStorage.setItem('role', response.data.data.roleName)
                    history.push('/pages/analytics');
                }


            }
        } catch (error) {
            console.error('Authentication failed', error);
        }
    };

    useEffect(() => {
        initKeycloak();
    }, []); // Call the initialization function only once when the component mounts

    return keycloak;
};

export default useKeycloak;

