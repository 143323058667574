import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';;
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../config/constant.json'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardGroup, CCardImage, CFormLabel, CSpinner,
    CForm, CFormInput, CFormCheck
} from '@coreui/react'


const EditTeacher = (props) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const numberRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const teacherSchema = yup.object({

        name: yup.string()
            .required('Name is required').min(3),
        email: yup.string().email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        number: yup.string()
            .matches(numberRegExp, 'Phone number is not valid')
            .min(10, 'Minimum Digits are 10')
            .max(10, 'Phone should be at most 10 digits')
            .required()
        ,
        gender: yup.string()
            .required('Gender is Required'),
        // about: yup.string()
        //     .required('About is required').min(10),

    });

    let _id = props.teacherDetails._id
    const handleeditteacher = async (values) => {
        try {
            setIsLoading(true)
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/teacher/editteacher?_id=${props.teacherDetails._id}`, { values })
            setIsLoading(false)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            let toast = {
                "status": true,
                "body": "Teacher Edited",
                "message": "Success"
            }
            props.addToaster(toast);
            props.editCallback({ ...values, _id })
        } catch (e) {
            setIsLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }

            props.addToaster(toast)
        }
    }
    const initialValues = {
        name: props.teacherDetails.name,
        email: props.teacherDetails.email,
        number: props.teacherDetails.number,
        gender: props.teacherDetails.gender,
        // about: props.teacherDetails.about
    }

    return (
        <>
            <CModal visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>Edit Teacher</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={teacherSchema}
                                onSubmit={(values, actions) => {
                                    handleeditteacher(values);
                                    actions.resetForm();
                                }}
                            >
                                {(formikProps) => (
                                    <CForm>
                                        <CInputGroup className="mb-3">
                                            <legend>Name</legend>
                                            <CFormInput
                                                placeholder="Name"
                                                autoComplete="name"
                                                onChange={formikProps.handleChange('name')}
                                                value={formikProps.values.name}
                                                onBlur={formikProps.handleBlur('name')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.name && formikProps.errors.name}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>Email</legend>
                                            <CFormInput readOnly={true}
                                                placeholder="Email"
                                                autoComplete="email"
                                                onChange={formikProps.handleChange('email')}
                                                value={formikProps.values.email}
                                                onBlur={formikProps.handleBlur('email')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.email && formikProps.errors.email}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>Contact Number</legend>
                                            <CFormInput
                                                placeholder="Contact Number"
                                                autoComplete="number"
                                                onChange={formikProps.handleChange('number')}
                                                value={formikProps.values.number}
                                                onBlur={formikProps.handleBlur('number')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.number && formikProps.errors.number}</p>

                                        <CInputGroup>
                                            <legend>Gender</legend>
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Male')} checked={(formikProps.values.gender === 'Male') ? formikProps.values.gender : ''} value={formikProps.values.gender} type="radio" inline name="flexRadioDefault" id="Male" label="Male" />
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Female')} checked={(formikProps.values.gender === 'Female') ? formikProps.values.gender : ''} value={formikProps.values.gender} type="radio" inline name="flexRadioDefault" id="Female" label="Female" />
                                            <CFormCheck onChange={() => formikProps.setFieldValue('gender', 'Other')} checked={(formikProps.values.gender === 'Other') ? formikProps.values.gender : ''} value={formikProps.values.gender} type="radio" inline name="flexRadioDefault" id="Transgender" label="Other" />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.gender && formikProps.errors.gender}</p>
                                        <br />
                                        {/* 
                                        <CInputGroup className="mb-3">
                                            <legend>About</legend>
                                            <CFormTextarea
                                                id="about"
                                                rows="3"
                                                placeholder='About'
                                                text="Must be 8-20 words long."
                                                onChange={formikProps.handleChange('about')}
                                                value={formikProps.values.about}
                                                onBlur={formikProps.handleBlur('about')}
                                            ></CFormTextarea>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.about && formikProps.errors.about}</p> */}

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                {!isLoading && <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>}
                                                {isLoading && <CCol xs={7}> <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary"><CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit</button> </CCol>}
                                            </CCol>

                                            <CCol xs={5}> </CCol>

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default EditTeacher;