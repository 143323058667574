import { Route, Switch, useRouteMatch} from "react-router-dom";
import Page404 from "../page404/Page404";
import Course from "./Course/Course";
import Institute from "./Institute/Institute";
import MasterPage from "./MasterPage";
import Module from "./Module/Module";
import QuizCourseView from "./Video/QuizCourseView";
import BatchDetails from "./Batch/BatchDetails";
import ViewBatchQuiz from "./Batch/BatchQuiz/ViewBatchQuiz";

const MasterRoute = () => {
    const { url } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${url}/`} exact component={MasterPage} />
                <Route path={`${url}/institute`} exact component={Institute} />
                <Route path={`${url}/course`} exact component={Course} />
                <Route path={`${url}/module`} exact component={Module} />
                <Route path={`${url}/batch`} exact component={BatchDetails} />
                <Route path={`${url}/batchquiz`} exact component={ViewBatchQuiz} />
                <Route path={`${url}/quizCourseView`} exact component={QuizCourseView} />
                <Route path={`${url}/*`} exact component={Page404} />
            </Switch>
        </>

    );
}

export default MasterRoute;