import React, { useState } from 'react'
import userApi from 'src/utils/axios';
import ConfigData from 'src/config/constant.json';
import { Table } from 'react-bootstrap'
import LoadingScreen from 'src/views/shared/Loading';
import ReactPaginate from 'react-paginate';
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CFormInput } from '@coreui/react'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Toaster from 'src/views/shared/Toaster';
import ExcelFile from 'src/views/shared/ExcelFile';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { configure } from '@testing-library/react';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { getIn } from 'yup/lib/util/reach';

const Institute = () => {

  const [toast, addToast] = React.useState(false)
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [instituteData, setInstituteData] = React.useState([]);
  const [date, setDate] = useState(null);
  let history = useHistory();
  const [debouncedValue, setDebouncedValue] = useState('');
  let timeoutId; // Declare timeoutId variable outside of debounce function

  // Function to Add Toaster
  let addToaster = (toast) => {
    addToast(toast)
    setTimeout(() => {
      addToast(false)
    }, 3000)
  }

  React.useEffect(() => {
    if (!debouncedValue) {
      getInstituteData('')
    } else {
      getInstituteData(debouncedValue)
    }
  }, [debouncedValue])

  const getInstituteData = async (searchValue) => {
    try {
      const searchTerm = searchValue ? searchValue : '';

      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getInstituteAnalytics?searchTerm=${searchTerm}`)
      if (response.data.status == false) {
        setLoading(false)
        throw Error(response.data.message);
      }
      setInstituteData(response.data.data)
      setLoading(false)

    } catch (error) {
      setError(`${error.message} :(`)
      setLoading(false)

      let toast = {
        "status": false,
        "body": error.message,
        "message": "Failed"
      }
      addToaster(toast)
    }
  }

  const handleExcelFile = async () => {
    try {
      const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelDataInstitutes?dateRange=${date}`)
      if (response.data.status == true) {
        return response.data.data;
      }
    } catch (e) {
      console.log(e.message)
    }
  }


  const [ModalView, setModalView] = React.useState(false);
  const [current_In_Data, setCurrent_Ins_Data] = React.useState();

  // Pagination logic
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(instituteData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(instituteData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, instituteData]);
  const handlePageClick = (event) => {
    setOffset(event.selected);
    const newOffset = (event.selected * itemsPerPage) % instituteData.length;
    setItemOffset(newOffset);
  };

  // Handle search input change with debounce
  const handleSearch = (e) => {
    debounce(e.target.value, 500);
  }

  // Debounce function to delay search input
  const debounce = (value, delay) => {

    clearTimeout(timeoutId); // Clear the previous timeout if exists

    timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup function to clear the timeout on unmount or new debounce call
    return () => clearTimeout(timeoutId);
  }
  return (
    < >
      <CRow>
        <CCol sm={12}>
          <CCard style={{ background: "white" }}>
            <CCardBody>
              <CRow>
                <CCol sm={5}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >Back</CButton> </CCol>
                <CCol sm={5}><CCardTitle style={{ color: "black" }}>Institute Analytics</CCardTitle></CCol>
                <CCol sm={2}>

                </CCol>

              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <br />

      {!loading &&
        <CRow>
          <CCol md={6}>
            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
              <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                <CIcon icon={cilSearch} size="xl" />
              </div>

              <CFormInput
                placeholder="Search by Institute Name..."
                autoComplete="Search"
                style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                onChange={handleSearch}
              />
            </div>
          </CCol>
          <CCol md={4}></CCol>
          <CCol md={2}>
            <div>
              <ExcelFile data={handleExcelFile} />
            </div>
          </CCol>
        </CRow>
      }

      {
        !loading && !error &&

        <div>
          <h5 style={{ marginBottom: '1%' }}>Filtered Institutes Count:<span><b>{instituteData.length}</b></span></h5>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Institute Name</th>
                <th>Institute Short Name</th>
                <th>Course Count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                search == "" ?
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{offset * 10 + (index + 1)}</td>
                      <td>{item._doc.instituteName}</td>
                      <td>{item._doc.insituteShortName}</td>
                      <td>{item.courses.length}</td>
                      <td >
                        <Link style={{ textDecoration: "none" }}
                          to={{
                            pathname: '/pages/analytics/course',
                            state: { institute: item._doc._id }
                          }}>
                          <CButton type="submit" style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }}>
                            View Course</CButton>
                        </Link>
                      </td>
                    </tr>
                  )) : instituteData.filter((val) => {
                    if (search == "") {
                      return val
                    } else if (val._doc.instituteName.toLowerCase().includes(search.toLowerCase()) ||
                      val._doc.insituteShortName.toLowerCase().includes(search.toLowerCase())) {
                      return val
                    }
                  }).map((item, index) => (
                    <tr key={index}>
                      <td>{offset * 10 + (index + 1)}</td>
                      <td>{item._doc.instituteName}</td>
                      <td>{item._doc.insituteShortName}</td>
                      <td>{item.courses.length}</td>
                      <td >
                        <Link style={{ textDecoration: "none" }}
                          to={{
                            pathname: '/pages/analytics/course',
                            state: { institute: item._doc._id }
                          }}>
                          <CButton type="submit" style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }}>
                            View Course</CButton>
                        </Link>
                      </td>
                    </tr>
                  ))

              }

            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />

        </div>
      }
      {
        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
      }
      {

        !loading && error && <h1>{error}</h1>
      }

      {
        loading && <LoadingScreen></LoadingScreen>
      }

    </>
  );
}

export default Institute;