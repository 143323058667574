import { CModalTitle, CModalBody, CButton, CModalHeader, CModal } from '@coreui/react'
import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from './../../../../../config/constant.json'
import LoadingScreen from 'src/views/shared/Loading';
import { Table } from 'react-bootstrap'
import moment from 'moment';
import DeleteDailog from '../../DeleteDailog';

const ViewScheduledMeetings = (props) => {

    const [meetData, setmeetData] = React.useState([]);
    const [loader, setloader] = React.useState(true);
    const [selectedData, setSelectedData] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);

    React.useEffect(async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getBatchMeeting?course_id=${props.data.courseId}&&batch_id=${props.data._id}`)

            if (response.data.status == true) {
                setmeetData(response.data.data)
                setloader(false)
            }
        } catch (error) {
            setloader(false)
            console.log(error);
            let toast = {
                "status": true,
                "body": error.message,
                "message": "Error"
            }
            props.addToaster(toast)
        }
    }, []);

    const handleDelete = (values) => {
        setSelectedData(values)
        setConfirm(true)

    }

    const handleDeleteMeet = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/deleteBatchMeeting`, { _id: selectedData._id })

            console.log("-->", response.data);
            if (response.data.status == true) {
                let toast = {
                    "status": true,
                    "body": response.data.message,
                    "message": "Success"
                }
                setmeetData(meetData.filter(item => item._id != selectedData._id))
                setConfirm(false)
                props.addToaster(toast)
                setSelectedData(false)
                // window.location.reload()
            }
        } catch (error) {
            console.log(error);
            setConfirm(false)
            let toast = {
                "status": true,
                "body": error.message,
                "message": "Error"
            }
            props.addToaster(toast)

        }
    }

    return (
        <CModal size="xl" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Scheduled Meetings</CModalTitle>
            </CModalHeader>
            <CModalBody>

                {
                    loader &&
                    <LoadingScreen ></LoadingScreen>
                }
                {
                    confirm &&

                    <DeleteDailog
                        visible={confirm}
                        onClose={() => setConfirm(false)}
                        delete={handleDeleteMeet}
                    />
                }

                {
                    loader == false && meetData.length > 0 &&
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Sr. No</th>
                                <th>Batch Name</th>
                                <th>Course Name</th>
                                <th>Meeting Summary</th>
                                <th>Meeting Link</th>
                                <th>Teacher Name</th>
                                <th>Meeting Date</th>
                                <th>Meeting Time</th>
                                <th>Description</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {

                                meetData.map((item, index) => (
                                    <tr key={index}>

                                        <td>{index + 1}</td>
                                        <td>{item.batch_id.batchName}</td>
                                        <td>{item.course_id.courseName}</td>
                                        <td>{item.meeting_summary}</td>
                                        <td>
                                            <a target='_blank' href={item.meeting_link}>{item.meeting_link}</a>
                                        </td>
                                        <td>{item.teacher_id.name}</td>
                                        <td>
                                            {/* Show Date and Start Time and End Time */}
                                            {moment(item.meetingDate).format('DD-MM-YYYY')}
                                        </td>
                                        <td>
                                            {/* Show Date and Start Time and End Time */}
                                            {item.meetingStartTime} - {item.meetingEndTime}
                                        </td>

                                        <td>{item.description}</td>
                                        <td>
                                            <CButton onClick={() => handleDelete(item)} color="primary" className="px-4">Delete</CButton>
                                        </td>

                                    </tr>

                                ))
                            }
                        </tbody>
                    </Table>
                }

                {
                    meetData.length == 0 && loader == false &&
                    <h1>No Data</h1>
                }

            </CModalBody>
        </CModal>
    );
}

export default ViewScheduledMeetings;