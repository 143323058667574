import React from 'react'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardImage,
    CForm, CFormInput, CSpinner, CButton,
} from '@coreui/react';
import userApi from 'src/utils/axios';;
import { Formik, Field } from "formik";
import * as yup from 'yup';
import ConfigData from '../../../../config/constant.json';

export default function ChangePassword(props) {

    console.log(props);

    const userSchema = yup.object().shape({
        newpassword: yup.string()
            .required('Password is required'),
        confirmpassword: yup.string()
            .oneOf([yup.ref('newpassword'), null], "Passwords must match")
            .required('Repeat password'),
    });

    const handleChangePassword = async (values) => {
        try {
            let data = { _id: props.user_id, password: values.newpassword }
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/user/changeUserPassword`, { data });
            if (response.data.status === true) {
                props.addToaster({
                    "status": true,
                    "body": "Password Changed Successfully",
                    "message": "Success"
                })
                props.onClose();
            } else {
                props.addToaster({
                    "status": false,
                    "body": response.data.message,
                    "message": "Error"
                })
            }

        } catch (error) {
            console.log(error);
            props.addToaster({
                type: 'error',
                message: 'Something went wrong',
                body: `${error.message}`,
            })
        }
    }


    return (
        <CModal size="lg" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Change Password</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <>
                    <CCardBody>

                        <Formik
                            initialValues={{ newpassword: '', confirmpassword: '' }}
                            validationSchema={userSchema}
                            onSubmit={(values, actions) => {
                                handleChangePassword(values);
                                actions.resetForm();
                            }}
                        >
                            {(formikProps) => (
                                <CForm>

                                    <CInputGroup className="mb-3">
                                        <legend>New Password</legend>
                                        <CFormInput
                                            type="password"
                                            placeholder="********"
                                            onChange={formikProps.handleChange('newpassword')}
                                            value={formikProps.values.newpassword}
                                            onBlur={formikProps.handleBlur('newpassword')}
                                            minLength={6}
                                        />
                                        <p style={{ color: 'red' }}>{formikProps.touched.newpassword && formikProps.errors.newpassword}</p>

                                        <legend>Confirm New Password</legend>
                                        <CFormInput
                                            type="password"
                                            placeholder="********"
                                            onChange={formikProps.handleChange('confirmpassword')}
                                            value={formikProps.values.confirmpassword}
                                            onBlur={formikProps.handleBlur('confirmpassword')}
                                            minLength={6}
                                        />
                                        <p style={{ color: 'red' }}>{formikProps.touched.confirmpassword && formikProps.errors.confirmpassword}</p>
                                    </CInputGroup>
                                    <div style={{textAlign:'right'}}>
                                    <CButton shape="square" color="info" onClick={formikProps.handleSubmit}>Submit</CButton>
                                    </div>
                                </CForm>
                            )}
                        </Formik>


                    </CCardBody>
                </>
            </CModalBody>
        </CModal>
    )
}
