import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCardBody, CCol, CRow,
    CModalHeader, CModal, CFormTextarea, CInputGroup,
    CModalTitle, CSpinner,
    CForm, CFormInput,
} from '@coreui/react'



const EditModule = (props) => {
    const [moduleDetails, setModuleDetails] = useState(null)
    const [loader, setloader] = React.useState(false)

    const moduleSchema = yup.object({
        module_serial_number: yup.number()
            .required('Serial number is required'),

        module_name: yup.string()
            .required('Name is required').min(3),

        module_description: yup.string()
            .required('About is required').min(10)

    });
    const initialValues = {
        module_name: props.modalId.module_name,
        course_id: props.courseId,
        module_description: props.modalId.module_description,
        module_serial_number: props.modalId.module_serial_number
    }

    let _id = props.modalId._id

    const handleEditModule = async (values, submitProps) => {

        try {
            setloader(true)
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/module/editmodule?_id=${props.modalId._id}`, { values })
            setloader(false)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            let toast = {
                "status": true,
                "body": "Module Edited",
                "message": "Success"
            }
            props.addToaster(toast);
            props.editModuleCallBack({ ...values, _id })
        } catch (e) {
            //console.log(e)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            props.addToaster(toast)
        }

    }


    const [formValues, setFormValues] = useState(null)

    return (
        <>
            <CModal visible={props.visible} backdrop={'static'} alignment="center" onClose={props.onClose}>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>Edit Module</CModalTitle>
                </CModalHeader>
                <CCardBody>
                    <Formik
                        initialValues={formValues || initialValues}
                        validationSchema={moduleSchema}
                        onSubmit={handleEditModule}
                        enableReinitialize
                    >
                        {(formikProps) => (
                            <CForm className='m-3'>
                                <CInputGroup className="mb-3">
                                    <legend>Serial Number</legend>
                                    <CFormInput
                                        placeholder="Serial Number"
                                        autoComplete="module_serial_number"
                                        onChange={formikProps.handleChange('module_serial_number')}
                                        value={formikProps.values.module_serial_number}
                                        onBlur={formikProps.handleBlur('module_serial_number')}
                                    />
                                </CInputGroup>
                                <p style={{ color: 'red' }}>{formikProps.touched.module_serial_number && formikProps.errors.module_serial_number}</p>

                                <CInputGroup className="mb-3">
                                    <legend>Module Name</legend>
                                    <CFormInput
                                        placeholder="Module Name"
                                        autoComplete="module_name"
                                        onChange={formikProps.handleChange('module_name')}
                                        value={formikProps.values.module_name}
                                        onBlur={formikProps.handleBlur('module_name')}
                                    />
                                </CInputGroup>
                                <p style={{ color: 'red' }}>{formikProps.touched.module_name && formikProps.errors.module_name}</p>

                                <CInputGroup className="mb-3">
                                    <legend>Module Description</legend>
                                    <CFormTextarea
                                        id="module_description"
                                        rows="3"
                                        placeholder='moduledescription'
                                        text="Must be 8-20 words long."
                                        onChange={formikProps.handleChange('module_description')}
                                        value={formikProps.values.module_description}
                                        onBlur={formikProps.handleBlur('module_description')}
                                    ></CFormTextarea>
                                </CInputGroup>
                                <p style={{ color: 'red' }}>{formikProps.touched.module_description && formikProps.errors.module_description}</p>

                                <CRow >
                                    <CCol xs={5}> </CCol>
                                    {loader && <CCol xs={2}>  <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary"><CSpinner component="span" size="sm" aria-hidden="true" />Update</button>  </CCol>}
                                    {!loader && <CCol xs={2}>  <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Update</button>  </CCol>}
                                    <CCol xs={5}> </CCol>

                                </CRow>
                            </CForm>
                        )}
                    </Formik>
                </CCardBody>
            </CModal>
        </>
    );
}

export default EditModule;