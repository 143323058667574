import React, { useState, useEffect } from "react";
import { CCard, CButton, CNavLink, CFormSelect, CTabPane, CNav, CNavItem, CTabContent, CCardBody, CCol, CRow, CCardTitle, CFormInput, CSpinner } from '@coreui/react'
import { Table } from 'react-bootstrap'
import userApi from 'src/utils/axios.js';
import ConfigData from '../../../../config/constant.json';
import LoadingScreen from "src/views/shared/Loading";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ExcelFile from 'src/views/shared/ExcelFile';
import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const User = (batchId) => {

    const [activeKey, setActiveKey] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filterByCart, setFilterByCart] = useState('');
    const [filterByEnrolled, setFilterByEnrolled] = useState('');
    const [cartStudents, setCartStudents] = useState([]);
    const [enrolledStudents, setEnrolledStudents] = useState([]);
    const itemsPerPage = 10;
    const [offsetCart, setOffsetCart] = useState(0);
    const [pageCountCart, setPageCountCart] = useState(0);
    const [offsetEnrolled, setOffsetEnrolled] = useState(0);
    const [pageCountEnrolled, setPageCountEnrolled] = useState(0);
    const [inCartCount, setInCartCount] = useState(0);
    const [enrolledCount, setEnrolledCount] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState('');

    let timeoutId; // Declare timeoutId variable outside of debounce function
    const inputRefIncart = React.useRef(null);
    const inputRefEnrolled = React.useRef(null);
    useEffect(() => {
        if (activeKey === 1) {
            setFilterByCart('');
            if (debouncedValue !== '') {
                getInCartData(batchId.batchId, debouncedValue);
            } else {
                getInCartData(batchId.batchId, '');
            }
        } else if (activeKey === 2) {
            setFilterByEnrolled('');
            getEnrolledData(batchId.batchId, debouncedValue);
        }

    }, [activeKey, debouncedValue])

    // Fetch In Cart students data when offset or filterBy changes
    useEffect(() => {
        getInCartData(batchId.batchId, '');
    }, [offsetCart, filterByCart])

    // Fetch Enrolled students data when offset or filterBy changes
    useEffect(() => {
        getEnrolledData(batchId.batchId, '');
    }, [offsetEnrolled, filterByEnrolled])

    const handleTabChange = async (key) => {

        setActiveKey(key);
        // getData(type); 
        setFilterByCart('');
        setFilterByEnrolled('');
        setOffsetCart(0);
        setPageCountEnrolled(0);
        setOffsetEnrolled(0);
        if (inputRefIncart.current) {
            inputRefIncart.current.value = '';
        }
        if (inputRefEnrolled.current) {
            inputRefEnrolled.current.value = '';
        }
        setDebouncedValue('');
    }

    // Handle filter by student type change in cart
    const filterByStudentCart = (e) => {
        // setCartStudents([]);
        setFilterByCart(e.target.value);

    }

    // Handle filter by student type change in enrolled
    const filterByStudentEnrolled = (e) => {
        // setEnrolledStudents([]);
        setFilterByEnrolled(e.target.value);

    }

    // Handle pagination click in cart
    const handlePageClickCart = (e) => {
        setOffsetCart(e.selected)
    }

    // Handle pagination click in cart
    const handlePageClickEnrolled = (e) => {
        setOffsetEnrolled(e.selected)
    }



    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }
    const handleExcelFile = async (type) => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelDataCartAndEnrolled?batchId=${batchId.batchId}&&type=${type}&&filterBy=${filterByCart}`)
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.log(e.message)
        }
    }


    const getInCartData = async (batchId, searchTerm) => {
        try {
            setLoading(true);
            const filterCartValue = filterByCart ? (filterByCart === 'all' ? '' : filterByCart) : '';
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getInCartStudents?batchId=${batchId}&filterBy=${filterCartValue}&offset=${offsetCart}&searchTerm=${searchTerm}`);

            if (response.data?.data) {
                setCartStudents(response.data?.data?.CartData);
                setInCartCount(response.data?.data?.totalCount)
                setPageCountCart(Math.ceil(response.data?.data?.totalCount / itemsPerPage))

            } else {
                setInCartCount(0)
            }
            setLoading(false); // Set loader to false after data fetching
        } catch (err) {
            setLoading(false) // Set loader to false on error
        }
    }


    const getEnrolledData = async (batchId, searchTerm) => {
        try {
            setLoading(true);
            const filterEnrolledValue = filterByEnrolled ? (filterByEnrolled === 'all' ? '' : filterByEnrolled) : '';
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getEnrolledStudents?batchId=${batchId}&filterBy=${filterEnrolledValue}&offset=${offsetEnrolled}&searchTerm=${searchTerm}`);

            if (response.data?.data) {
                setEnrolledStudents(response.data?.data?.enrolledData);
                setEnrolledCount(response.data?.data?.totalCount)
                setPageCountEnrolled(Math.ceil(response.data?.data?.totalCount / itemsPerPage))
            }
            setLoading(false); // Set loader to false after data fetching
        } catch (err) {
            setLoading(false) // Set loader to false on error
        }
    }

    return (
        <div className="row">
            <CNav variant="underline" layout="fill">
                <CNavItem  >
                    <CNavLink
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                            fontWeight: activeKey === 1 ? '700' : '500',
                            fontSize: activeKey === 1 ? '20px' : '18px',
                        }}
                        active={activeKey === 1}
                        onClick={() => handleTabChange(1)}
                    >
                        InCart Students
                    </CNavLink>
                </CNavItem>

                <CNavItem>
                    <CNavLink
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                            fontWeight: activeKey === 2 ? '700' : '500',
                            fontSize: activeKey === 2 ? '20px' : '18px',
                        }}
                        active={activeKey === 2}
                        onClick={() => handleTabChange(2)}
                    >
                        Enrolled Students
                    </CNavLink>
                </CNavItem>

            </CNav>
            <>
                <CTabContent>
                    <CTabPane visible={activeKey === 1}>
                        {
                            <CRow>

                                <CCol sm="4" style={{ marginTop: '15px' }}>
                                    <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                            <CIcon icon={cilSearch} size="xl" />
                                        </div>

                                        <CFormInput
                                            ref={inputRefIncart}
                                            placeholder="Search..."
                                            autoComplete="Search"
                                            style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </CCol>


                                <CCol sm="4">
                                    <div style={{ marginTop: '15px', marginLeft: '40%' }}>
                                        <ExcelFile data={() => handleExcelFile('cart')} />
                                    </div>
                                </CCol>
                                <CCol sm="4" style={{ marginTop: '15px', marginBottom: '5px' }}>
                                    <CFormSelect aria-label="Default select example" onChange={filterByStudentCart} value={filterByCart}>
                                        <option disabled value="" selected={true}>Filter By</option>
                                        <option value="all">All</option>
                                        <option value="InHouse">InHouse Students</option>
                                        <option value="OutSider">OutSider Students</option>
                                        <option value="TEACHER">Teacher</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol sm="3" style={{ marginTop: '15px', marginBottom: '5px' }}>
                                    Total students : {inCartCount}
                                </CCol>
                                <br />
                            </CRow>
                        }
                        {
                            loading &&
                            <LoadingScreen></LoadingScreen>

                        }
                        {
                            !loading && cartStudents &&
                            <>
                                <Table striped bordered hover id='user-table'>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>User Name</th>
                                            <th>Role</th>
                                            <th>Email</th>
                                            <th>Batch Name</th>
                                            <th>Course Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartStudents.length > 0 &&
                                            cartStudents.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{offsetCart * 10 + (index + 1)}</td>
                                                    <td>{item.user[0]?.fullName ? item.user[0]?.fullName : (item.user[0]?.firstName ? `${item.user[0]?.firstName} ${item.user[0]?.lastName}` : item.user[0]?.name)}</td>
                                                    <td>{item.user[0]?.roleType === 'TEACHER' ? 'TEACHER' : 'STUDENT'}</td>
                                                    <td>{item?.user[0]?.email}</td>
                                                    <td>{item?.batch[0]?.batchName}</td>
                                                    <td>{item?.course[0]?.courseName}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </>
                        }
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCountCart}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={(event) => { handlePageClickCart(event) }}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={offsetCart}
                        />
                        {
                            !loading && cartStudents.length === 0 &&
                            <div style={{ textAlign: 'center', marginTop: '100px', fontSize: '30px', fontWeight: '500' }}>
                                No students have added this batch to their carts.
                            </div>
                        }
                    </CTabPane>
                </CTabContent>
                <CTabContent>
                    <CTabPane visible={activeKey === 2}>
                        {
                            <CRow>

                                <CCol sm="4" style={{ marginTop: '15px' }}>
                                    <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                            <CIcon icon={cilSearch} size="xl" />
                                        </div>

                                        <CFormInput
                                            ref={inputRefEnrolled}
                                            placeholder="Search..."
                                            autoComplete="Search"
                                            style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </CCol>
                                <CCol sm="4">
                                    <div style={{ marginTop: '15px', marginLeft: '40%' }}>
                                        <ExcelFile data={() => handleExcelFile('enrolled')} />
                                    </div>
                                </CCol>
                                <CCol sm="4" style={{ marginTop: '15px', marginBottom: '5px' }}>
                                    <CFormSelect aria-label="Default select example" onChange={filterByStudentEnrolled} value={filterByEnrolled} >
                                        <option disabled value="" selected={true}>Filter By</option>
                                        <option value="all">All</option>
                                        <option value="InHouse">InHouse Students</option>
                                        <option value="OutSider">OutSider Students</option>
                                        <option value="TEACHER">Teacher</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol sm="4" style={{ marginTop: '15px', marginBottom: '5px' }}>
                                    Total students : {enrolledCount}
                                </CCol>
                                <br />
                            </CRow>
                        }
                        {
                            loading &&
                            <LoadingScreen></LoadingScreen>

                        }
                        {
                            !loading && enrolledStudents &&
                            <>
                                <Table striped bordered hover id='user-table'>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>User Name</th>
                                            <th>Role</th>
                                            <th>Email</th>
                                            <th>Batch Name</th>
                                            <th>Course Name</th>
                                            <th>Amount</th>
                                            <th>Payment Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enrolledStudents.length > 0 &&
                                            enrolledStudents.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{offsetEnrolled * 10 + (index + 1)}</td>
                                                    <td>{item.user[0]?.fullName ? item.user[0]?.fullName : (item.user[0]?.firstName ? `${item.user[0]?.firstName} ${item.user[0]?.lastName}` : item.user[0]?.name)}</td>
                                                    <td>{item.user[0]?.roleType === 'TEACHER' ? 'TEACHER' : 'STUDENT'}</td>
                                                    <td>{item?.user[0]?.email}</td>
                                                    <td>{item?.batch[0]?.batchName}</td>
                                                    <td>{item?.course[0]?.courseName}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </>
                        }
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCountEnrolled}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={(event) => { handlePageClickEnrolled(event) }}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={offsetEnrolled}

                        />
                        {
                            !loading && enrolledStudents.length === 0 &&
                            <div style={{ textAlign: 'center', marginTop: '100px', fontSize: '30px', fontWeight: '500' }}>
                                No students have Enrolled this batch.
                            </div>
                        }
                    </CTabPane>
                </CTabContent>
            </>
        </div >
    );
}

export default User;