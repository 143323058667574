import React from 'react'
import { CButton, CRow, CCol, CCard, CCardBody, CCardTitle, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import LoadingScreen from 'src/views/shared/Loading';
import userApi from 'src/utils/axios';;
import CreateRole from './CreateRole';
import Toaster from 'src/views/shared/Toaster';
import ConfigData from '../../../config/constant.json';
import DeleteDailog from '../Master/DeleteDailog';
import EditRole from './EditRole';
import CreateUser from './Users/CreateUser';
import Users from './Users/Users';

export default function RoleHome() {

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    const [openCreateRoleModal, setCreateRoleModal] = React.useState(false);
    const [toast, addToast] = React.useState(false);
    const [activeKey, setActiveKey] = React.useState(1);
    const [allroles, setAllRoles] = React.useState([]);
    const [loader, setloader] = React.useState(true);
    const [role_id, setRole_id] = React.useState();
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [viewEditDialog, setViewEditDialog] = React.useState(false);
    const [openCreateUser, setOPenCreateUser] = React.useState(false);
    const [roles, setRoles] = React.useState(true);

    React.useEffect(() => {
        if (activeKey === 1 && !openCreateRoleModal && !deleteDailog && !viewEditDialog) {
            getRoleAccess();
        }
    }, [activeKey, openCreateRoleModal, deleteDailog, viewEditDialog]);

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRoles(false);
        } else {
            setRoles(true);
        }
    }, [])

    const getRoleAccess = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/roleAccess/getAllRoleAccess`);
            if (response.data.status == true) {
                console.log(response.data);
                setAllRoles(response.data.allRoles);
                setloader(false);
            }
        } catch (error) {
            console.log(error);
            let toast = {
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            }
            addToaster(toast);
        }
    }

    const handleDelete = async () => {
        try {

            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/roleAccess/deleteRoleAccess`, { role_id });
            if (response.data.status == true) {
                let toast = {
                    "status": true,
                    "body": "Role Deleted Successfully",
                    "message": "Success"
                }
                setAllRoles([...allroles.filter((role) => role._id !== role_id)]);
                setDeleteDailog(false);
                addToaster(toast);
            }
        } catch (error) {
            console.log(error);
            let toast = {
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            }
            addToaster(toast);
        }
    }

    const CallBack = (data) => {
        console.log(data);
        setAllRoles([...allroles, data])
        console.log("CallBack", data);
    }

    const EditCallBack = (data) => {
        let index = allroles.findIndex((role) => role._id === data._id);
        allroles[index] = data;
        setAllRoles([...allroles]);
        setViewEditDialog(false);
    }

    return (
        <>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "#fff" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={4}> <CCardTitle style={{ color: "#000" }}>Role Base Access</CCardTitle></CCol>
                                <CCol sm={6}></CCol>
                                {
                                    roles &&
                                    <CCol sm={2}><CButton onClick={() => activeKey == 1 ? setCreateRoleModal(true) : setOPenCreateUser(true)} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >{`${activeKey == 1 ? 'Create Role' : 'Create User'}`}</CButton></CCol>
                                }
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            {
                openCreateRoleModal &&
                <CreateRole
                    openCreateRoleModal={openCreateRoleModal}
                    closeCreateRoleModal={() => setCreateRoleModal(false)}
                    addToaster={addToaster}
                    CallBack={CallBack}
                />
            }
            {
                deleteDailog &&
                <DeleteDailog
                    visible={deleteDailog}
                    onClose={() => setDeleteDailog(false)}
                    delete={handleDelete}

                />
            }
            {
                viewEditDialog &&
                <EditRole
                    visible={viewEditDialog}
                    onClose={() => setViewEditDialog(false)}
                    addToaster={addToaster}
                    role={role_id}
                    EditCallBack={EditCallBack}
                />
            }
            {
                openCreateUser &&
                <CreateUser
                    openCreateUser={openCreateUser}
                    onClose={() => setOPenCreateUser(false)}
                    addToaster={addToaster}
                    allRoles={allroles}

                />
            }
            {
                toast &&
                <Toaster
                    message={toast.message}
                    status={toast.status}
                    body={toast.body} >
                </Toaster>
            }

            <div className="row" style={{ marginTop: "2%" }}>

                {/* Tab View */}
                <CNav variant="underline" layout="fill">
                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 1 ? '700' : '500',
                                fontSize: activeKey === 1 ? '20px' : '18px',
                            }}
                            active={activeKey === 1}
                            onClick={() => setActiveKey(1)}
                        >
                            Roles
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 2 ? '700' : '500',
                                fontSize: activeKey === 2 ? '20px' : '18px',
                            }}
                            active={activeKey === 2}
                            onClick={() => setActiveKey(2)}
                        >
                            Users
                        </CNavLink>
                    </CNavItem>
                </CNav>


                <div className="col-md-1"></div>

                <div className="col-md-10">

                    <CTabContent>
                        <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 1}>

                            <div className="row" style={{ marginTop: "3%" }}>
                                <div className="col-md-12">
                                    <CCard>

                                        <CCardBody>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>##</th>
                                                        <th>RoleName</th>
                                                        <th>Role Access</th>
                                                        {
                                                            roles &&
                                                            <th>Action</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        allroles.map((role, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{role.roleName}</td>
                                                                    <td>{role.roleAccess.map((data, index) => <React.Fragment key={index} ><div style={{ marginLeft: '1%' }}> {index + 1}.{data} </div></React.Fragment>)}</td>
                                                                    {roles &&

                                                                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <CButton disabled={role.roleName == 'admin'} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} onClick={() => { setRole_id(role); setViewEditDialog(true) }} >Edit Roles</CButton>
                                                                        </td>

                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </Table>
                                        </CCardBody>
                                    </CCard>
                                </div>
                            </div>

                        </CTabPane>

                    </CTabContent>

                    <CTabContent>
                        <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 2}>

                            <Users
                                call={activeKey}
                                addToaster={addToaster}
                                allRoles={allroles}
                                openCreateUser={openCreateUser}
                                setOPenCreateUser={setOPenCreateUser}
                            />





                        </CTabPane>

                    </CTabContent>

                </div>


            </div>
        </>
    )
}
