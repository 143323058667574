import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from '../../../../config/constant.json'
import { CModalHeader, CModal, CModalTitle, CModalBody } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import UserReport from './UserReport';
import UserBatchReport from './UserBatchReport';

const ViewUserBatchs = (props) => {

    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [purchasedBatch, setPurchasedBatch] = React.useState(false)
    const [viewBatchReport, setViewBatchReport] = React.useState(false)
    const [batchId, setBatchId] = React.useState(null)
    const [batchname, setBatchname] = React.useState(null)


    React.useEffect(async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getPurchesedBatch?userId=${props.selectedUserId}`)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setPurchasedBatch(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])


    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }



    return (
        <>{
            viewBatchReport &&
            <UserBatchReport
                visible={viewBatchReport}
                onClose={() => setViewBatchReport(false)}
                selectedUserId={props.selectedUserId}
                batchId={batchId}
                batchName={batchname}
            ></UserBatchReport>
        }

            <CModal size="xl" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>View Batches</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {
                        loading && <LoadingScreen></LoadingScreen>
                    }
                    {
                        !loading && !error &&
                        <div >
                            <div>

                            </div>
                            {purchasedBatch.length > 0 ?
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Course Name</th>
                                            <th>Batch Name</th>
                                            <th>Bank Reference No</th>
                                            <th>Order Id</th>
                                            <th>Amount</th>
                                            <th>View Analytics</th>
                                            {/* <th>Report</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            purchasedBatch.map((item, index) => (
                                                <tr key={item._id}>
                                                    <td>{index + 1}</td>
                                                    <td >{item.courseId.courseName}</td>
                                                    <td >{item.batchId.batchName}</td>
                                                    <td>{item.bankRefNo}</td>
                                                    <td>{item.orderId}</td>
                                                    <td>{item.amount}</td>
                                                    <td className="text-center" >
                                                        <Link style={{ textDecoration: "none" }}
                                                            to={{
                                                                pathname: '/pages/analytics/user_analytics',
                                                                state: { user_id: props.selectedUserId, batch_id: item.batchId._id }
                                                            }}>
                                                            <button type="submit" className="btn btn-outline-primary">View</button>
                                                        </Link>
                                                    </td>
                                                    {/* <td> <button id ={`batchId${item.batch_id._id}`} type="submit" className="btn btn-outline-primary" onClick={()=>handleReport(item.batch_id._id,item.batch_id.batchName)}>Report</button></td> */}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table> : <div>No data</div>
                            }
                        </div>
                    }
                    {
                        !loading && error && <h1>{error}</h1>
                    }

                    {
                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                    }
                </CModalBody>
            </CModal>


        </>
    );
}

export default ViewUserBatchs;