import React, { useState, useEffect, useRef } from 'react'
import { CRow, CCol, CCard, CCardBody, CButton, CCardTitle, CFormInput, CFormSelect, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react/dist/index.js';
import { useHistory } from 'react-router-dom';
import AddOfflinePayment from './AddOfflinePayment';
import { Table } from 'react-bootstrap';
import ExcelFile from 'src/views/shared/ExcelFile';
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import LoadingScreen from "src/views/shared/Loading";
import logo from '../../../assets/images/HSNCULogo.png';
import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const OfflinePayment = () => {

    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);
    const [offlinePayment, setOfflinePayment] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const itemsPerPage = 10;

    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterBy, setFilterBy] = useState('');
    const [visible, setVisible] = useState(false)
    const contentRef = useRef();
    const [receiptData, setReceiptData] = useState();
    const [couponData, setCouponData] = useState();
    const [role, setRole] = useState(true);
    const [count, setCount] = useState(0);

    // Fetch payment data when debounced search term changes
    useEffect(() => {
        if (debouncedValue !== '') {
            getPaymentData(debouncedValue);
        } else {
            getPaymentData('');
        }

    }, [debouncedValue]);

    useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    // Fetch payment data on initial load
    useEffect(() => {
        if (!offlinePayment) {
            getPaymentData('');
        }
    }, [offlinePayment])

    // Fetch payment data when offset or filterBy changes
    useEffect(() => {
        getPaymentData('');
    }, [offset, filterBy])

    // Function to fetch payment data
    const getPaymentData = async (searchTerm) => {
        try {
            setLoader(true);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/payment/getOfflinePaymentData?searchTerm=${searchTerm}&offset=${offset}&filterBy=${filterBy}`);
            if (response.data?.data) {
                setUsers(response.data?.data?.paymentData);
                setPageCount(Math.ceil(response.data?.data?.paymentDataCount / itemsPerPage));
                setCount(response.data?.data?.paymentDataCount)
            }
            setLoader(false); // Set loader to false after data fetching

        } catch (e) {
            setError(`${e.message} :(`)
            setLoader(false) // Set loader to false on error
        }

    }
    //Handle excel file 
    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/payment/getExcelOfflinePaymentData?filterBy=${filterBy}`)
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    // Handle filter by student type change
    const filterByStudent = (e) => {
        setFilterBy(e.target.value);
    }

    //Handle paymentReceipt view
    const paymentReceipt = async (userId, batchId, courseId) => {
        try {
            setVisible(!visible);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/payment/getReceiptData?userId=${userId}&batchId=${batchId}&courseId=${courseId}`);
            if (response.data.data) {
                setReceiptData(response.data.data);
                setCouponData(response.data.couponData);
            }

        } catch (error) {
            console.log(error.message)
        }
    }

    //Handle payment receipt download
    const downloadPDF = () => {
        const contentElement = contentRef.current;

        html2canvas(contentElement).then((canvas) => {
            const imgWidth = 210; // mm (A4 width)
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjust height to keep aspect ratio

            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('payment-receipt.pdf');
        });
    };

    return (
        <>
            <CRow>
                <CCol sm={12} className='pb-4'>
                    <CCard style={{ background: "#fff" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={6}>
                                    <CCardTitle style={{ color: "#000" }}>{offlinePayment ? 'Add Offline Payment' : 'Offline Payment'}</CCardTitle>
                                </CCol>
                                {role &&
                                    <CCol sm={6} className="d-flex justify-content-end" >
                                        {!offlinePayment && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setOfflinePayment(true)}>Add Offline Payment</CButton>}
                                        {offlinePayment && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setOfflinePayment(false)}>Back</CButton>}
                                    </CCol>
                                }
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            {
                !offlinePayment &&
                <div>
                    {/* search and filter section */}
                    <CRow>
                        <CCol md={6} style={{ paddingLeft: '30px' }}>

                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                    <CIcon icon={cilSearch} size="xl" />
                                </div>

                                <CFormInput
                                    placeholder="Search..."
                                    autoComplete="Search"
                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                    onChange={handleSearch}
                                />
                            </div>
                        </CCol>
                        <CCol md={2}>
                            <div>
                                <ExcelFile data={handleExcelFile} />
                            </div>
                        </CCol>
                        <CCol md={4} style={{ paddingRight: '50px' }}>
                            <CFormSelect aria-label="Default select example" onChange={filterByStudent}>
                                <option disabled selected="true">Filter By</option>
                                <option value="">All</option>
                                <option value="InHouse">InHouse</option>
                                <option value="OutSider">OutSider</option>
                                <option value="TEACHER">Teacher</option>
                            </CFormSelect>
                        </CCol>
                    </CRow >
                    {
                        !loader ?
                            <>
                                <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Users Count: <span ><b>{count}</b></span></h5>
                                <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                                    <Table striped bordered hover id='user-table'>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>User Name</th>
                                                <th>Course</th>
                                                <th>Batch</th>
                                                <th>Student Type</th>
                                                <th>Status</th>
                                                <th>Amount</th>
                                                <th>Payment Receipt</th>
                                                <th>Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 &&
                                                users.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td>{offset * 10 + (index + 1)}</td>
                                                        <td>{item.user[0]?.fullName ? item.user[0]?.fullName : (item.user[0]?.firstName ? `${item.user[0]?.firstName} ${item.user[0]?.lastName}` : item.user[0]?.name)}</td>
                                                        <td>{item.course[0]?.courseName}</td>
                                                        <td>{item.batch[0]?.batchName}</td>
                                                        <td>{item.user[0]?.roleType === 'TEACHER' ? 'TEACHER' : (item.user[0]?.registration ? 'OutSider' : 'InHouse')}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.amount}</td>
                                                        <td><CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => { paymentReceipt(item.user[0]?.userId, item.batch[0]?._id, item.course[0]?._id) }}>View Receipt</CButton></td>
                                                        <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                    {/* Pagination Component */}
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={(event) => { handlePageClick(event) }}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={offset}
                                    />
                                </div>
                            </>
                            : <LoadingScreen />
                    }

                </div>


            }
            {/* No Data Found Message */}
            {
                users.length === 0 && loader === false &&
                <h3 style={{ margin: '3%', textAlign: 'center' }}>No Data Found</h3>
            }

            {offlinePayment &&
                <AddOfflinePayment />
            }


            <CModal
                size="xl"
                alignment="center"
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="VerticallyCenteredExample"
            >
                <CModalHeader>
                    <CModalTitle id="VerticallyCenteredExample">Payment Receipt</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {
                        receiptData &&
                        <div>
                            <div ref={contentRef} style={{ padding: ' 5px 20px' }}>
                                <div>
                                    <img src={logo} height="150" width="120" alt="Logo"
                                        style={{ float: 'left', paddingTop: '30px' }} />
                                    <div style={{ textAlign: 'center', paddingTop: '30px', paddingLeft: '80px' }}>
                                        <h1>HSNC UNIVERSITY, MUMBAI</h1>
                                        <h4>(A STATE PUBLIC UNIVERSITY)</h4>
                                        <p style={{ fontSize: '18px' }}>47, Dr. R. G.
                                            Thadani
                                            Marg, Maharashtra,
                                            Mumbai - 400018</p>
                                        <div style={{ textAlign: 'center', paddingTop: '25px', fontSize: '22px', fontWeight: '700' }}>
                                            <u>
                                                RECEIPT
                                                VOUCHER</u>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: '10px 50px' }}>
                                    <div style={{ float: 'left' }}>
                                        <h5 style={{ fontWeight: '700' }}>RECIPIENT</h5>
                                        <div style={{ textTransform: 'capitalize', fontWeight: '500', fontSize: '18px' }}>
                                            {receiptData[0]?.user[0]?.fullName}
                                        </div>
                                        <div style={{ fontWeight: '500', fontSize: '18px' }}>
                                            {receiptData[0]?.user[0]?.studentEmail}
                                        </div>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <div>
                                            <div style={{ fontSize: '18px' }}><strong>Order ID: </strong> <span
                                                style={{ fontWeight: '500', fontSize: '18px' }}>
                                                {receiptData[0]?.orderId}</span>
                                            </div>
                                            {
                                                receiptData[0]?.bankRefNo &&
                                                <div style={{ fontSize: '18px' }}>
                                                    <strong>Bank Reference No: </strong> <span
                                                        style={{ fontWeight: '500', fontSize: '18px' }}>
                                                        {receiptData[0]?.bankRefNo}
                                                    </span>
                                                </div>
                                            }
                                            {
                                                receiptData[0]?.utrNumber &&
                                                <div style={{ fontSize: '18px' }}>
                                                    <strong>UTR Number: </strong> <span
                                                        style={{ fontWeight: '500', fontSize: '18px' }}>
                                                        {receiptData[0]?.utrNumber}
                                                    </span>
                                                </div>
                                            }
                                            {
                                                receiptData[0]?.chequeNo &&
                                                <div style={{ fontSize: '18px' }}>
                                                    <strong>Cheque Number: </strong> <span
                                                        style={{ fontWeight: '500', fontSize: '18px' }}>
                                                        {receiptData[0]?.chequeNo}
                                                    </span>
                                                </div>
                                            }

                                            <div style={{ fontSize: '18px' }}><strong>Date:</strong> <span
                                                style={{ fontWeight: '500', fontSize: '18px' }}>
                                                {moment(receiptData[0]?.createdAt).format('DD-MM-YYYY')}</span></div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Course</th>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Batch</th>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Payment Mode</th>
                                                    <th style={{ border: '1px solid black', padding: '8px' }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ border: '1px solid black', padding: '8px', fontSize: '18px' }}>
                                                        {receiptData[0]?.course[0]?.courseName}
                                                    </td>
                                                    <td style={{ border: '1px solid black', padding: '8px', fontSize: '18px' }}>
                                                        {receiptData[0]?.batch[0]?.batchName}
                                                    </td>
                                                    <td style={{ border: '1px solid black', padding: '8px', fontSize: '18px' }}>
                                                        {receiptData[0]?.paymentMode}
                                                    </td>
                                                    <td style={{ border: '1px solid black', padding: '8px', fontSize: '18px' }}>
                                                        {receiptData[0]?.user[0]?.roleType === 'TEACHER' ? receiptData[0]?.batch[0]?.teacherFees : (receiptData[0]?.user[0]?.registration === true ? receiptData[0]?.batch[0]?.outHouseFees : receiptData[0]?.batch[0]?.inHouseFees)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <strong>Total:</strong>
                                        <span
                                            style={{ fontWeight: '500', fontSize: '18px', display: 'inline-block', width: '65px', textAlign: 'right' }}>
                                            {receiptData[0]?.user[0]?.roleType === 'TEACHER' ? receiptData[0]?.batch[0]?.teacherFees : (receiptData[0]?.user[0]?.registration === true ? receiptData[0]?.batch[0]?.outHouseFees : receiptData[0]?.batch[0]?.inHouseFees)}
                                        </span>
                                    </div>
                                    {
                                        couponData &&
                                        <div style={{ textAlign: 'right' }}>
                                            <strong>Discount (-) :</strong>
                                            <span
                                                style={{ fontWeight: '500', fontSize: '18px', display: 'inline-block', width: '65px', textAlign: 'right' }}>
                                                {couponData?.amount}
                                            </span>
                                        </div>
                                    }
                                    <div style={{ textAlign: 'right' }}>
                                        <strong>Grand Total:</strong>
                                        <span
                                            style={{ fontWeight: '500', fontSize: '18px', display: 'inline-block', width: '65px', textAlign: 'right' }}>
                                            {receiptData[0]?.amount}
                                        </span>
                                    </div>
                                    {
                                        receiptData[0].paymentMode !== 'ONLINE' &&
                                        <div >
                                            <span style={{ fontSize: ' medium' }}><b>Remark : </b> <span
                                                style={{ fontWeight: '500', fontSize: 'medium' }}>{receiptData[0].remark} </span></span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={downloadPDF}>Download Receipt</CButton>
                </CModalFooter>
            </CModal >
        </>
    )
}

export default OfflinePayment;
