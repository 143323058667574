import React from 'react'
import userApi from 'src/utils/axios';
import { Formik, FieldArray, ErrorMessage } from "formik";
import ConfigData from './../../../../config/constant.json'
import { CButton, CCol, CRow, CFormLabel, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import * as yup from 'yup';
import CIcon from '@coreui/icons-react'
import { cilScrubber } from '@coreui/icons'
import{cilCircle} from '@coreui/icons'
const ManageQuiz = ({ visible, courseId, moduleId }) => {
    let model_id = moduleId
    let course_id = courseId
    const [toast, addToast] = React.useState(false)

    const QuizSchema = yup.object().shape({
        quiz: yup.array()
            .of(yup.object().shape({

                questionTitle: yup.string()
                    .required('Question  is required'),
                answerOptions: yup.array()
                    .of(yup.object().shape({
                        answerBody: yup.string().required("Answer is required"),
                        isCorrectAnswer: false
                        ,

                        answerBody: yup.string().required("Answer is required"),
                        isCorrectAnswer: false
                        ,

                        answerBody: yup.string().required("Answer is required"),
                        isCorrectAnswer: false
                        ,

                        answerBody: yup.string().required("Answer is required"),
                        isCorrectAnswer: false

                    }))
            }))
    })

    let QuizStructure = {
        questionTitle: '',
        answerOptions: [
            {
                answerBody: '',
                isCorrectAnswer: false
            },
            {
                answerBody: '',
                isCorrectAnswer: false
            },
            {
                answerBody: '',
                isCorrectAnswer: false
            },
            {
                answerBody: '',
                isCorrectAnswer: false
            }
        ]

    }

    const initialValues = {
        quiz: [QuizStructure]
    }




    const handleQuesAns = async (values) => {
        try {

            let data = {
                quiz: {
                    model_id: model_id,
                    questions: values.quiz,
                    course_id: course_id
                }
            }

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/addquiz`, data)
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            else {
                console.log("Success");
                let toast = {
                    "status": true,
                    "body": "Quiz Added",
                    "message": "Success"
                }
                addToaster(toast)
            }
        } catch (error) {
            console.log(error);

        }


    }
    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }


    return (<div>
        {
            visible = { visible }
            &&
            <div>
                <div style={{ position: "relative" }}>
                    <br />
                    {/* <h1>Add Questions :-</h1> */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={QuizSchema}
                        onSubmit={(values) =>handleQuesAns(values)
                        }
                    >
                        {(formikProps) => (
                            <div>
                                <FieldArray name="quiz">
                                    {({ insert, remove, push }) => (
                                        <div>
                                            {formikProps.values.quiz.length > 0 &&
                                                formikProps.values.quiz.map((quiz, index) =>
                                                (
                                                    <div key={index}>
                                                        <CAccordion style={{ width: "100%" }} activeItemKey={1}>
                                                            <CAccordionItem itemKey={1}>
                                                                <CAccordionHeader>
                                                                    Form - {index + 1}
                                                                </CAccordionHeader>
                                                                <CAccordionBody >
                                                                    <div className="mb-3">
                                                                        <CFormLabel htmlFor={`quiz.${index}.questionTitle`}>Add Question</CFormLabel>
                                                                        <CFormInput
                                                                            name={`quiz.${index}.questionTitle`}
                                                                            placeholder="Question"
                                                                            onChange={formikProps.handleChange(`quiz.${index}.questionTitle`)}
                                                                            onBlur={formikProps.handleBlur(`quiz.${index}.questionTitle`)}
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`quiz.${index}.questionTitle`}
                                                                            component="div"
                                                                            className="field-error"
                                                                            style={{ color: 'red' }}
                                                                        />
                                                                    </div>
                                                                    <CFormLabel >Add 4 Answers </CFormLabel>
                                                                    <FieldArray name={`quiz.${index}.answerOptions`}>
                                                                        {
                                                                            ({ insert, remove, push }) =>
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        formikProps.values.quiz[index].answerOptions.length > 0 &&
                                                                                        formikProps.values.quiz[index].answerOptions.map((answer, index2) => (
                                                                                            <div className="mb-3" key={index2}>
                                                                                                <br />

                                                                                                <CFormInput
                                                                                                    name={`quiz.${index}.answerOptions.${index2}.answer1`}
                                                                                                    placeholder="Answer here"
                                                                                                    onChange={formikProps.handleChange(`quiz.${index}.answerOptions.${index2}.answerBody`)}
                                                                                                    onBlur={formikProps.handleBlur(`quiz.${index}.answerOptions.${index2}.answerBody`)}
                                                                                                />
                                                                                                <ErrorMessage
                                                                                                    name={`quiz.${index}.answerOptions.${index2}.answerBody`}
                                                                                                    component="div"
                                                                                                    className="field-error"
                                                                                                    style={{ color: 'red' }}
                                                                                                />
                                                                                                    <div 
                                                                                                        type="button"
                                                                                                        className={formikProps.values.quiz[index].answerOptions
                                                                                                            ? "active"
                                                                                                            : ""}
                                                                                                        onClick={() => {
                                                                                                            formikProps.values.quiz[index].answerOptions.map(
                                                                                                                (tem3, index3) => {
                                                                                                                    formikProps.setFieldValue(`quiz.${index}.answerOptions.${index3}.isCorrectAnswer`, false);
                                                                                                                }
                                                                                                            );
                                                                                                            formikProps.setFieldValue(`quiz.${index}.answerOptions.${index2}.isCorrectAnswer`, true);
                                                                                                        }}
                                                                                                    >
                                                                                                        {formikProps.values.quiz[index].answerOptions[index2].isCorrectAnswer
                                                                                                            ? <CIcon  style= {{color:'blue'}} icon={cilScrubber} />
                                                                                                            : <CIcon icon={cilCircle} />
                                                                                                        } Correct Answer
                                                                                                    </div>
                                                                                                
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </FieldArray>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                                                        {/* values.quiz[0]['answerOptions'][0].answerBody */}
                                                                        <CButton onClick={() => push(QuizStructure)}>Add More</CButton>
                                                                        &nbsp;&nbsp;&nbsp;<CButton onClick={() => remove(index)}>Cancel Accordion</CButton>
                                                                    </div>
                                                                </CAccordionBody>
                                                            </CAccordionItem>
                                                        </CAccordion>

                                                    </div>
                                                ))}
                                            <br />

                                            <CRow>
                                                <CCol xs={5}> </CCol>
                                                <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>
                                            </CRow>

                                        </div>
                                    )
                                    }
                                </FieldArray >
                            </div>

                        )}
                    </Formik>
                </div>
            </div>


        }
        {
            toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
        }
    </div>
    );
}

export default ManageQuiz;