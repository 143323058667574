import React, { useState } from 'react'
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CFormInput } from '@coreui/react'
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap'
import ConfigData from './../../../../config/constant.json'
import LoadingScreen from 'src/views/shared/Loading';
import userApi from 'src/utils/axios';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilLockUnlocked } from '@coreui/icons'
import moment from 'moment';
import NotificationModal from './NotificationModal';
import Toaster from 'src/views/shared/Toaster';
import ExcelFile from 'src/views/shared/ExcelFile';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import User from './User';
import { cilSearch } from '@coreui/icons'

const Batch = (props) => {
    const [loading, setloading] = React.useState(true)
    const [error, setError] = React.useState(false);
    const [batchData, setBatchData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [data, setData] = React.useState(false);
    const [toast, addToast] = React.useState(false)
    const [date, setDate] = useState(null);
    const [userView, setUserView] = useState(false);
    const [batchId, setBatchId] = useState();
    const [debouncedValue, setDebouncedValue] = useState('');

    let timeoutId; // Declare timeoutId variable outside of debounce function

    let history = useHistory();

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }


    // Fetch BatchDetails when debounced search term changes
    React.useEffect(() => {
        if (debouncedValue !== '') {
            getBatchDetails(debouncedValue);
        } else {
            getBatchDetails('');
        }

    }, [debouncedValue]);

    // Fetch payment data on initial load
    React.useEffect(() => {
        getBatchDetails('');
    }, [])

    const getBatchDetails = async (searchTerm) => {
        try {
            const searchValue = searchTerm ? searchTerm : '';
            let response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getBatchDetails?course_id=${props.location.state.course_id}&searchTerm=${searchValue}`)
            if (response.data.status == true) {
                setBatchData(response.data.batchData);
            }
            setloading(false)

        } catch (error) {
            setError(`${error.message} :(`)
            setloading(false)
            let toast = {
                "status": false,
                "body": error.message,
                "message": "Failed"
            }
            addToaster(toast)

        }
    };

    const handleNotification = (batch_id, batchName) => {
        setData({ batch_id, batchName, visible: true })
        setVisible(true);
    }

    const inCartNotification = (batch_id, batchName) => {
        setData({ batch_id, batchName, visible: false })
        setVisible(true);

    }

    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelDataBatchDetails?dateRange=${date}&&course_id=${props.location.state.course_id}`)
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Pagination logic
    const itemsPerPage = 10;
    const [search, setSearch] = React.useState('');
    const [currentItems, setCurrentItems] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [offSet, setOffset] = React.useState(0);

    React.useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(batchData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(batchData.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, batchData]);

    const handlePageClick = (event) => {
        setOffset(event.selected)
        const newOffset = (event.selected * itemsPerPage) % batchData.length;
        setItemOffset(newOffset);
    };

    const viewStudents = (batchId, courseId) => {
        setBatchId(batchId)
        setUserView(true);
    }

    return (
        <>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "white" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={5}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >Back</CButton> </CCol>
                                <CCol sm={5}><CCardTitle style={{ color: "black" }}>Batch Analytics</CCardTitle></CCol>
                                <CCol sm={2}>
                                    {/* <CFormInput
                                        placeholder="Search"
                                        autoComplete="Search"
                                        onChange={(e) => setSearch(e.target.value)}
                                    /> */}
                                </CCol>

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>

            </CRow>

            <br />


            {!loading && !userView &&
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ position: 'relative', width: '50%' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                            <CIcon icon={cilSearch} size="xl" />
                        </div>
                        <CFormInput
                            placeholder="Search..."
                            autoComplete="Search"
                            style={{ paddingLeft: '40px' }}
                            onChange={handleSearch}
                        />
                    </div>
                    <div>
                        <ExcelFile data={handleExcelFile} />
                    </div>
                </div>
            }
            {
                batchData.length == 0 && !userView &&
                <h1>No Data</h1>
            }
            <br />
            {
                !loading && !error && batchData?.length > 0 && !userView &&
                <div>

                    <h5 style={{ marginBottom: '1%' }}>Filtered Batches count: <span ><b>{batchData.length}</b></span></h5>
                    {/* <CCol md={6} style={{ paddingLeft: '30px' }}>

           <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
              <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                 <CIcon icon={cilSearch} size="xl" />
             </div>

              <CFormInput
                placeholder="Search..."
                  autoComplete="Search"
                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                 onChange={handleSearch}
              />
         </div>
      </CCol> */}
                    <Table striped bordered hover>
                        <thead>
                            <tr>

                                <th>Sr. No</th>
                                <th>Batch Name</th>
                                <th>Course Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                {/* <th>Visible In Market</th> */}
                                {/* <th>Lock Content</th> */}
                                {/* <th>Lock Content After</th> */}
                                <th>Batch Price</th>
                                {/* <th style={{ textAlign: 'center' }} >Notification</th> */}
                                <th>Total Students</th>

                            </tr>
                        </thead>
                        <tbody>
                            {

                                search == "" ?
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{offSet * 10 + (index + 1)}</td>
                                            <td>{item.batchName}</td>
                                            <td>{item.course?.courseName}</td>
                                            <td>{moment(item.startDate).format('DD MMM,YYYY')}</td>
                                            <td>{moment(item.endDate).format('DD MMM,YYYY')}</td>
                                            {/* <td style={{ textAlign: 'center' }}>{item.visibleInMarket}</td> */}
                                            {/* <td style={{ textAlign: 'center' }}>
                                                {
                                                    item.lockContent == true ?
                                                        <CIcon style={{ color: 'red' }} icon={cilLockLocked} size="xl" />
                                                        :
                                                        <CIcon style={{ color: 'green' }} icon={cilLockUnlocked} size="xl" />
                                                }
                                            </td> */}
                                            {/* <td style={{ textAlign: 'center' }}>{item.lockContentAfter}</td> */}

                                            <td style={{ textAlign: 'center' }}>
                                                <div> {`InHouse : ${item.inHousePayment === 'paid' ? `${item.inHouseFees} rupees` : 'Free'}`}</div>
                                                <div>   {`OutSider : ${item.outHousePayment === 'paid' ? `${item.outHouseFees} rupees` : 'Free'}`}</div>
                                            </td>
                                            <td style={{ textAlign: 'center', textDecoration: 'underline' }}>
                                                {/* <Link style={{ textDecoration: "none" }}

                                                    to={{
                                                        pathname: '/pages/analytics/users',
                                                        state: { batch_id: item._id }
                                                    }}> */}
                                                <CButton type="submit" style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => { viewStudents(item._id, item.courseId._id) }}>Students </CButton>
                                                {/* </Link> */}
                                            </td>

                                        </tr>
                                    )) : batchData.filter((val) => {
                                        if (search == "") {
                                            return val
                                        } else if (val.batchName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                    }).map((item, index) => (
                                        <tr key={index}>
                                            <td>{offSet * 10 + (index + 1)}</td>
                                            <td>{item.batchName}</td>
                                            <td>{moment(item.startDate).format('DD/MM/YYYY | HH:mmA')}</td>
                                            <td>{moment(item.endDate).format('DD/MM/YYYY | HH:mmA')}</td>
                                            <td style={{ textAlign: 'center' }}>{item.visibleInMarket}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                {
                                                    item.lockContent == true ?
                                                        <CIcon style={{ color: 'red' }} icon={cilLockLocked} size="xl" />
                                                        :
                                                        <CIcon style={{ color: 'green' }} icon={cilLockUnlocked} size="xl" />
                                                }
                                            </td>
                                            <td style={{ textAlign: 'center' }}>{item.lockContentAfter}</td>

                                            <td style={{ textAlign: 'center' }}>{item.batchFees}</td>
                                            <td style={{ textAlign: 'center' }}>{`${item.Revenue} Rs/-`}</td>

                                            <td>

                                                {/* <button type="submit" className="btn btn-outline-primary" onClick={() => handleNotification(item._id, item.batchName)}>Notify Students</button> */}
                                                {/* &nbsp;
                                                &nbsp; */}

                                                <CButton type="submit" style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} disabled={item.countCart === 0 ? true : false} onClick={() => inCartNotification(item._id, item.batchName)}>InCart Batches : {item.countCart}</CButton>


                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {/* <Link style={{ textDecoration: "none" }}

                                                    to={{
                                                        pathname: '/pages/analytics/users',
                                                        state: { batch_id: item._id }
                                                    }}> */}
                                                <CButton type="submit" style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }}>View Students</CButton>
                                                {/* </Link> */}
                                            </td>

                                        </tr>
                                    ))

                            }
                        </tbody>


                    </Table>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            }

            {
                loading && !userView &&
                <LoadingScreen></LoadingScreen>

            }

            {
                toast && !userView && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }

            {
                error && !userView &&
                <div>{error}</div>
            }


            {userView &&
                <User batchId={batchId} />
            }

        </>

    );
}

export default Batch;