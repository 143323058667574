import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CModalHeader, CModal, CModalFooter,
    CModalTitle, CModalBody, CButton, CFormLabel,
    CForm, CFormInput, CAlert, CSpinner
} from '@coreui/react'

const Resources = (props) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const handleAddResources = async ({ fileName }) => {

        try {
            setIsLoading(true)
            const data = new FormData();

            data.append("courseId", props.currentUser.course_id);
            data.append('videoId', props.currentUser._id)
            data.append('module_id',props.currentUser.module_id)
            data.append('fileName', fileName)

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/resources/addResources`, data, {
                // headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true
            })

            if (response.data.status == false) {
                setIsLoading(false)
                throw Error(response.data.message)
            }
            else {
                setIsLoading(false)
                let toast = {
                    "status": true,
                    "body": "Resource Added",
                    "message": "Success"
                }
                props.addToaster(toast)
                props.onClose(() => setAddResources(false))
            }
        } catch (error) {
            setIsLoading(false)
            console.log(error)
            let toast = {
                "status": false,
                "body": error.message,
                "message": "Failed"
            }
            props.addToaster(toast)
            props.onClose(() => setAddResources(false))
        }
    }

    const resourcesSchema = yup.object({
        fileName: yup.string()
            .required('File is Required'),
    })

    return (
        <>
            <CModal size="lg" alignment="center" visible={props.visible} onClose={props.onClose} backdrop='static'>
                <CModalHeader>
                    <CModalTitle>Add Resources</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <Formik
                        initialValues={{ fileName: '' }}
                        validationSchema={resourcesSchema}
                        onSubmit={(values, actions) => {
                            handleAddResources(values)
                        }}
                    >
                        {(formikProps) => (
                            <CForm>

                                <div className="mb-3" >
                                    <CFormLabel htmlFor="formFileMultiple">Select Resources</CFormLabel>
                                    <CFormInput
                                        onChange={(event) => formikProps.setFieldValue('fileName', event.target.files[0])}
                                        accept="application/pdf"
                                        type="file" id="formFileMultiple" />
                                    <p style={{ color: 'red' }}>{formikProps.touched.fileName && formikProps.errors.fileName}</p>
                                </div>

                                <CModalFooter>
                                    {!isLoading && <CButton color="primary" onClick={formikProps.handleSubmit}>Save Resources</CButton>}
                                    {isLoading && <CButton color='primary' disabled><CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Save Resources</CButton>}
                                </CModalFooter>

                            </CForm>
                        )}
                    </Formik>
                </CModalBody>
            </CModal>
        </>
    );
}

export default Resources;