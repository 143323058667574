import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCardBody, CCol, CRow, CModalHeader, CModal, CFormTextarea, CInputGroup,
    CModalTitle, CModalBody, CFormLabel, CForm, CFormInput, CFormSelect
} from '@coreui/react'
import CropEasy from '../../crop/CropEasy';


const AddCourse = ({ visible, onClose, addToaster, addCourseCallBack, instituteId }) => {
    const [openCrop, setOpenCrop] = useState(false);
    const [image, setImage] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null)
    const [originalName, setOriginalName] = useState(null)
    const [imageError, setImageError] = useState(false)

    const addCourseSchema = yup.object({

        courseName: yup.string()
            .required('Course-Name is required').min(3),

        // price: yup.number()
        //     .required('Price is required'),

        description: yup.string()
            .required('Description is required'),

        qualificationEligibility: yup.string()
            .required('Qualification Eligibility is required'),

    });

    const handleAddCourse = async (values) => {
        try {
            if (updatedImage == null) {
                setImageError(true);
                return;
            }
            console.log('values', values);
            let formData = new FormData();
            formData.append("fileName", updatedImage);
            formData.append("courseName", values.courseName);
            formData.append("totalVideos", values.totalVideos);
            // formData.append("price", values.price);
            formData.append("qualificationEligibility", values.qualificationEligibility);
            formData.append("description", values.description);
            formData.append("published", false);  // set dafult false formData.append("published", values.published);    
            formData.append("offeredBy", instituteId);
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/course/addcourse`, formData)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            let toast = {
                "status": true,
                "body": "Course Added",
                "message": "Success"
            }
            addToaster(toast)
            addCourseCallBack(response.data.data)
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    const handleChange = (e) => {
        const file = e.target.files[0];
        setOriginalName(file.name)
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.addEventListener("load", () => {
                setImage(reader.result);
            });
            setOpenCrop(true);
        }
    };

    const cropImageFile = (updatedimg) => {
        setUpdatedImage(updatedimg)
        setImageError(false)
    }

    return (
        <>
            <CModal scrollable visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>ADD COURSE</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={{ courseName: '', qualificationEligibility: '', price: '', description: '', offeredBy: '' }}
                                validationSchema={addCourseSchema}
                                onSubmit={(values, actions) => {
                                    actions.resetForm();
                                    handleAddCourse(values);
                                }}
                            >
                                {(formikProps) => (
                                    <CForm >
                                        {!openCrop ? (<div className="mb-3">
                                            {/* <CCol md={6}> */}
                                            <CFormLabel htmlFor="formFile">Select Image</CFormLabel>
                                            <CFormInput style={{ display: `${updatedImage != null ? 'none' : ''}` }}
                                                onChange={(event) => handleChange(event)}
                                                accept="image/*"
                                                type="file" id="formFile" />
                                            {/* </CCol> */}
                                            {updatedImage && <div><img src={image} style={{ width: 100, height: 90, marginRight: '5%' }} />{" " + originalName + " "}<button onClick={() => { setUpdatedImage(null) }} className="btn btn-outline-primary">Clear</button></div>}
                                            {imageError && <div style={{ color: 'red' }}>Please select image</div>}
                                        </div>) : (<CropEasy  {...{ setOpenCrop, cropImageFile, image, setImage, originalName }} />)}

                                        <CCol md={12}>
                                            <label className='text-sm'>Course Name</label>
                                            <CFormInput
                                                placeholder="Course Name"
                                                autoComplete="courseName"
                                                onChange={formikProps.handleChange('courseName')}
                                                value={formikProps.values.courseName}
                                                onBlur={formikProps.handleBlur('courseName')}
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.courseName && formikProps.errors.courseName}</p>
                                        </CCol>

                                        <CCol md={12}>
                                            <label className='text-sm'>Qualification Eligibility:</label>
                                            <CFormSelect aria-label="Default select example"
                                                onChange={formikProps.handleChange('qualificationEligibility')}
                                                value={formikProps.values.qualificationEligibility}>
                                                <option value='' disabled="true">Select Qualification</option>
                                                <option value="Anyone">Anyone</option>
                                                <option value="10th and above">10th and above</option>
                                                <option value="12th and above">12th and above</option>
                                                <option value="Graduation and above">Graduation and above</option>
                                            </CFormSelect>
                                            <p style={{ color: 'red' }}>{formikProps.touched.qualificationEligibility && formikProps.errors.qualificationEligibility}</p>
                                        </CCol>

                                        {/* <CCol md={12}  className='mt-2'>
                                            <label className='test-sm'>Price</label>

                                            <CFormInput
                                                placeholder="Price"
                                                autoComplete="price"
                                                onChange={formikProps.handleChange('price')}
                                                value={formikProps.values.price}
                                                onBlur={formikProps.handleBlur('price')}
                                            />

                                            <p style={{ color: 'red' }}>{formikProps.touched.price && formikProps.errors.price}</p>

                                        </CCol> */}
                                        <CCol md={12}>
                                            <label className='text-sm'>Course Description</label>

                                            <CFormTextarea
                                                id="description"
                                                rows="3"
                                                placeholder='Description'
                                                text="Must be 8-20 words long."
                                                onChange={formikProps.handleChange('description')}
                                                value={formikProps.values.description}
                                                onBlur={formikProps.handleBlur('description')}
                                            ></CFormTextarea>
                                            <p style={{ color: 'red' }}>{formikProps.touched.description && formikProps.errors.description}</p>

                                        </CCol>



                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>

                                            <CCol xs={5}> </CCol>

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default AddCourse;