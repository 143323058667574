import React from 'react'
import { CCard, CRow, CFormInput, CFormSelect, CCardBody, CSpinner, CButton, CCardImage, CCardTitle, CCardText, CCardHeader, CCol, } from '@coreui/react'
import ConfigData from '../../../../config/constant.json';
import userApi from 'src/utils/axios';;
import EditUser from './EditUser';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import { Link } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import DeleteDailog from '../../Master/DeleteDailog';
import ChangePassword from './ChangePassword';
import CreateUser from './CreateUser';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import ExcelFile from 'src/views/shared/ExcelFile';

export default function Users(props) {

    const [loader, setloader] = React.useState(true);
    const [users, setusers] = React.useState([]);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(false);
    const [user_id, setUser_id] = React.useState(null);
    const [userData, setUserData] = React.useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [openIndex, setOpenIndex] = React.useState(-1);
    const itemsPerPage = 10;
    const [offset, setOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [role, setRole] = React.useState(true);
    const [debouncedValue, setDebouncedValue] = React.useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const [filterBy, setFilterBy] = React.useState('');

    const handleClick = (index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenIndex(index); // set current menu index to open
    };

    const handleClose = () => {
        setAnchorEl(null);
        getInitialData('');
    };

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    React.useEffect(() => {
        if (debouncedValue !== '') {
            getInitialData(debouncedValue);
        } else {
            if (!openEditModal && !openDeleteModal && !changePassword && !props.openCreateUser) {
                getInitialData('');
            }
        }
    }, [props.call, debouncedValue, offset, openEditModal, openDeleteModal, changePassword, props.openCreateUser, filterBy]);

    const getInitialData = async (searchTerm) => {
        try {
            const searchValue = searchTerm ? searchTerm : '';
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/user/getUsersByRole?offset=${offset}&searchTerm=${searchValue}&filterBy=${filterBy}`);
            if (response.data.status == true) {
                setloader(false);
                setusers(response.data.data.user);

                setPageCount(Math.ceil(response.data?.data?.userCount / itemsPerPage))
            }
        } catch (error) {
            setloader(false);
            props.addToaster({
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            })
        }
    }

    //Handle excel file 
    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/user/getExcelUsersByRole?filterBy=${filterBy}`);
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.error(e.message)
        }
    }

    const handleDeleteUser = async () => {
        try {
            setloader(true);
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/user/deleteUser`, { user_id });
            if (response.data.status == true) {
                setloader(false);
                props.addToaster({
                    "status": true,
                    "body": "User Deleted Successfully",
                    "message": "Success"
                });
                setOpenDeleteModal(false);
                setusers(users.filter(user => user._id != user_id))
            }
        } catch (error) {
            setloader(false);
            props.addToaster({
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            })
        }
    }

    let editUserCallBack = (editUser) => {
        let index = users.findIndex((userId) => userId._id === editUser._id);
        users[index] = editUser;
        setusers([...users]);
        setOpenEditModal(false);
    }

    const AddUserCallBack = (userData) => {
        setusers([...users, userData])
    }
    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Handle filter by users type change
    const filterByRole = (e) => {
        setFilterBy(e.target.value);
    }

    return (
        <div className="row" style={{ marginTop: "2%" }}>
            <div className="col-md-12">
                {
                    <CRow>
                        <CCol md={6} style={{ paddingLeft: '10px' }}>

                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                    <CIcon icon={cilSearch} size="xl" />
                                </div>

                                <CFormInput
                                    placeholder="Search..."
                                    autoComplete="Search"
                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                    onChange={handleSearch}
                                />
                            </div>
                        </CCol>
                        <CCol md={2}>
                            <div>
                                <ExcelFile data={handleExcelFile} />
                            </div>
                        </CCol>
                        <CCol md={4} style={{ paddingRight: '50px' }}>
                            <CFormSelect aria-label="Default select example" onChange={filterByRole}>
                                <option disabled selected="true">Filter By</option>
                                <option value="">All</option>
                                <option value="admin">Admin</option>
                                <option value="VIEWER">Viewer</option>
                                <option value="TEACHER">Teacher</option>
                            </CFormSelect>
                        </CCol>
                    </CRow >
                }
                {
                    loader ? <CSpinner component="span" color='green' aria- hidden="true" /> :
                        <div>
                            <Table bordered hover id='user-table'>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>User Name</th>
                                        <th>Email</th>
                                        <th>Mobile No</th>
                                        <th>Role</th>
                                        {
                                            role &&
                                            <th>Action</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 &&
                                        users.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{offset * 10 + (index + 1)}</td>
                                                <td>{(item.firstName) ? ((item.firstName ? item.firstName : ' ') + ' ' + (item.lastName ? item.lastName : ' ')) : (item.fullName) || (item.name)}</td>
                                                <td>{item?.email}</td>
                                                <td>{item.number ? item.number : item.mobile}</td>
                                                <td>{item?.roleType}</td>
                                                {
                                                    role &&
                                                    <td> <div style={{ display: 'flex', gap: '10px' }}>
                                                        <button type="submit" className="btn" style={{ backgroundColor: 'rgb(214, 178, 49)' }} onClick={() => {
                                                            setUserData(item);
                                                            setOpenEditModal(true);
                                                            handleClose();
                                                        }}>Edit User</button>
                                                    </div>
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>

                            {/* Pagination Component */}
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(event) => { handlePageClick(event) }}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>

                }
                {
                    openEditModal &&
                    <EditUser
                        openEditModal={openEditModal}
                        onClose={() => setOpenEditModal(false)}
                        userData={userData}
                        addToaster={props.addToaster}
                        CallBack={getInitialData}
                        allRoles={props.allRoles}
                        editUserCallBack={editUserCallBack}
                    />
                }
                {
                    (openDeleteModal && user_id) &&
                    <DeleteDailog
                        visible={openDeleteModal}
                        onClose={() => setOpenDeleteModal(false)}
                        delete={handleDeleteUser}
                        addToaster={props.addToaster}
                    />
                }
                {
                    changePassword &&
                    <ChangePassword
                        visible={changePassword}
                        onClose={() => setChangePassword(false)}
                        user_id={user_id}
                        addToaster={props.addToaster}
                    />
                }
                {/* {
                    props.openCreateUser &&
                    // <CreateUser
                    //     openCreateUser={props.openCreateUser}
                    //     onClose={() => props.setOPenCreateUser(false)}
                    //     addToaster={props.addToaster}
                    //     allRoles={props.allRoles}
                    //     AddUserCallBack={AddUserCallBack}
                    // >
                    // </CreateUser>
                } */}
            </div>
        </div>


    )
}
