import React from 'react'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardImage,
    CForm, CFormInput, CSpinner,
} from '@coreui/react'
import userApi from 'src/utils/axios';;
import { Formik, Field } from "formik";
import * as yup from 'yup';
import ConfigData from '../../../../config/constant.json'

export default function EditUser(props) {

    const [loader, setloader] = React.useState(false);

    const userSchema = yup.object().shape({
        name: yup.string()
            .required('Name is required'),
        email: yup.string()
            .email('Invalid email')
            .required('Email is required'),
        number: yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
            .required('Number is required'),
        roleType: yup.string()
            .required('Role is required'),
    });
    const initialValues = {
        name: props.userData.name,
        email: props.userData.email,
        number: props.userData.number,
        roleType: props.userData.roleType,
        userId: props.userData.userId
    }

    const handleEditUser = async (values) => {
        try {
            setloader(true);
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/user/editUser`, values)
            if (response.data.status == true) {
                setloader(false);
                props.addToaster({
                    "status": true,
                    "body": "Updated Successfully",
                    "message": "Success"
                });
                props.onClose();
                props.editUserCallBack(response.data.data);
            } else {
                setloader(false);
                props.addToaster({
                    "status": false,
                    "body": response.data.message,
                    "message": "Failed"
                });
            }

        } catch (e) {
            console.log(e.message);
            setloader(false);
            props.addToaster({
                "status": true,
                "body": `${e.message}`,
                "message": "Failed"
            });
            props.onClose();
        }
    }

    return (

        <CModal size="lg" visible={props.openEditModal} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Edit User</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <>
                    <CCardBody>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={userSchema}
                            onSubmit={(values, actions) => {
                                handleEditUser(values);
                                actions.resetForm();
                            }}
                        >
                            {(formikProps) => (
                                <CForm>

                                    <CInputGroup className="mb-3">
                                        <legend>Full Name</legend>
                                        <CFormInput
                                            placeholder="Full Name"
                                            autoComplete="name"
                                            onChange={formikProps.handleChange('name')}
                                            value={formikProps.values.name}
                                            onBlur={formikProps.handleBlur('name')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.name && formikProps.errors.name}</p>

                                    <CInputGroup className="mb-3">
                                        <legend>Email</legend>
                                        <CFormInput readOnly={true}
                                            placeholder="eg. user@gmail.com"
                                            autoComplete="email"
                                            type='email'
                                            onChange={formikProps.handleChange('email')}
                                            value={formikProps.values.email}
                                            onBlur={formikProps.handleBlur('email')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.email && formikProps.errors.email}</p>

                                    <CInputGroup className="mb-3">
                                        <legend>Number</legend>
                                        <CFormInput
                                            placeholder="eg. 8907349952"
                                            autoComplete="number"
                                            type='number'
                                            onChange={formikProps.handleChange('number')}
                                            value={formikProps.values.number}
                                            onBlur={formikProps.handleBlur('number')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.number && formikProps.errors.number}</p>

                                    {
                                        props.allRoles.length > 0 &&
                                        <div>
                                            <CInputGroup className="mb-3">
                                                <legend>Role</legend>
                                                <CFormSelect
                                                    onChange={formikProps.handleChange('roleType')}
                                                    value={formikProps.values.roleType}
                                                    onBlur={formikProps.handleBlur('roleType')}
                                                    disabled // Adding the disabled attribute makes the select readOnly

                                                >
                                                    <option value="">Select Role</option>
                                                    {
                                                        props.allRoles.map((role, index) => {
                                                            return <option key={index} value={role.roleName}>{role.roleName}</option>
                                                        })
                                                    }
                                                </CFormSelect>
                                            </CInputGroup>

                                            <p style={{ color: 'red' }}>{formikProps.touched.roleType && formikProps.errors.roleType}</p>

                                        </div>
                                    }

                                    <CRow >
                                        <CCol xs={5}> </CCol>
                                        <CCol xs={2}>
                                            {!loader &&
                                                <CCol xs={7}>
                                                    <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button>
                                                </CCol>
                                            }
                                            {loader &&
                                                <CCol xs={7}>
                                                    <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary">
                                                        <CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit</button>
                                                </CCol>
                                            }


                                        </CCol>
                                    </CRow>
                                </CForm>
                            )}
                        </Formik>

                    </CCardBody>
                </>
            </CModalBody>
        </CModal>
    )
}
