import React from 'react'
import userApi from 'src/utils/axios'
import ConfigData from '../../../../config/constant.json'
import {
    CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CAccordion, CAccordionItem,
    CAccordionHeader, CAccordionBody, CTable, CTableHead, CTableBody, CTableHeaderCell, CTableRow, CTableDataCell,
    CNav, CNavItem, CNavLink, CTabContent, CTabPane
} from '@coreui/react'
import { ProgressBar } from 'react-bootstrap'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { cilCheck } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap'


const UserAnalytics = (props) => {
    let user_id = props.location.state.user_id
    let batch_id = props.location.state.batch_id
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [analyticsData, setAnalyticsData] = React.useState([])
    const [activeKey, setActiveKey] = React.useState(1);
    const [quizData, setQuizData] = React.useState([{ moduleName: 'Introducation-II', MaxScore: 4, LowScore: 1, OutOf: 5, numberOfAttemp: 6 }]);

    let history = useHistory();

    // for user analytics data
    React.useEffect(async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/singleUserAnalytics`, { user_id, batch_id })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setAnalyticsData(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])


    // for quiz analytics data
    React.useEffect(async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/getQuizAnalytics`, { user_id, batch_id })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setQuizData(response.data.data)
            console.log("response.data.data==>", response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Convert milliseconds to minutes
    const millisToMinutesAndSeconds = (millis) => {
        var d = new Date(1000 * Math.round(millis / 1000)); // round to nearest second
        function pad(i) { return ('0' + i).slice(-2); }
        var str = d.getUTCHours() + ':' + pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds());
        return str;
    }

    // get module percentage
    const modulePercentage = (array) => {
        let videoDuration = 0;
        let watchedDuration = 0;
        let moduleWatched = 0;
        for (let data of array) {
            videoDuration = videoDuration + data.duration;
            watchedDuration = watchedDuration + data.durationWatched
        }
        moduleWatched = watchedDuration * 100 / videoDuration
        return moduleWatched.toFixed();
    }


    return (
        <>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "#3c4b64" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={5}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "white" }} >Back</CButton> </CCol>
                                <CCol sm={5}><CCardTitle style={{ color: "white" }}>User Batch Report 😀 </CCardTitle></CCol>
                                <CCol sm={2}></CCol>

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <br />
            <>
                <CNav variant="pills" layout="fill" >
                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 1 ? '700' : '500',
                                fontSize: activeKey === 1 ? '20px' : '18px',
                            }}
                            active={activeKey === 1}
                            onClick={() => setActiveKey(1)}
                        >
                            User Analytics
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 2 ? '700' : '500',
                                fontSize: activeKey === 2 ? '20px' : '18px',
                            }}
                            active={activeKey === 2}
                            onClick={() => setActiveKey(2)}
                        >
                            Quiz Analytics
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>

                    <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 1}>
                        <br />
                        {
                            loading && <LoadingScreen></LoadingScreen>
                        }

                        {
                            !loading && !error &&

                            analyticsData.map((option, index) => (
                                <CAccordion flush key={index} >
                                    <CAccordionItem itemKey={1} >
                                        <CAccordionHeader style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                            <div>
                                                <span style={{ fontWeight: 'bold' }}>Module Name :&nbsp;&nbsp; </span> {option.module.module_name.substring(0, 50)}{option.module.module_name.length > 50 ? "..." : ""}
                                            </div>
                                            <div style={{ paddingLeft: 20, width: 200 }}>
                                                <ProgressBar now={`${modulePercentage(option.videos)}`} label={`${modulePercentage(option.videos)}%`} />
                                            </div>
                                        </CAccordionHeader>
                                        <div className='container'>
                                            {option.videos &&
                                                <CAccordionBody key={index}>
                                                    <div style={{ overflow: "auto" }}>
                                                        <CTable striped hover >
                                                            <CTableHead>
                                                                <CTableRow>
                                                                    <CTableHeaderCell scope="col">Sr. No</CTableHeaderCell>
                                                                    <CTableHeaderCell className="text-center" >Title</CTableHeaderCell>
                                                                    <CTableHeaderCell className="text-center" >Video Duration</CTableHeaderCell>
                                                                    <CTableHeaderCell className="text-center" >Watched Duration</CTableHeaderCell>
                                                                    <CTableHeaderCell className="text-center" >Is Completed</CTableHeaderCell>

                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                {
                                                                    option.videos.length > 0 && option.videos.map((item, index) => (
                                                                        <CTableRow key={item._id}>
                                                                            <CTableDataCell  >{item.sr_no}</CTableDataCell>
                                                                            <CTableDataCell className="text-center">{item.title}</CTableDataCell>
                                                                            <CTableDataCell className="text-center">{item.durationMinutes}</CTableDataCell>
                                                                            <CTableDataCell className="text-center">{millisToMinutesAndSeconds(item.durationWatched)}</CTableDataCell>
                                                                            <CTableDataCell className="text-center">
                                                                                {
                                                                                    item.isFinished ?
                                                                                        <CIcon icon={cilCheck} size="lg" style={{ color: 'green' }} /> :
                                                                                        <progress value={item.durationWatched * 100 / item.duration} max="100"></progress>
                                                                                }
                                                                            </CTableDataCell>
                                                                        </CTableRow>
                                                                    ))
                                                                }
                                                            </CTableBody>
                                                        </CTable>
                                                    </div>

                                                </CAccordionBody>}

                                            {option.videos == 0 &&
                                                <CAccordionBody > No Videos</CAccordionBody>
                                            }
                                        </div>
                                    </CAccordionItem>

                                </CAccordion>

                            ))
                        }



                        {
                            !loading && error && <h1>{error}</h1>
                        }

                        {
                            toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                        }

                    </CTabPane>
                    <CTabPane role="tabpanel" aria-labelledby="resource-tab" visible={activeKey === 2}>
                        <br />
                        {
                            loading && <LoadingScreen></LoadingScreen>
                        }

                        {
                            !loading && !error &&
                            <div style={{ margin: 30 }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Module Name</th>
                                            <th>Max Score</th>
                                            <th>Low Score</th>
                                            <th className="text-center">Out Of</th>
                                            <th>Numbers of Attempt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Search bar logic */}
                                        {quizData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.moduleName}</td>
                                                <td>{item.MaxScore}</td>
                                                <td>{item.LowScore}</td>
                                                <td>{item.OutOf}</td>
                                                <td>{item.numberOfAttemp}</td>

                                            </tr>
                                        ))

                                        }
                                    </tbody>
                                </Table>
                            </div>
                        }
                        {
                            !loading && error && <h1>{error}</h1>
                        }

                        {
                            toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                        }
                    </CTabPane>

                </CTabContent>
            </>


        </>
    );
}

export default UserAnalytics;