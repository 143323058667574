import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from '../../../../config/constant.json'
import { CModalHeader, CModal, CModalTitle, CModalBody, CSpinner, CButton } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';






const UserBatchReport = (props) => {

    let user_id = props.selectedUserId
    let batch_id = props.batchId

    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [analyticsData, setAnalyticsData] = React.useState([])
    const [activeKey, setActiveKey] = React.useState(1);
    const [quizData, setQuizData] = React.useState([{ moduleName: 'Introducation-II', MaxScore: 4, LowScore: 1, OutOf: 5, numberOfAttemp: 6 }]);
    const [userData, setUserData] = React.useState("")

    // for user analytics data
    React.useEffect(async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/singleUserAnalytics`, { user_id, batch_id })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setAnalyticsData(response.data.data)
            setUserData(response.data.userdata)
            console.log("sdnkl", response.data.userdata)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])


    // for quiz analytics data
    React.useEffect(async () => {
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/getQuizAnalytics`, { user_id, batch_id })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setQuizData(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Convert milliseconds to minutes
    const millisToMinutesAndSeconds = (millis) => {
        var d = new Date(1000 * Math.round(millis / 1000)); // round to nearest second
        function pad(i) { return ('0' + i).slice(-2); }
        var str = d.getUTCHours() + ':' + pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds());
        return str;
    }

    // get module percentage
    const modulePercentage = (array) => {
        let videoDuration = 0;
        let watchedDuration = 0;
        let moduleWatched = 0;
        for (let data of array) {
            videoDuration = videoDuration + data.duration;
            watchedDuration = watchedDuration + data.durationWatched
        }
        moduleWatched = watchedDuration * 100 / videoDuration
        return moduleWatched.toFixed();
    }
    async function download_file(fileURL, fileName) {
        let test = fileURL.split('/public')
        let real = ConfigData.SERVER_URL + test[1]

        fetch(real).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = fileName;
                alink.click();
            })
        })


    }

    const getPDF = async () => {
        try {
            setLoading(true)
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/getUserReportPDFbatchwise`, { userdata: user_id, batchid: props.batchId })
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            await download_file(response.data.pdf, `${user_id}.pdf`)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    return (<>
        <CModal scrollable size="lg" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static' >
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>User Profile</CModalTitle>
            </CModalHeader>
            <CModalBody>

                {analyticsData.length > 0 ?
                    <div >
                        {/* Mentor Profile  */}
                        <div >
                            <div id="batchReportToPrint" >
                                <p style={{ fontSize: 20, textAlign: 'center', color: 'blue', border: '2px solid blue' }}><b>Profile</b></p>
                                <div  >
                                    {userData.length > 0 && <table style={{ ...styles.table, width: '100%' }} >

                                        <tbody>

                                            <tr >
                                                <th style={styles.table} >Profile Image</th>
                                                <td style={styles.table} ><img style={{ width: 150, height: 100, textAlign: 'center' }} src={`${ConfigData.SERVER_URL}/images/PIcon.png `} /></td>
                                            </tr>

                                            <tr  >
                                                <th style={styles.table}>Name</th>
                                                <td style={styles.table}>{userData[0].name}</td>
                                            </tr>
                                            <tr >
                                                <th style={styles.table}>email</th>
                                                <td style={styles.table}>{userData[0].email}</td>

                                            </tr>
                                            <tr >

                                                <th style={styles.table} >Phone</th>
                                                <td style={styles.table}>{userData[0].number}</td>

                                            </tr>
                                        </tbody>
                                    </table>}
                                </div>


                                <br />
                                {/* EducationDetails */}

                                <p>{props.batchName}</p>
                                <div  >
                                    <div>Module Report : - </div>

                                    {analyticsData && analyticsData.map((item1, index1) => (

                                        <div key={index1} style={{ border: '1px solid black', padding: "1%", margin: "1%" }}>


                                            <div>{index1 + 1}. Module name :- {item1.module.module_name} :{modulePercentage(item1.videos)} %  </div>


                                            <div style={{ margin: '1%' }}>
                                                <div>Videos Report : - </div>
                                                <table style={{ ...styles.table, width: '100%' }} >
                                                    <thead >
                                                        <tr >
                                                            <th style={styles.table}>Sr. No</th>
                                                            <th style={styles.table}>Title</th>
                                                            <th style={styles.table}>Video Duration</th>
                                                            <th style={styles.table}>Watched Duration</th>
                                                            <th style={styles.table}>Is Completed</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* Search bar logic */}
                                                        {item1.videos.map((item3, index3) => (
                                                            <tr key={index3}>
                                                                <td style={styles.table}>{index3 + 1}</td>
                                                                <td style={styles.table}>{item3.title}</td>
                                                                <td style={styles.table}>{item3.durationMinutes}</td>
                                                                <td style={styles.table}>{millisToMinutesAndSeconds(item3.durationWatched)}</td>
                                                                <td style={styles.table}>{(item3.durationWatched * 100 / item3.duration).toFixed(2)} % </td>

                                                            </tr>
                                                        ))

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>))}
                                    <div style={{ margin: '1%' }} >
                                        <div>Quiz Report : -</div>
                                        {quizData.length > 0 ? <table style={{ ...styles.table, width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={styles.table}>Sr. No</th>
                                                    <th style={styles.table}>Module Name</th>
                                                    <th style={styles.table}>Max Score</th>
                                                    <th style={styles.table}>Low Score</th>
                                                    <th style={styles.table}>Out Of</th>
                                                    <th style={styles.table}>Numbers of Attempt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Search bar logic */}
                                                {quizData.map((item2, index2) => (
                                                    <tr key={index2}>
                                                        <td style={styles.table}>{index2 + 1}</td>
                                                        <td style={styles.table}>{item2.moduleName}</td>
                                                        <td style={styles.table}>{item2.MaxScore}</td>
                                                        <td style={styles.table}>{item2.LowScore}</td>
                                                        <td style={styles.table}>{item2.OutOf}</td>
                                                        <td style={styles.table}>{item2.numberOfAttemp}</td>

                                                    </tr>
                                                ))

                                                }
                                            </tbody>
                                        </table> : <div style={{ fontWeight: 'bold' }}> No Data</div>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CButton
                                id="pdfButton"
                                color="primary"
                                className="px-4"
                                type="submit"
                                disabled={loading}
                                onClick={() => getPDF()}
                            >
                                {loading ? <CSpinner color="light" size="sm" /> : "Download pdf"}
                            </CButton>
                        </div>
                        <br />

                    </div>
                    :
                    <h1>No Data</h1>
                }

            </CModalBody>
        </CModal>
    </>);
}
const styles = {
    table: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'center'
    }
}


export default UserBatchReport;