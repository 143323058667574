import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardGroup, CCardImage,
    CForm, CFormInput,
} from '@coreui/react'


const AddInstitute = ({ visible, onClose, addToast, addinstituteCallback }) => {
    const instituteSchema = yup.object({
        instituteName: yup.string()
            .required('Institute Name is Required').min(5),
        insituteShortName: yup.string()
            .required('Institute Short Name is Required'),
        aboutInstitute: yup.string()
            .required('About Institute is Required').min(10),
    });

    const handleAddInstitute = async (values) => {

        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/institute/addinstitute`, { values })
            if (response.data.status == false) return addToaster({ 'body': response.data.message, 'message': 'danger', 'status': false })
            addinstituteCallback(response.data.data)
            addToast({ "status": true, "body": "Institute Added", "message": "Success" })
            setTimeout(() => {
                addToast(false);
            }, 2000)
           
        } catch (e) {
            addToast({ "status": false, "body": "Adding Institute Failed", "message": "Failure" })
            setTimeout(() => {
                addToast(false);
            }, 2000)
        }
    }

    return (
        <>
            <CModal visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>Add Institute</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={{ instituteName: '', insituteShortName: '', aboutInstitute: '' }}
                                validationSchema={instituteSchema}
                                onSubmit={(values, actions) => {
                                    handleAddInstitute(values);
                                    actions.resetForm();
                                }}
                            >
                                {(formikProps) => (
                                    <CForm>
                                        <CInputGroup className="mb-3">
                                            <legend>Institute Name</legend>
                                            <CFormInput
                                                placeholder="eg. Indian Institute Of Management–Ahmedabad"
                                                autoComplete="instituteName"
                                                onChange={formikProps.handleChange('instituteName')}
                                                value={formikProps.values.instituteName}
                                                onBlur={formikProps.handleBlur('instituteName')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.instituteName && formikProps.errors.instituteName}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>Institute Short Name</legend>
                                            <CFormInput
                                                placeholder="eg. IIM-A"
                                                autoComplete="insituteShortName"
                                                onChange={formikProps.handleChange('insituteShortName')}
                                                value={formikProps.values.insituteShortName}
                                                onBlur={formikProps.handleBlur('insituteShortName')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.insituteShortName && formikProps.errors.insituteShortName}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>About Institute</legend>
                                            <CFormTextarea
                                                id="aboutInstitute"
                                                rows="3"
                                                placeholder='About Institute'
                                                text="Must be 8-20 words long."
                                                onChange={formikProps.handleChange('aboutInstitute')}
                                                value={formikProps.values.aboutInstitute}
                                                onBlur={formikProps.handleBlur('aboutInstitute')}
                                            ></CFormTextarea>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.aboutInstitute && formikProps.errors.aboutInstitute}</p>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>

                                            <CCol xs={5}> </CCol>

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default AddInstitute;