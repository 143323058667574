import React from 'react'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardGroup, CCardImage,
    CForm, CFormInput, CSpinner,
} from '@coreui/react';
import userApi from 'src/utils/axios';;
import { Formik, Field } from "formik";
import * as yup from 'yup';
import ConfigData from '../../../config/constant.json'
import LoadingScreen from 'src/views/shared/Loading';
import navigation from '../../pages/_nav';

export default function EditRole(props) {

    const [loader, setloader] = React.useState(false);

    console.log(props);

    const roleSchema = yup.object().shape({
        role_name: yup.string(),
        // .required('Role Name is required'),
        roleAccess: yup.array()
            // .required('Role Access is Required')
            .min(1, "Role Access should be atleast 1"),
    });


    const handleEditRole = async (values) => {
        try {
            setloader(true);
            // console.log("Values", values);

            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/roleAccess/updateRoleAccess?role_id=${props.role._id}`, { values });
            if (response.data.status == true) {
                setloader(false);
                props.addToaster({
                    "status": true,
                    "body": "Role Updated Successfully",
                    "message": "Success"
                })
                console.log(response.data.updatedData);
                props.EditCallBack(response.data.updatedData);
                props.onClose();
            }
        } catch (error) {
            setloader(false);
            props.addToaster({
                "status": true,
                "body": `${error.message}`,
                "message": "Failed"
            })
            props.onClose();
        }
    }

    return (
        <CModal size="lg" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Create Role</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <>
                    <CCardBody>

                        <Formik
                            initialValues={{ roleName: props.role.roleName, roleAccess: props.role.roleAccess }}
                            validationSchema={roleSchema}
                            onSubmit={(values, actions) => {
                                handleEditRole(values);
                                actions.resetForm();
                            }}
                        >
                            {(formikProps) => (
                                <CForm>
                                    <CInputGroup className="mb-3">
                                        <legend>Role Name</legend>
                                        <CFormInput
                                            placeholder="eg. Admin/ Student/ Teacher / etc"
                                            autoComplete="roleName"
                                            onChange={formikProps.handleChange('roleName')}
                                            value={formikProps.values.roleName}
                                            onBlur={formikProps.handleBlur('roleName')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.roleName && formikProps.errors.roleName}</p>

                                    <legend>Roles</legend>




                                    {
                                        navigation &&
                                        navigation.map((item, index) => (
                                            <CRow key={index} >
                                                <CCol >
                                                    {item.name}
                                                </CCol>
                                                <CCol>
                                                    <input
                                                        type="checkbox"
                                                        name="roleAccess"
                                                        value={item.dbname}
                                                        onChange={formikProps.handleChange('roleAccess')}
                                                        onBlur={formikProps.handleBlur('roleAccess')}
                                                        checked={formikProps.values.roleAccess.includes(item.dbname)}
                                                    />
                                                </CCol>
                                                <hr style={{ color: 'blue' }} />
                                            </CRow>
                                        ))
                                    }

                                    <p style={{ color: 'red' }}>{formikProps.touched.roleAccess && formikProps.errors.roleAccess}</p>

                                    <CRow >
                                        <CCol xs={5}> </CCol>
                                        <CCol xs={2}>
                                            {!loader &&
                                                <CCol xs={7}>
                                                    <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button>
                                                </CCol>
                                            }
                                            {loader &&
                                                <CCol xs={7}>
                                                    <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary">
                                                        <CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit</button>
                                                </CCol>
                                            }


                                        </CCol>
                                    </CRow>
                                </CForm>
                            )}
                        </Formik>



                    </CCardBody>
                </>
            </CModalBody>
        </CModal>

    )
}
