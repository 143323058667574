import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCardBody, CCol, CRow,
    CModalHeader, CModal, CFormTextarea, CInputGroup,
    CModalTitle, CModalBody, CSpinner,
    CForm, CFormInput,
} from '@coreui/react'
import LoadingScreen from 'src/views/shared/Loading';


const EditInstitute = (props) => {
    const [loader, setLoader] = React.useState(false)

    const instituteSchema = yup.object({
        instituteName: yup.string()
            .required('Institute Name is Required').min(5),
        insituteShortName: yup.string()
            .required('Institute Short Name is Required'),
        aboutInstitute: yup.string()
            .required('About Institute is Required').min(10),
    });

    let _id = props.institute._id

    const initialValues = {
        instituteName: props.institute.instituteName,
        insituteShortName: props.institute.insituteShortName,
        aboutInstitute: props.institute.aboutInstitute
    }

    const handleEditInstitute = async (values, submitProps) => {
        try {
            setLoader(true)
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/institute/editinstitute?_id=${props.institute._id}`, { values })
            setLoader(false);
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            let toast = {
                "status": true,
                "body": "Institute Edited",
                "message": "Success"
            }
            props.addToaster(toast);
            props.editinstituteCallback({ ...values, _id })
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            props.addToaster(toast)
        }

    }


    return (<>
        <CModal visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>
                <CModalTitle>Edit Institute</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <>
                    <CCardBody>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={instituteSchema}
                            onSubmit={handleEditInstitute}
                        >
                            {(formikProps) => (
                                <CForm>
                                    <CInputGroup className="mb-3">
                                        <legend>Institute Name</legend>
                                        <CFormInput
                                            placeholder="eg. Indian Institute Of Management–Ahmedabad"
                                            autoComplete="instituteName"
                                            onChange={formikProps.handleChange('instituteName')}
                                            value={formikProps.values.instituteName}
                                            onBlur={formikProps.handleBlur('instituteName')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.instituteName && formikProps.errors.instituteName}</p>

                                    <CInputGroup className="mb-3">
                                        <legend>Institute Short Name</legend>
                                        <CFormInput
                                            placeholder="eg. IIM-A"
                                            autoComplete="insituteShortName"
                                            onChange={formikProps.handleChange('insituteShortName')}
                                            value={formikProps.values.insituteShortName}
                                            onBlur={formikProps.handleBlur('insituteShortName')}
                                        />
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.insituteShortName && formikProps.errors.insituteShortName}</p>

                                    <CInputGroup className="mb-3">
                                        <legend>About Institute</legend>
                                        <CFormTextarea
                                            id="aboutInstitute"
                                            rows="3"
                                            placeholder='About Institute'
                                            text="Must be 8-20 words long."
                                            onChange={formikProps.handleChange('aboutInstitute')}
                                            value={formikProps.values.aboutInstitute}
                                            onBlur={formikProps.handleBlur('aboutInstitute')}
                                        ></CFormTextarea>
                                    </CInputGroup>
                                    <p style={{ color: 'red' }}>{formikProps.touched.aboutInstitute && formikProps.errors.aboutInstitute}</p>

                                    <CRow >
                                        <CCol xs={5}> </CCol>
                                        {!loader && <CCol xs={2}><button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>}
                                        {loader && <CCol xs={2}><button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary"><CSpinner component="span" size="sm" aria-hidden="true" />Submit</button> </CCol>}

                                        <CCol xs={5}> </CCol>

                                    </CRow>
                                </CForm>
                            )}
                        </Formik>
                    </CCardBody>
                </>
            </CModalBody>
        </CModal>

    </>);
}

export default EditInstitute;