import React, { useState, useEffect } from "react";
import { CCard, CCardBody, CCol, CCardTitle, CFormInput, CRow, CFormSelect } from '@coreui/react';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import ExcelFile from 'src/views/shared/ExcelFile';
import LoadingScreen from "src/views/shared/Loading";
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

const enrolledFreeCourses = () => {
    const [debouncedValue, setDebouncedValue] = useState('');
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(true);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterBy, setFilterBy] = useState('');
    const [error, setError] = useState(false);
    const itemsPerPage = 10;
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const [count, setCount] = useState(0);

    // Fetch enrolled data 
    useEffect(() => {
        if (debouncedValue !== '') {
            getEnrolledStudent(debouncedValue);
        } else {
            getEnrolledStudent('');
        }
    }, [offset, filterBy, debouncedValue])

    const getEnrolledStudent = async (searchTerm) => {
        try {
            setLoader(true);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/payment/getFreeCoursesEnrolledUser?searchTerm=${searchTerm}&offset=${offset}&filterBy=${filterBy}`);
            if (response.data?.data) {
                setUsers(response.data?.data?.enrolledData);
                setPageCount(Math.ceil(response.data?.data?.enrolledDataCount / itemsPerPage));
                setCount(response.data?.data?.enrolledDataCount)
            }
            setLoader(false); // Set loader to false after data fetching
        } catch (e) {
            console.error(e.message);
        }
    };

    //Handle excel file 
    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/payment/getExcelFreeCoursesEnrolledData?filterBy=${filterBy}`);
            if (response.data.status == true) {
                return response.data.data;
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    };

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    };

    // Handle filter by student type change
    const filterByStudent = (e) => {
        setFilterBy(e.target.value);
    };

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    return (
        <>
            {/* header section */}
            <div style={{ margin: 10 }}>
                <CRow>
                    <CCol sm={12}>
                        <CCard style={{ background: "white" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={8}> <CCardTitle style={{ color: "black" }}>Free Courses Enrolled Students</CCardTitle></CCol>
                                    <CCol sm={2}></CCol>
                                    <CCol sm={2}></CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>

            {/* search and filter section */}
            <CRow>
                <CCol md={6} style={{ paddingLeft: '30px' }}>

                    <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                            <CIcon icon={cilSearch} size="xl" />
                        </div>

                        <CFormInput
                            placeholder="Search..."
                            autoComplete="Search"
                            style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                            onChange={handleSearch}
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <div>
                        <ExcelFile data={handleExcelFile} />
                    </div>
                </CCol>
                <CCol md={4} style={{ paddingRight: '50px' }}>
                    <CFormSelect aria-label="Default select example" onChange={filterByStudent}>
                        <option disabled selected="true">Filter By</option>
                        <option value="">All</option>
                        <option value="InHouse">InHouse</option>
                        <option value="OutSider">OutSider</option>
                        <option value="TEACHER">Teacher</option>
                    </CFormSelect>
                </CCol>
            </CRow >

            {/* Payment Data Table and Pagination */}
            {
                loader ? <LoadingScreen /> :
                    <>
                        <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Users Count: <span ><b>{count}</b></span></h5>
                        <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                            <Table striped bordered hover id='user-table'>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>User Name</th>
                                        <th>Course</th>
                                        <th>Batch</th>
                                        <th>Student Type</th>
                                        <th>Status</th>
                                        <th>Enrolled Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 &&
                                        users.map((item, index) => (
                                            <tr key={item._id}>
                                                <td>{offset * 10 + (index + 1)}</td>
                                                <td>{item.user[0]?.fullName ? item.user[0]?.fullName : (item.user[0]?.firstName ? `${item.user[0]?.firstName} ${item.user[0]?.lastName}` : item.user[0]?.name)}</td>
                                                <td>{item.course[0]?.courseName}</td>
                                                <td>{item.batch[0]?.batchName}</td>
                                                <td>{item.user[0]?.roleType === 'TEACHER' ? 'TEACHER' : (item.user[0]?.registration ? 'OutSider' : 'InHouse')}</td>
                                                <td>{item.status}</td>
                                                <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>

                            {/* Pagination Component */}
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(event) => { handlePageClick(event) }}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={offset}
                            />
                        </div>
                    </>
            }
            {/* No Data Found Message */}
            {
                users.length === 0 && loader === false &&
                <h3 style={{ margin: '3%', textAlign: 'center' }}>No Data Found</h3>
            }
        </>
    );

};

export default enrolledFreeCourses;