import React, { useLayoutEffect } from 'react';
import { CButton } from "@coreui/react";
import useKeycloak from '../../utils/keycloak.init';
import { CSpinner } from '@coreui/react'
import { useHistory } from 'react-router-dom'

const Landing = () => {
    const keycloak = useKeycloak();
    const history = useHistory();


    return (
        <div >
            <div style={{ textAlign:"center" , margin: "20%" }}>
            <CSpinner  component="span" size='lg' color='black' aria-hidden="true" /> <h3 style={{color:"green"}}>Authenticating</h3>

            </div>

        </div>
    );
}

export default Landing;
