import React from 'react'
import userApi from 'src/utils/axios';
import { CCard, CCardBody, CCol, CRow, CCardTitle } from '@coreui/react'
import LoadingScreen from 'src/views/shared/Loading';
import Card from 'src/views/shared/Card';
import ConfigData from 'src/config/constant.json'
import Toaster from 'src/views/shared/Toaster';

const Analytics = () => {

    const [analyticsData, setAnalyticsData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [toast, addToast] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [role, setRole] = React.useState('');

    React.useEffect(async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getAnalytics`)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setAnalyticsData(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }

    }, [])

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        setRole(userRoles === 'admin' ? 'Admin' : (userRoles === 'TEACHER' ? 'Teacher' : (userRoles === 'VIEWER' ? 'Viewer' : '')))
    }, [])

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }
    // Icons for card view
    let UserIcon = `${ConfigData.SERVER_URL}/images/users.png`
    let CourseIcon = `${ConfigData.SERVER_URL}/images/course1.png`
    let BatchIcon = `${ConfigData.SERVER_URL}/images/batch.png`
    let InstituteIcon = `${ConfigData.SERVER_URL}/images/institud.png`
    let IncomeIcon = `${ConfigData.SERVER_URL}/images/income.png`
    let referedbyIcon = `${ConfigData.SERVER_URL}/images/referedby.png`
    let couponCodeIcon = `${ConfigData.SERVER_URL}/images/coupon.png`

    // Routes for card view
    let instituteRoute = '/pages/analytics/insitute'
    let courseRoute = '/pages/analytics/course'
    let batchRoute = '/pages/analytics/batch'
    let userRoute = '/pages/analytics/users'
    let incomeRoute = '/pages/analytics/revenue'
    let referedbyRoute = '/pages/analytics/referedBy'
    let generateCouponRoute = '/pages/analytics/generateCoupon'

    // states for card view
    let instituteState = { insititute: 'all' }
    let courseState = { insititute: 'all' }
    let batchState = { course_id: 'all' }
    let userState = { batch_id: 'all' }
    let incomeState = { batch_id: 'all' }
    let referedbyState = { batch_id: 'all' }
    let couponCodeState = { course_id: 'all' }


    return (
        <>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "white" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={4}> <CCardTitle style={{ color: "black" }}>Welcome {role}  </CCardTitle></CCol>
                                <CCol sm={6}></CCol>
                                <CCol sm={2}></CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            {
                loading && <LoadingScreen></LoadingScreen>
            }

            {
                !loading && error && <h1>{error}</h1>
            }

            <br></br>

            {!loading && !error &&
                <div style={{ margin: 10 }}>

                    <br></br>
                    <CRow>
                        <Card Id="insititue" cardIcon={InstituteIcon} title={analyticsData.institutes.title} routes={instituteRoute} state={instituteState} count={analyticsData.institutes.totalInstitutes} symbol={''}></Card>
                        <Card Id="course" cardIcon={CourseIcon} title={analyticsData.courses.title} routes={courseRoute} state={courseState} count={analyticsData.courses.totalCourses} symbol={''} ></Card>
                        <Card Id="batch" cardIcon={BatchIcon} title={analyticsData.batches.title} routes={batchRoute} state={batchState} count={analyticsData.batches.totalBatches} symbol={''} ></Card>
                        <Card Id="user" cardIcon={UserIcon} title={analyticsData.users.title} routes={userRoute} state={userState} count={analyticsData.users.totalUser} symbol={''}></Card>
                        {/* <Card Id="income" cardIcon={IncomeIcon} title={analyticsData.incomes.title} routes={incomeRoute} state={incomeState} count={analyticsData.incomes.totalIncomes} symbol={'₹'}></Card> */}
                        {/* <Card
                            Id="refer"
                            cardIcon={referedbyIcon}
                            title={analyticsData.referedBy.title}
                            routes={referedbyRoute}
                            // state={incomeState}
                            count={analyticsData.referedBy.count}
                            symbol={''}
                        ></Card> */}
                        <Card Id="couponCode" cardIcon={couponCodeIcon} title={analyticsData?.couponCodes.title} routes={generateCouponRoute} state={couponCodeState} count={analyticsData?.couponCodes.totalCoupons} symbol={''}>

                        </Card>
                    </CRow>
                </div>
            }

            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>

    );
}

export default Analytics;


