import React, { useState, useEffect, useRef } from 'react'
import userApi from 'src/utils/axios'
import { CCard, CButton, CModalHeader, CNavLink, CModalTitle, CModalBody, CModalFooter, CFormSelect, CModal, CTabPane, CNav, CNavItem, CTabContent, CCardBody, CCol, CRow, CCardTitle, CFormInput, CSpinner } from '@coreui/react'
import ConfigData from '../../../../config/constant.json'
import { Table } from 'react-bootstrap'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import ViewUserBatchs from './ViewUserBatchs';
import ExcelFile from 'src/views/shared/ExcelFile';
import UserReport from './UserReport';
import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const Users = (props) => {
    const [users, setUsers] = React.useState([]);
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [viewBatch, setViewBatch] = React.useState(false);
    const [resetPassword, setResetPassword] = React.useState(false);
    const [resetPassUserID, setResetPassUserId] = useState()
    const [viewReport, setViewReport] = React.useState(false);
    const [selectedUserId, setSelectedUserId] = React.useState(false);
    const [date, setDate] = useState(null);
    const [activeKey, setActiveKey] = React.useState(1);

    // Pagination logic
    const [pages, setPages] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const itemsPerPage = 10;
    const [loader, setLoader] = React.useState(false);
    const [filterBy, setFilterBy] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const inputRefAdmin = useRef(null);
    const inputRefUser = useRef(null);
    const inputRefTeacher = useRef(null);
    const inputRefViewer = useRef(null);
    const [visible, setVisible] = useState(false)
    let history = useHistory();
    const [offset, setOffSet] = useState(0);
    const [role, setRole] = React.useState(true);

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    React.useEffect(async () => {
        const type = activeKey === 1 ? 'admin' : (activeKey === 2 ? 'STUDENT' : (activeKey === 3 ? 'TEACHER' : (activeKey === 4 ? 'VIEWER' : '')))
        setLoader(true);
        if (debouncedValue !== '') {
            getData(type, debouncedValue);
        } else {
            getData(type, '');
        }
    }, [debouncedValue, filterBy])

    const getData = async (type, searchValue) => {
        try {
            const userType = type ? type : 'admin';
            const searchTerm = searchValue ? searchValue : '';

            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getusersAnalytics?batch_id=${props.location.state.batch_id}&userType=${userType}&filterBy=${filterBy}&searchTerm=${searchTerm}`)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setUsers(response.data.data)
            setPages(response.data.pages);
        } catch (e) {
            setError(`${e.message} :(`)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        } finally {
            setLoading(false);
            setLoader(false);
        }
    }

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Display viewUserBatch modal component
    let userBatches = (user_id) => {
        setViewBatch(true)
        setSelectedUserId(user_id)
    }

    const passwordReset = (userId) => {
        setResetPassword(true);
        setResetPassUserId(userId);
        setVisible(true);
    }

    // handle reset password of students
    const handleResetPassword = async () => {
        const data = {
            userId: resetPassUserID
        }
        try {
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/analytics/resetPassword`, data);
            if (response) {
                let toast = {
                    "status": true,
                    "body": response.data.message,
                    "message": "Success"
                }
                addToaster(toast)
            }
        } catch (err) {
            let toast = {
                "status": false,
                "body": "Server error occurred. Please try again later.",
                "message": "Error"
            }
            addToaster(toast)
        } finally {
            setVisible(false);
            setResetPassword(false);
        }
    }

    let UserReports = (user_id) => {
        setViewReport(true)
        setSelectedUserId(user_id)

    }

    const handleExcelFile = async () => {
        const userType = activeKey === 1 ? 'admin' : (activeKey === 2 ? 'STUDENT' : (activeKey === 3 ? 'TEACHER' : (activeKey === 4 ? 'VIEWER' : '')))
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelData?dateRange=${date}&&batch_id=${props.location.state.batch_id}&userType=${userType}&filterBy=${filterBy}`)
            if (response.data.status == true) {
                return response.data.data;
            }

        } catch (error) {
            let toast = {
                "status": false,
                "body": error.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    React.useMemo(() => {
        setPageCount(Math.ceil(pages / itemsPerPage));
    }, [itemOffset, itemsPerPage, pages]);

    //Displays data for each page.
    const handlePageClick = async (event, type) => {
        setLoader(true);
        try {
            setOffSet(event.selected);
            const newOffset = (event.selected * itemsPerPage) % pages;
            setItemOffset(newOffset);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getusersAnalytics?batch_id=${props.location.state.batch_id}&page=${event.selected}&limit=${itemsPerPage}&userType=${type}&filterBy=${filterBy}`)
            setUsers(response.data.data);
        } catch (e) {
            setError(`${e.message} :(`);
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        } finally {
            setLoader(false);
        }
    };

    const handleTabChange = async (key, type) => {
        setLoading(true);
        setUsers([]);
        setActiveKey(key);
        getData(type);
        setFilterBy('');
        setPages([]);
        setSelectedFilter('')
        setOffSet(0);
        if (inputRefAdmin.current) {
            inputRefAdmin.current.value = '';
        }
        if (inputRefUser.current) {
            inputRefUser.current.value = '';
        }
        if (inputRefViewer.current) {
            inputRefViewer.current.value = '';
        }
        if (inputRefTeacher.current) {
            inputRefTeacher.current.value = '';
        }
        setDebouncedValue('');
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    const filterByStudent = (e) => {
        setFilterBy(e.target.value);
        setSelectedFilter(e.target.value);
    }

    return (
        <>
            {/* Header  */}
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "#fff" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={3}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >Back</CButton> </CCol>
                                <CCol sm={6}><CCardTitle style={{ color: "#000", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Users List</CCardTitle></CCol>
                                <CCol sm={3}>
                                </CCol>

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <div className="row" style={{ marginTop: "3%" }}>
                {/* TAB */}
                <CNav variant="underline" layout="fill">
                    <CNavItem  >
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 1 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 1 ? '700' : '500',
                                fontSize: activeKey === 1 ? '20px' : '18px',
                            }}
                            active={activeKey === 1}
                            onClick={() => handleTabChange(1, 'admin')}
                        >
                            ADMIN
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 2 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 2 ? '700' : '500',
                                fontSize: activeKey === 2 ? '20px' : '18px',
                            }}
                            active={activeKey === 2}
                            onClick={() => handleTabChange(2, 'STUDENT')}
                        >
                            STUDENT
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 3 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 3 ? '700' : '500',
                                fontSize: activeKey === 3 ? '20px' : '18px',
                            }}
                            active={activeKey === 3}
                            onClick={() => handleTabChange(3, 'TEACHER')}
                        >
                            TEACHER</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            style={{
                                cursor: 'pointer',
                                color: 'black',
                                borderBottom: activeKey === 4 ? '3px solid rgb(214, 178, 49)' : '3px solid transparent',
                                fontWeight: activeKey === 4 ? '700' : '500',
                                fontSize: activeKey === 4 ? '20px' : '18px',
                            }}
                            active={activeKey === 4}
                            onClick={() => handleTabChange(4, 'VIEWER')}
                        >
                            VIEWER</CNavLink>
                    </CNavItem>
                </CNav>

                {/* Tab Content  */}
                {
                    loading ? <LoadingScreen></LoadingScreen> :
                        <div className="col-md-12" style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                            {/* admin  */}
                            <CTabContent>
                                <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 1}>

                                    {/* search / export to excel */}
                                    <CRow>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>
                                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                                    <CIcon icon={cilSearch} size="xl" />
                                                </div>

                                                <CFormInput
                                                    ref={inputRefAdmin}
                                                    placeholder="Search..."
                                                    autoComplete="Search"
                                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                                    onChange={handleSearch}
                                                />
                                            </div>

                                        </CCol>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%' }}>

                                                {/* Excel File */}
                                                <div >
                                                    <ExcelFile
                                                        data={handleExcelFile}
                                                    />
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>

                                    {/* table admin data  */}
                                    {
                                        !loader && !error ?
                                            <div >
                                                <h5 style={{ marginBottom: '1%' }}>Filtered Admin Count: <span ><b>{pages}</b></span></h5>
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>User Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.length > 0 &&
                                                            users.map((item, index) => (
                                                                <tr key={item._id}>
                                                                    <td>{offset * 10 + (index + 1)}</td>
                                                                    <td>{item.fullName ? item.fullName : (item.firstName ? `${item.firstName} ${item.lastName}` : item.name)}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.number ? item.number : item.mobile}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={(event) => { handlePageClick(event, 'admin') }}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={offset}
                                                />
                                            </div>
                                            : <LoadingScreen></LoadingScreen>
                                    }
                                    {
                                        !loader && error && <h1>{error}</h1>
                                    }

                                    {
                                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                                    }

                                </CTabPane>

                            </CTabContent>

                            {/* STUDENTS  */}
                            <CTabContent>
                                <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 2}>

                                    {/* search / export to excel / filter By */}
                                    <CRow >
                                        <CCol md={4} style={{ paddingTop: '10px' }}>
                                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                                    <CIcon icon={cilSearch} size="xl" />
                                                </div>

                                                <CFormInput
                                                    ref={inputRefUser}
                                                    placeholder="Search..."
                                                    autoComplete="Search"
                                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol md={4} style={{ paddingTop: '10px' }}>
                                            <CFormSelect aria-label="Default select example" onChange={filterByStudent} value={selectedFilter}>
                                                <option disabled selected="true" value=''>Filter By</option>
                                                <option value="">All</option>
                                                <option value="InHouse">InHouse</option>
                                                <option value="OutSider">OutSider</option>
                                            </CFormSelect></CCol>
                                        <CCol md={4} style={{ paddingTop: '10px' }}>
                                            {!loading &&
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%' }}>
                                                    <div >
                                                        <ExcelFile
                                                            data={handleExcelFile}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </CCol>
                                    </CRow>

                                    {/* table Student data  */}
                                    {
                                        !loader && !error ?
                                            <div >
                                                <h5 style={{ marginBottom: '1%' }}>Filtered Students Count: <span ><b>{pages}</b></span></h5>
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>User Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                            <th className="text-center">Batch Purchased</th>
                                                            {role && <th>Action</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.length > 0 &&
                                                            users.map((item, index) => (
                                                                <tr key={item._id}>
                                                                    <td>{offset * 10 + (index + 1)}</td>
                                                                    <td>{item.fullName ? item.fullName : (item.firstName ? `${item.firstName} ${item.lastName}` : item.name)}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.number ? item.number : item.mobile}</td>
                                                                    <td className="text-center" >
                                                                        <button style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} id={`batch${item.userId}`} onClick={() => userBatches(item.userId)} type="submit" className="btn btn-outline-secondary">View Batches</button>
                                                                    </td>
                                                                    {role &&
                                                                        <td className="text-center" >
                                                                            <button type="submit" style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => passwordReset(item.userId)} className="btn">Reset Password</button>
                                                                        </td>}
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={(event) => { handlePageClick(event, 'STUDENT') }}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={offset}
                                                />
                                            </div> : <LoadingScreen></LoadingScreen>
                                    }

                                    {
                                        !loader && error && <h1>{error}</h1>
                                    }

                                    {
                                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                                    }

                                </CTabPane>

                            </CTabContent>

                            {/* teacher  */}
                            <CTabContent>
                                <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 3}>

                                    {/* search / export to excel  */}
                                    <CRow>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>
                                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                                    <CIcon icon={cilSearch} size="xl" />
                                                </div>
                                                <CFormInput
                                                    ref={inputRefTeacher}
                                                    placeholder="Search..."
                                                    autoComplete="Search"
                                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%' }}>
                                            <div >
                                                <ExcelFile
                                                    data={handleExcelFile}
                                                />
                                            </div>
                                        </div></CCol>
                                    </CRow>

                                    {/* table Teacher data  */}
                                    {
                                        !loader && !error ?
                                            <div >
                                                <h5 style={{ marginBottom: '1%' }}>Filtered Teachers Count: <span ><b>{pages}</b></span></h5>
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.length > 0 &&
                                                            users.map((item, index) => (
                                                                <tr key={item._id}>
                                                                    <td>{offset * 10 + (index + 1)}</td>
                                                                    <td>{item.fullName ? item.fullName : (item.firstName ? `${item.firstName} ${item.lastName}` : item.name)}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.number ? item.number : item.mobile}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={(event) => { handlePageClick(event, 'TEACHER') }}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={offset}
                                                />
                                            </div> : <LoadingScreen></LoadingScreen>
                                    }

                                    {
                                        !loader && error && <h1>{error}</h1>
                                    }

                                    {
                                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                                    }

                                </CTabPane>
                            </CTabContent>

                            {/* viewer */}
                            <CTabContent>
                                <CTabPane role="tabpanel" aria-labelledby="video-tab" visible={activeKey === 4}>

                                    {/* search / export to excel */}
                                    <CRow>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>
                                            <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                                <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                                    <CIcon icon={cilSearch} size="xl" />
                                                </div>
                                                <CFormInput
                                                    ref={inputRefViewer}
                                                    placeholder="Search..."
                                                    autoComplete="Search"
                                                    style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </CCol>
                                        <CCol md={6} style={{ paddingTop: '10px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '1%' }}>
                                                {/* Excel File */}
                                                <div >
                                                    <ExcelFile
                                                        data={handleExcelFile}
                                                    />
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>

                                    {/* table Viewer data  */}
                                    {
                                        !loading && !error ?
                                            <div >
                                                <h5 style={{ marginBottom: '1%' }}>Filtered Viewers Count: <span ><b>{pages}</b></span></h5>
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.length > 0 &&
                                                            users.map((item, index) => (
                                                                <tr key={item._id}>
                                                                    <td>{offset * 10 + (index + 1)}</td>
                                                                    <td>{item.fullName ? item.fullName : (item.firstName ? `${item.firstName} ${item.lastName}` : item.name)}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.number ? item.number : item.mobile}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={(event) => { handlePageClick(event, 'VIEWER') }}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={offset}
                                                />
                                            </div> : <LoadingScreen></LoadingScreen>
                                    }

                                    {
                                        !loader && error && <h1>{error}</h1>
                                    }

                                    {
                                        toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                                    }

                                </CTabPane>
                            </CTabContent>
                        </div>
                }

                {
                    !loading && users.length === 0 && !error && !loader &&
                    <div className="text-center">
                        <h3>No Data Found</h3>
                    </div>
                }

                {/* Reset Password  */}
                {
                    resetPassword &&
                    <CModal
                        backdrop="static"
                        alignment="center"
                        visible={visible}
                        onClose={() => setVisible(false)}
                        aria-labelledby="VerticallyCenteredExample"
                    >
                        <CModalHeader>
                            <CModalTitle id="VerticallyCenteredExample">Reset Password</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Are you sure you want to reset the password ?</span>
                        </CModalBody>
                        <CModalFooter>
                            <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => handleResetPassword()}>Yes</CButton>
                            <CButton style={{ backgroundColor: '#ab2f0c', color: 'white' }} onClick={() => setVisible(false)}>
                                No
                            </CButton>
                        </CModalFooter>
                    </CModal>
                }

                {/* View Batch  */}
                {
                    viewBatch &&
                    <ViewUserBatchs
                        visible={viewBatch}
                        onClose={() => setViewBatch(false)}
                        selectedUserId={selectedUserId}

                    ></ViewUserBatchs>
                }

                {/* View Report  */}
                {
                    viewReport &&
                    <UserReport
                        visible={viewReport}
                        onClose={() => setViewReport(false)}
                        selectedUserId={selectedUserId}
                    ></UserReport>
                }
            </div >
        </>
    );
}

export default Users;