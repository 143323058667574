import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../../config/constant.json'
import { CCardBody, CCol, CRow, CModalHeader, CModal, CFormTextarea, CInputGroup, CModalTitle, CModalBody, CForm, CFormInput, } from '@coreui/react'



const AddBatchModule = ({ visible, onClose, addToaster, addModuleCallBack, courseId, Batch_id }) => {
    const moduleSchema = yup.object({
        module_serial_number: yup.number()
            .required('Serial number is required'),

        module_name: yup.string()
            .required('Name is required').min(3),

        module_description: yup.string()
            .required('About is required').min(10)

    });

    const handleAddModule = async (values) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/addbatchmodule`, { values: { ...values, course_id: courseId, batch_id: Batch_id } })

            if (response.data.status == false) {
                throw Error(response.data.message);
            }

            let toast = {
                "status": true,
                "body": "Module Added",
                "message": "Success"
            }

            addToaster(toast)
            addModuleCallBack(response.data.data)

        } catch (e) {

            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }

            addToaster(toast)
        }
    }

    return (
        <>
            <CModal visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>Add Module</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={{ module_name: '', course_id: '', module_description: '', module_serial_number: '' }}
                                validationSchema={moduleSchema}
                                onSubmit={(values, actions) => {
                                    handleAddModule(values);
                                    actions.resetForm();
                                }}
                            >
                                {(formikProps) => (
                                    <CForm>
                                        <CInputGroup className="mb-3">
                                            <legend>Module Sequence Number</legend>
                                            <CFormInput
                                                placeholder="Serial Number"
                                                autoComplete="module_serial_number"
                                                onChange={formikProps.handleChange('module_serial_number')}
                                                value={formikProps.values.module_serial_number}
                                                onBlur={formikProps.handleBlur('module_serial_number')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.module_serial_number && formikProps.errors.module_serial_number}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>Module Name</legend>
                                            <CFormInput
                                                placeholder="Module Name"
                                                autoComplete="module_name"
                                                onChange={formikProps.handleChange('module_name')}
                                                value={formikProps.values.module_name}
                                                onBlur={formikProps.handleBlur('module_name')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.module_name && formikProps.errors.module_name}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>Module Description</legend>
                                            <CFormTextarea
                                                id="module_description"
                                                rows="3"
                                                placeholder='Module Description'
                                                text="Must be 8-20 words long."
                                                onChange={formikProps.handleChange('module_description')}
                                                value={formikProps.values.module_description}
                                                onBlur={formikProps.handleBlur('module_description')}
                                            ></CFormTextarea>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.module_description && formikProps.errors.module_description}</p>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>
                                            <CCol xs={5}> </CCol>
                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default AddBatchModule;