import axios from 'axios';;

const userApi = axios.create();
userApi.defaults.timeout = 10000;

userApi.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

userApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default userApi;
