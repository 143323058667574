import { Route, Switch, useRouteMatch } from "react-router-dom";
import Batch from "./Batch/Batch";
import Analytics from "./Analytics";
import Course from "./Course/Course";
import Institute from "./Institute/Institute";
import Revenue from "./Revenue/Revenue";
import Users from "./Users/Users";
import Page404 from "../page404/Page404";
import UserAnalytics from "./Users/UserAnalytics";
import ReferedBy from "./ReferedBy/ReferedBy";
import CouponCodeGenerate from "./CouponCodeGenerate/CouponCodeGenerate";
import AddCoupon from "./CouponCodeGenerate/AddCoupon";


const analytics_routes = () => {
    const { url } = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`${url}/`} exact component={Analytics} />
                <Route path={`${url}/insitute`} component={Institute} />
                <Route path={`${url}/course`} component={Course} />
                <Route path={`${url}/batch`} component={Batch} />
                <Route path={`${url}/users`} component={Users} />
                <Route path={`${url}/user_analytics`} component={UserAnalytics} />
                <Route path={`${url}/revenue`} component={Revenue} />
                {/* <Route path={`${url}/referedBy`} component={ReferedBy} /> */}
                <Route path={`${url}/generateCoupon`} component={CouponCodeGenerate} />
                <Route path={`${url}/createCoupon`} component={AddCoupon} />
                <Route path={`${url}/*`} exact component={Page404} />
            </Switch>
        </>

    );
}

export default analytics_routes;
