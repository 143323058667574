import React from 'react'
import userApi from 'src/utils/axios';;
import ConfigData from './../../../config/constant.json'
import {
    CCard, CButton, CCardBody, CCol, CRow, CCardText, CCardTitle, CCardImage, CFormInput
} from '@coreui/react'
import { Table, Button } from 'react-bootstrap'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Link } from 'react-router-dom';
import AddTeacher from './AddTeacher';
import DeleteDailog from '../Master/DeleteDailog';
import EditTeacher from './EditTeacher';
import ReactPaginate from 'react-paginate';
import TeacherAlert from './TeacherAlert';
import ExcelFile from 'src/views/shared/ExcelFile';
import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const Teacher = (props) => {
    const [modalState, setModalState] = React.useState(false)
    const [teachers, setTeachers] = React.useState([])
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [teacherDetails, setTeacherDetails] = React.useState(null);
    const [teacherId, setTeacherId] = React.useState(null);
    const [editTeacherState, setEditTeacherState] = React.useState(false);
    const [teacherAlert, setTeacherAlert] = React.useState(false);
    const [isEnrolled, setIsEnrolled] = React.useState(false)
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [role, setRole] = React.useState(true);


    React.useEffect(async () => {
        try {
            // Gets all the courses by institute id
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/teacher/getteachers`)
            setTeachers(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    // Pagination logic
    const itemsPerPage = 10;
    const [currentItems, setCurrentItems] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(teachers.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(teachers.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, teachers]);

    const handlePageClick = (event) => {
        setOffset(event.selected);
        const newOffset = (event.selected * itemsPerPage) % teachers.length;
        setItemOffset(newOffset);
    };

    //Handle excel file 
    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/teacher/getExcelTeacherData`);
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.log(e.message)
        }
    };


    const handleDelete = async () => {
        try {

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/teacher/deleteteacher`, { teacherId })
            if (response.data.status == false) throw Error(response.data.message);

            setTeachers(teachers.filter((teacher) => { return teacher._id !== teacherDetails._id }))
            setDeleteDailog(false)
        } catch (e) {
            console.log(e)
        }

    }

    const checkTeacherCourses = async (teacherId) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/teacher/checkTeacherCourse`, { teacherId })
            if (response.data.status == false) throw Error(response.data.message);
            if (response.data.status == true && response.data.isEnrolled == true) {
                return true
            } else if (response.data.status == true && response.data.isEnrolled == false) {
                return false
            }

        } catch (e) {
            console.log(e)
        }
    }


    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Add Institute Callback
    let addTeacherCallBack = (newteacher) => {
        setTeachers([...teachers, newteacher])
    }

    let deleteTeacher = async (item) => {
        let condition = await checkTeacherCourses(item._id)
        setTeacherDetails(item)
        if (condition) {
            setTeacherAlert(true)
        } else {
            setTeacherId(item._id)
            setDeleteDailog(true)

        }

    }
    let editteacher = (item) => {
        setEditTeacherState(true)
        setTeacherDetails(item)
    }
    let editCallback = (editedTeacher) => {
        const newList = teachers.map((item) => {
            if (item._id === editedTeacher._id) {
                const updatedItem = {
                    ...editedTeacher
                };
                return updatedItem;
            }
            return item;
        });
        setTeachers(newList);
        setEditTeacherState(false)
    }


    return (
        <>
            <div style={{ margin: 20 }}>
                <CRow>
                    <CCol sm={12}>
                        <CCard style={{ background: "white" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={4}> <CCardTitle style={{ color: "black" }}>HSNCU Teachers</CCardTitle></CCol>
                                    <CCol sm={6}></CCol>
                                    {/* {
                                        role &&
                                        // <CCol sm={2}><CButton onClick={() => setModalState(true)} style={{ color: "black", backgroundColor: "white" }} >Add Teacher</CButton></CCol>
                                    } */}
                                    {
                                        modalState &&
                                        <AddTeacher
                                            visible={modalState}
                                            onClose={() => setModalState(false)}
                                            addToaster={addToaster}
                                            addTeacherCallBack={addTeacherCallBack}
                                        >
                                        </AddTeacher>
                                    }
                                    {
                                        deleteDailog && <DeleteDailog
                                            visible={deleteDailog}
                                            onClose={() => setDeleteDailog(false)}
                                            teacherId={teacherId}
                                            delete={handleDelete}
                                        ></DeleteDailog>
                                    }
                                    {
                                        editTeacherState &&
                                        <EditTeacher
                                            visible={editTeacherState}
                                            onClose={() => setEditTeacherState(false)}
                                            addToaster={addToaster}
                                            teacherDetails={teacherDetails}
                                            editCallback={editCallback}

                                        >
                                        </EditTeacher>
                                    }
                                    {
                                        teacherAlert && <TeacherAlert
                                            visible={teacherAlert}
                                            onClose={() => { setTeacherAlert(false) }}
                                            setTeacherAlert={setTeacherAlert}
                                            teacherDetails={teacherDetails}
                                        >
                                        </TeacherAlert>
                                    }
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <br></br>

                <CRow>

                    {
                        loading && <LoadingScreen></LoadingScreen>
                    }
                    {
                        !loading &&
                        teachers.length > 0 &&

                        <div className="row">
                            <CRow>
                                <CCol md={6}>
                                    <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '3%' }}>
                                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                            <CIcon icon={cilSearch} size="xl" />
                                        </div>

                                        <CFormInput
                                            placeholder="Search..."
                                            autoComplete="Search"
                                            style={{ paddingLeft: '40px', width: '100%', }} // Adjust paddingLeft to make space for the icon
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                </CCol>
                                <CCol md={4}></CCol>
                                <CCol md={2}>
                                    <ExcelFile data={handleExcelFile} />
                                </CCol>
                            </CRow>

                            <div className="pt-4">

                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Gender</th>
                                            <th>Number</th>
                                            {
                                                role &&
                                                <th className="text-center">Action</th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            search == "" ?
                                                currentItems.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td>{offset * 10 + (index + 1)}</td>
                                                        <td>{(item.firstName) ? ((item.firstName ? item.firstName : ' ') + ' ' + (item.lastName ? item.lastName : ' ')) : (item.fullName) || (item.name)}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.gender}</td>
                                                        <td>{item.number ? item.number : item.mobile}</td>
                                                        {
                                                            role &&
                                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <button type="submit" className="btn btn-outline-secondary" style={{ color: '#000', backgroundColor: 'rgb(214, 178, 49)' }} onClick={() => editteacher(item)} >Edit</button>
                                                            </td>
                                                        }
                                                    </tr>
                                                )) : teachers.filter((val) => {
                                                    if (search == "") {
                                                        return val
                                                    } else if (
                                                        (val.name && val.name.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.firstName && val.firstName.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.lastName && val.lastName.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.fullName && val.fullName.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.email && val.email.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.number && val.number.toLowerCase().includes(search.toLowerCase())) ||
                                                        (val.mobile && val.mobile.toLowerCase().includes(search.toLowerCase()))

                                                    ) {
                                                        return val;
                                                    }

                                                }).map((item, index) => (
                                                    <tr key={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{(item.firstName) ? ((item.firstName ? item.firstName : ' ') + ' ' + (item.lastName ? item.lastName : ' ')) : (item.fullName) || (item.name)}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.gender}</td>
                                                        <td>{item.number ? item.number : item.mobile}</td>
                                                        <td >
                                                            <button type="submit" className="btn btn-outline-primary" onClick={() => editteacher(item)} >Edit</button>
                                                            &nbsp;&nbsp;&nbsp; <button type="submit" className="btn btn-outline-danger" onClick={() => deleteTeacher(item)} >Delete</button>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />

                            </div>
                        </div>

                    }
                    {
                        !loading && teachers.length == 0 && !error && <h1> No teachers Added</h1>
                    }
                    {
                        !loading && error && <h1>{error}</h1>
                    }
                </CRow>

            </div>
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    );
}


export default Teacher;