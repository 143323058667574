
import React, { useState } from 'react'
import userApi from 'src/utils/axios'
import ConfigData from 'src/config/constant.json'
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CFormInput } from '@coreui/react'
import { Table } from 'react-bootstrap'
import LoadingScreen from 'src/views/shared/Loading';
import Toaster from 'src/views/shared/Toaster';
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import ExcelFile from 'src/views/shared/ExcelFile';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { cilSearch } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const Course = (props) => {
    const [courseData, setCourseData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false);
    const [toast, addToast] = React.useState(false)
    const [notifymodal, setnotifymodal] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState(false);
    const [date, setDate] = useState(null);
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function


    let history = useHistory();

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    React.useEffect(async () => {

        if (!debouncedValue) {
            getCourseData();
        } else {
            getCourseData(debouncedValue);
        }
    }, [debouncedValue])

    const getCourseData = async (searchValue) => {
        try {
            const searchTerm = searchValue ? searchValue : '';
            const institute = props.location.state.institute ? props.location.state.institute : 'all';

            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getCourseAnalytics?institute=${institute}&searchTerm=${searchTerm}`);
            if (response.data.status != true) {
                setLoading(false)
                throw Error(response.data.message);
            }

            setCourseData(response.data.data)
            setLoading(false)

        } catch (error) {
            setError(`${error.message} :(`)
            setLoading(false)

            let toast = {
                "status": false,
                "body": error.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }

    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelDataCourse?dateRange=${date}&&institute=${props.location.state.institute}`)
            if (response.data.status == true) {
                return response.data.data
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    // Pagination logic
    const itemsPerPage = 10;
    const [search, setSearch] = React.useState('');
    const [currentItems, setCurrentItems] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [offset, setOffset] = React.useState(0);

    React.useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(courseData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(courseData.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, courseData]);
    const handlePageClick = (event) => {
        setOffset(event.selected);
        const newOffset = (event.selected * itemsPerPage) % courseData.length;
        setItemOffset(newOffset);
    };


    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    return (
        <>
            {
                notifymodal &&

                <NotificationModal
                    visible={notifymodal}
                    closeModal={setnotifymodal}
                    onClose={() => setnotifymodal(false)}
                    selectedData={selectedData}
                    addToaster={addToaster}

                />
            }

            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "white" }}>
                        <CCardBody>
                            <CRow>

                                <CCol sm={5}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)" }} >Back</CButton> </CCol>
                                <CCol sm={5}><CCardTitle style={{ color: "black" }}>Course Analytics</CCardTitle></CCol>
                                {/* <CCol sm={2}>
                                    <CFormInput
                                        placeholder="Search"
                                        autoComplete="Search"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </CCol> */}

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <br />
            {!loading &&
                <CRow>
                    <CCol md={6}>
                        <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                            <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                <CIcon icon={cilSearch} size="xl" />
                            </div>

                            <CFormInput
                                placeholder="Search..."
                                autoComplete="Search"
                                style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                onChange={handleSearch}
                            />
                        </div>
                    </CCol>
                    <CCol md={4}></CCol>
                    <CCol md={2}>
                        <div>
                            <ExcelFile data={handleExcelFile} />
                        </div>
                    </CCol>
                </CRow>
            }
            {
                !loading && !error &&
                <div>
                    <h5 style={{ marginBottom: '1%' }}>Filtered Courses count: <span ><b>{courseData.length}</b></span></h5>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr. No</th>
                                <th>Course Name</th>
                                <th>Course Purchased Count</th>
                                <th>Batch count</th>
                                <th>Module Count</th>
                                <th>Video Count</th>
                                <th>Resources Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                search == "" ?
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{offset * 10 + (index + 1)}</td>
                                            <td>{item.allCourses.courseName}</td>
                                            <td>{item.PurchaseCount}</td>
                                            <td>{item.batches.length}</td>
                                            <td>{item.modulesbycourse}</td>
                                            <td>{item.videoCount}</td>
                                            <td>{item.recourcesCount}</td>
                                        </tr>
                                    )) : courseData.filter((val) => {
                                        if (search == "") {
                                            return val
                                        } else if (val.allCourses.courseName.toLowerCase().includes(search.toLowerCase())) {
                                            return val
                                        }
                                    }).map((item, index) => (
                                        <tr key={index}>
                                            <td>{offset * 10 + (index + 1)}</td>
                                            <td>{item.allCourses.courseName}</td>
                                            <td>{item.PurchaseCount}</td>
                                            <td>{item.batches.length}</td>
                                            <td>{item.modulesbycourse}</td>
                                            <td>{item.videoCount}</td>
                                            <td>{item.recourcesCount}</td>
                                        </tr>
                                    ))

                            }
                        </tbody>
                    </Table>

                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>

            }
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
            {
                error && !loading &&
                <h1>{error}</h1>
            }
            {
                loading &&
                <LoadingScreen></LoadingScreen>
            }

        </>
    );
}

export default Course;