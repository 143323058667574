import { CForm, CInputGroup, CFormInput, CCol, CRow, CModalTitle, CModalBody, CFormSelect, CModalHeader, CModal, CFormTextarea, CSpinner } from '@coreui/react'
import { Formik } from "formik";
import * as yup from 'yup';
import React from 'react'
import userApi from 'src/utils/axios';
import ConfigData from './../../../../../config/constant.json'

const ScheduleMeeting = (props) => {

    const [teachers, setTeachers] = React.useState([]);
    const [loader, setloader] = React.useState(false);
    const [error, setError] = React.useState(false);
    let batch_id = props.data._id;
    let course_id = props.data.courseId;

    React.useEffect(async () => {

        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/teacher/getteachers`)
            setTeachers(response.data.data)
        } catch (error) {
            setloader(false)
            console.log(error);
        }
    }, [])

    const meetingSchema = yup.object({
        meeting_summary: yup.string()
            .required('Meeting Summary is required')
            .min(3),
        meetingDate: yup.string()
            .required('Date is required'),
        meetingStartTime: yup.string()
            .required('Start Time is required'),
        meetingEndTime: yup.string()
            .required('End Time is required')
            // should not be same as start time or less than start time
            .test('is-greater-than-start-time', 'End Time should be greater than Start Time', function (value) {
                return this.parent.meetingStartTime < value;
            }),
        teacher_id: yup.string()
            .required('Teacher is required'),
        description: yup.string()
            .required('Description is Required')
            .min(10),
    });

    const handleMeeting = async (values) => {
        try {
            setloader(true)

            let data = { ...values, batch_id, course_id }

            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/addBatchMeeting`, data)

            if (response.data.status == true) {
                setloader(false)
                let toast = {
                    "status": true,
                    "body": "Meeting Scheduled",
                    "message": "Success"
                }
                props.addToaster(toast)
                props.onClose();
            }

        } catch (error) {
            setloader(false)
            console.log(error);
            let toast = {
                "status": true,
                "body": "Something went wrong",
                "message": "Error"
            }
            props.addToaster(toast)
            props.onClose();
        }
    }

    return (
        <CModal size="lg" visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
            <CModalHeader onClose={props.onClose}>

                <CModalTitle><b>Schedule Batch Meeting</b></CModalTitle>

            </CModalHeader>

            <CModalBody>

                <Formik
                    initialValues={{ meeting_summary: '', meetingDate: '', meetingStartTime: '', meetingEndTime: '', teacher_id: '', description: '' }}
                    validationSchema={meetingSchema}
                    onSubmit={(values, actions) => {
                        actions.resetForm();
                        handleMeeting(values);
                    }}
                >
                    {(formikProps) => (

                        <CForm>

                            <CInputGroup className="mb-3">
                                <legend>Meeting Summary</legend>
                                <CFormInput
                                    placeholder="Meeting Title"
                                    autoComplete="meeting_summary"
                                    onChange={formikProps.handleChange('meeting_summary')}
                                    value={formikProps.values.meeting_summary}
                                    onBlur={formikProps.handleBlur('meeting_summary')}
                                />
                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.meeting_summary && formikProps.errors.meeting_summary}</p>



                            <CInputGroup className='mb-3'>
                                <legend>Meeting Date</legend>
                                <input
                                    type="date"
                                    id="meetingDate"
                                    onChange={formikProps.handleChange('meetingDate')}
                                    onBlur={formikProps.handleBlur('meetingDate')}
                                    value={formikProps.values.meetingDate}
                                />

                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.meetingDate && formikProps.errors.meetingDate}</p>

                            <CInputGroup className='mb-3'>
                                <legend>Meeting Start Time</legend>
                                <input
                                    type="time"
                                    id="meetingStartTime"
                                    onChange={formikProps.handleChange('meetingStartTime')}
                                    onBlur={formikProps.handleBlur('meetingStartTime')}
                                    value={formikProps.values.meetingStartTime}
                                />
                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.meetingStartTime && formikProps.errors.meetingStartTime}</p>

                            <CInputGroup className='mb-3'>
                                <legend>Meeting End Time</legend>
                                <input
                                    type="time"
                                    id="meetingEndTime"
                                    onChange={formikProps.handleChange('meetingEndTime')}
                                    onBlur={formikProps.handleBlur('meetingEndTime')}
                                    value={formikProps.values.meetingEndTime}
                                />
                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.meetingEndTime && formikProps.errors.meetingEndTime}</p>

                            <CInputGroup className="mb-3">
                                <legend>Teacher</legend>
                                <CFormSelect
                                    value={formikProps.values.teacher_id}
                                    onChange={formikProps.handleChange('teacher_id')}
                                    size="lg" className="mb-3"
                                    aria-label="Teacher">

                                    <option
                                        key={1}
                                        value={"1"}
                                    >Select Teacher
                                    </option>

                                    {
                                        teachers.length >= 0 &&
                                        teachers.map((option) =>
                                            <option
                                                key={option._id}
                                                value={option._id}
                                            >{option.name}
                                            </option>
                                        )
                                    }

                                </CFormSelect>
                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.teacher_id && formikProps.errors.teacher_id}</p>

                            <CInputGroup className="mb-3">
                                <legend>Description</legend>
                                <CFormTextarea
                                    id="description"
                                    rows="3"
                                    placeholder='Description'
                                    onChange={formikProps.handleChange('description')}
                                    value={formikProps.values.description}
                                    onBlur={formikProps.handleBlur('description')}
                                ></CFormTextarea>
                            </CInputGroup>
                            <p style={{ color: 'red' }}>{formikProps.touched.description && formikProps.errors.description}</p>

                            <CRow >
                                <CCol xs={5}> </CCol>
                                <CCol xs={2}>

                                    {!loader && <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>}
                                    {loader && <CCol xs={7}> <button disabled onClick={formikProps.handleSubmit} type="submit" className="btn btn-primary"><CSpinner component="span" size="sm" aria-hidden="true" />&nbsp;&nbsp;Submit</button> </CCol>}


                                </CCol>

                                <CCol xs={5}>
                                </CCol>

                            </CRow>

                        </CForm>
                    )}

                </Formik>

            </CModalBody>
        </CModal>
    );
}

export default ScheduleMeeting;