import React from 'react'
import { CButton, CModalHeader, CModal, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilWarning } from '@coreui/icons'

const TeacherAlert = (props) => {
    const handleClose = async () => {
        props.onClose();
        props.setTeacherAlert(false);
    }
    return (
        <>
            <CModal visible={props.visible} alignment="center" onClose={handleClose} backdrop='static'>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle><CIcon icon={cilWarning} size='lg' style={{color:'red'}}/> Alert  </CModalTitle>
                </CModalHeader>
                <CModalBody>You cannot delete {props.teacherDetails.name} Because {props.teacherDetails.name} is already enrolled for some available courses</CModalBody>
                <CModalFooter>
                    <CButton color="info" onClick={handleClose}>OK</CButton>
                </CModalFooter>
            </CModal>

        </>
    );
}

export default TeacherAlert;