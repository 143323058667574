import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import icon from '../assets/images/HSNCULogo.png';


const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>

        <CHeaderBrand className="d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          <h2></h2>
        </CHeaderBrand>

        <CHeaderNav style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <CNavItem style={{ display: 'flex', alignItems: 'center' }}>
            <img src={icon} height="50" width="60" alt="Logo" style={{ paddingRight: '10px' }} />
            <CNavLink to="/pages/analytics" component={NavLink} style={{ display: 'inline-flex', alignItems: 'center' }}>
              <b style={{ whiteSpace: 'nowrap', fontSize: '25px' }}>HSNCU CERTIFICATION</b>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>

        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>

  )
}

export default AppHeader
