import React from 'react'
import userApi from 'src/utils/axios';
import { Formik, ErrorMessage } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCardBody, CCol, CRow, CSpinner,
    CModalHeader, CModal, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CFormLabel,
    CForm, CFormInput,
} from '@coreui/react'


const EditVideo = (props) => {
    const [radio, setradio] = React.useState(props.videoDetails.videoStatus)
    const [isDate, setDate] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [video, setVideo] = React.useState([])
    const [teacherDataArray, setTeacherDataArray] = React.useState(props.teacherData)
    const re = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

    let _id = props.videoDetails._id
    let teacher_id = props.videoDetails.teacher_id

    const VideoSchema = yup.object({

        serial: yup.number()
            .required('Serial number is required'),

        title: yup.string()
            .required(),

        teacher_id: yup.string()
            .required('Teacher is required')
            .test('is-valid', 'Please select a Teacher', (val) => {
                return val != '1'
            }),

        description: yup.string()
            .required(),

        fileName: yup.string()
            .required(),
        sampleVideo: yup.string()
            .required('Sample video is Required'),
        // live_meeting_Link: yup.string()
        //     .matches(re, "Please enter a valid link, Example:https://your domain.com")
    })

    const initialValues = {
        serial: props.videoDetails.sr_no,
        title: props.videoDetails.title,
        fileName: props.videoDetails.fileName,
        description: props.videoDetails.description,
        date: props.videoDetails.scheduleDate,
        teacher_id: props.videoDetails.teacher_id,
        // live_meeting_Link: props.videoDetails.live_meeting_Link,
        sampleVideo: props.videoDetails.sampleVideo,
    }


    function handleTransfer(bool) {
        setDate(bool)
        setradio(bool)
    }


    const handleEditVideo = async (values, submitProps) => {
        try {
            setIsLoading(true)
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/video/editvideo?_id=${props.videoDetails._id}`, { ...values, videoStatus: radio })
            setIsLoading(false)
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            let toast = {
                "status": true,
                "body": "Video Edited",
                "message": "Success"
            }
            props.addToaster(toast);
            props.editVideoCallBack(response.data.videoupdates)
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            props.addToaster(toast)
        }
    }



    return (
        <>
            <CModal visible={props.visible} backdrop={'static'} alignment="center" onClose={props.onClose}>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>Edit Video</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={VideoSchema}
                                onSubmit={handleEditVideo}
                            >
                                {(formikProps) => (
                                    <CForm>

                                        <div className="mb-3">
                                            <CFormLabel htmlFor="exampleFormControlInput1">Serial Number</CFormLabel>
                                            <CFormInput
                                                placeholder="Serial Number"
                                                autoComplete="serial"
                                                onChange={formikProps.handleChange('serial')}
                                                value={formikProps.values.serial}
                                                onBlur={formikProps.handleBlur('serial')}
                                            />

                                            <p style={{ color: 'red' }}>{formikProps.touched.serial && formikProps.errors.serial}</p>
                                        </div>

                                        <div className="mb-3">
                                            <CFormLabel htmlFor="exampleFormControlInput1">Title</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Video Title"
                                                autoComplete="username"
                                                onChange={formikProps.handleChange('title')}
                                                value={formikProps.values.title}
                                                onBlur={formikProps.handleBlur('title')}
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.title && formikProps.errors.title}</p>
                                        </div>

                                        {/* <div className="mb-3">
                                            <CFormLabel htmlFor="formFile">Select Video</CFormLabel>
                                            <CFormInput
                                                onChange={(event) => formikProps.setFieldValue('fileName', event.target.files[0])}
                                                // disabled={true}
                                                accept=".mp4,.mov,.flv,.wmv,.avi,.webm,.mkv"
                                                type="file" id="formFile"
                                            // disabled={true}
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.fileName && formikProps.errors.fileName}</p>
                                        </div> */}

                                        <div className="mb-3">
                                            <CInputGroup className="mb-3">
                                                <legend>Taught By</legend>
                                                <CFormSelect value={formikProps.values.teacher_id} onChange={formikProps.handleChange('teacher_id')} size="lg" className="mb-3" aria-label="Taught By">
                                                    <option
                                                        key={1}
                                                        value={"1"}
                                                    >Select Teacher
                                                    </option>

                                                    {teacherDataArray.map((option) =>
                                                        <option
                                                            key={option._id}
                                                            value={option.userId}
                                                        >{option.fullName ? option.fullName : (option.firstName ? `${option.firstName} ${option.lastName}` : option.name)}
                                                        </option>
                                                    )}
                                                </CFormSelect>
                                            </CInputGroup>
                                            <p style={{ color: 'red' }}>{formikProps.touched.teacher_id && formikProps.errors.teacher_id}</p>
                                        </div>

                                        <div className="mb-3">
                                            <CFormLabel htmlFor="Description">Description</CFormLabel>
                                            <CFormTextarea
                                                onChange={formikProps.handleChange('description')}
                                                value={formikProps.values.description}
                                                id="Description" rows="3"
                                                onBlur={formikProps.handleBlur('description')}
                                            ></CFormTextarea>
                                            <p style={{ color: 'red' }}>{formikProps.touched.description && formikProps.errors.description}</p>
                                        </div>

                                        {/* <div className="mb-3">
                                            <div>
                                                <CFormLabel >Live Meeting Link</CFormLabel>
                                            </div>

                                            <input type="radio" value={formikProps.values.live_meeting_Link} checked={formikProps.values.live_meeting_Link} name="live_meeting_Link" onChange={() => formikProps.setFieldValue('live_meeting_Link', true)} />
                                            &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <input type="radio" value={formikProps.values.live_meeting_Link} checked={!formikProps.values.live_meeting_Link} name="live_meeting_Link" onChange={() => formikProps.setFieldValue('live_meeting_Link', false)} />
                                            &nbsp;<label >No</label>

                                            {
                                                formikProps.values.live_meeting_Link &&

                                                <div>
                                                    <CFormInput
                                                        type="text"
                                                        placeholder="https://meet.google.com/lookup/..."
                                                        value={formikProps.values.live_meeting_Link == true ?
                                                            props.videoDetails.live_meeting_Link :
                                                            formikProps.values.live_meeting_Link}
                                                        onBlur={formikProps.handleBlur('live_meeting_Link')}
                                                        onChange={formikProps.handleChange('live_meeting_Link')}
                                                    />

                                                    <p style={{ color: 'red' }}>{formikProps.touched.live_meeting_Link && formikProps.errors.live_meeting_Link}</p>

                                                </div>
                                            }

                                        </div> */}

                                        {/* <div className="mb-3">
                                            <div>
                                                <div> <span>Do you want to Schedule this video??</span>&nbsp;&nbsp;
                                                    {isDate && <input type="date"
                                                        value={formikProps.values.date}
                                                        onChange={formikProps.handleChange('date')} ></input>}
                                                </div>
                                                <input type="radio" value={radio} checked={radio === 'true' ? true : false} name="transferRadio" onChange={() => handleTransfer(true)} />
                                                &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" value={radio} checked={radio === 'false' ? true : false} name="transferRadio" onChange={() => handleTransfer(false)} />
                                                &nbsp;<label >No</label>

                                            </div>
                                        </div> */}
                                        <div className="mb-3">
                                            <div>
                                                <div> <span>This is sample video?</span>

                                                </div>
                                                <input type="radio" name="sampleVideo" checked={formikProps.values.sampleVideo} onChange={() => formikProps.setFieldValue(`sampleVideo`, true)}
                                                    onBlur={formikProps.handleBlur(`sampleVideo`)} value={formikProps.values.sampleVideo} />
                                                &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input type="radio" name="sampleVideo" checked={!formikProps.values.sampleVideo} onChange={() => formikProps.setFieldValue(`sampleVideo`, false)}
                                                    onBlur={formikProps.handleBlur(`sampleVideo`)} value={formikProps.values.sampleVideo} />
                                                &nbsp;<label >No</label>

                                            </div>
                                            <ErrorMessage
                                                name={`sampleVideo`}
                                                component="div"
                                                className="field-error"
                                                style={{ color: 'red' }}
                                            />
                                        </div>
                                        <hr style={{
                                            color: 'red',
                                            backgroundColor: 'red',
                                            height: .5,
                                            borderColor: '#000000'
                                        }} />

                                        <CRow>
                                            <CCol xs={5}> </CCol>
                                            {isLoading && <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary"><CSpinner component="span" size="sm" aria-hidden="true" />Update</button> </CCol>}
                                            {!isLoading && <CCol xs={7}> <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Update</button> </CCol>}

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default EditVideo;