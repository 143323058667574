import React from 'react'
import { useHistory } from "react-router-dom";
import userApi from 'src/utils/axios';
import ConfigData from './../../../../config/constant.json'
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CCardImage } from '@coreui/react'
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import AddCourse from './AddCourse';
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { Link } from 'react-router-dom';
import DeleteDailog from '../DeleteDailog';
import EditCourse from './EditCourse';
import CIcon from '@coreui/icons-react'
import { cilList } from '@coreui/icons'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import "../Institute/style.css"
import WarningBox from 'src/views/shared/WarningBox';
import Page500 from '../../page500/Page500';

const Course = (props) => {
    if (!props.location.state) {
        return <Page500></Page500>
    }
    const ref = React.useRef(null);
    const [modalState, setModalState] = React.useState(false)
    const [editCourseState, setEditCourseState] = React.useState(false)
    const [meetingSchedule, setmeetingSchedule] = React.useState(false)
    const [deleteDailog, setDeleteDailog] = React.useState(false);
    const [courses, setCourses] = React.useState(false)
    const [courseId, setCourseId] = React.useState(null)
    const [courseDetails, setCourseDetails] = React.useState(null)
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [warningBox, setWarningBox] = React.useState(false)
    const [openIndex, setOpenIndex] = React.useState(-1);
    const [id, setid] = React.useState(false)
    const [role, setRole] = React.useState(true);

    let description = "Deleting course will delete all the modules, batches containing analytics, videos, resources and quiz. Also if a student has purchased the selected course it will also get deleted permanently."

    let instituteId = props.location.state.instituteId
    let instituteName = props.location.state.instituteName

    React.useEffect(() => {
        const userRoles = localStorage.getItem("role");
        if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
            setRole(false);
        } else {
            setRole(true);
        }
    }, [])

    React.useEffect(async () => {
        try {
            // Gets all the courses by institute id
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/course/getcourses?institute=${instituteId}`)
            setCourses(response.data.data)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])

    const handleAllDelete = async () => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/course/deletecoursealldata`, { courseId })


            if (response.data.status == false) {
                throw Error(response.data.message);
            }

            setCourses(courses.filter((mode) => { return mode._id !== courseDetails._id }))
            setDeleteDailog(false)
        } catch (e) {
            console.log(e)

        }


    }

    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Add Course Callback
    let addCourseCallBack = (newcourse) => {
        setCourses([...courses, newcourse])
        setModalState(false)
    }
    // this fuction enable warning modal
    let deleteCourse = (option) => {
        setCourseId(option._id)
        setCourseDetails(option)
        setWarningBox(true)
    }
    // this fuction enable delete modal
    let confirmDeleteCallBack = () => {
        setDeleteDailog(true)
    }

    let editCourse = (option) => {
        setCourseDetails(option)
        setEditCourseState(true)

    }


    let editCourseCallback = (editedCourse) => {
        let index = courses.findIndex((courseId) => courseId._id === editedCourse._id);
        courses[index] = editedCourse;
        setCourses([...courses]);
        setEditCourseState(false)

    }

    let history = useHistory();

    const handleView = (id) => {
        console.log("View", id);
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenIndex(index); // set current menu index to open

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div style={{ margin: 20 }}>
                <CRow>
                    <CCol sm={12}>
                        <CCard style={{ background: "white" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={1}><CButton onClick={() => history.push({ pathname: '/pages/master/institute', state: { instituteName: instituteName, instituteId: instituteId } })} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Back</CButton></CCol>
                                    <CCol sm={9}> <CCardTitle style={{ color: "black", textAlign: 'center' }}>{instituteName}</CCardTitle></CCol>

                                    {
                                        role &&
                                        <CCol sm={2}><CButton onClick={() => setModalState(true)} style={{ color: "black", backgroundColor: "rgb(214, 178, 49)", width: '100%' }} >Add Course</CButton></CCol>

                                    }

                                    {
                                        modalState &&
                                        <AddCourse
                                            visible={modalState}
                                            onClose={() => setModalState(false)}
                                            addToaster={addToaster}
                                            addCourseCallBack={addCourseCallBack}
                                            instituteId={instituteId}></AddCourse>
                                    }
                                    {
                                        editCourseState &&
                                        <EditCourse
                                            visible={editCourseState}
                                            onClose={() => setEditCourseState(false)}
                                            course={courseDetails}
                                            addToaster={addToaster}
                                            editCourseCallback={editCourseCallback}
                                            instituteId={instituteId}></EditCourse>
                                    }

                                    {
                                        deleteDailog && <DeleteDailog
                                            visible={deleteDailog}
                                            onClose={() => setDeleteDailog(false)}
                                            courseId={courseId}
                                            courseDetails={courseDetails}
                                            delete={handleAllDelete}
                                        // allDelete={handleDelete}
                                        ></DeleteDailog>
                                    }
                                    {
                                        warningBox && <WarningBox
                                            onClose={() => setWarningBox(false)}
                                            visible={warningBox}
                                            description={description}
                                            confirmDeleteCallBack={confirmDeleteCallBack}
                                        ></WarningBox>
                                    }

                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <br></br>
                <h3>Available Courses:-</h3>
                <h6>(Select Course to Add Modules)</h6>
                <br></br>

                <CRow>
                    {
                        loading && <LoadingScreen></LoadingScreen>
                    }
                    {
                        <div className='container'>
                            <div className='row'>
                                {!loading && courses && courses.length > 0 &&
                                    courses.map((option, index) => (
                                        <div className="col-md-6 p-2" key={index}>
                                            <CCard key={index} style={{ backgroundColor: "#B0F3AF", boxShadow: "10px 10px 5px lightblue", paddingBottom: '10px' }}>
                                                <CRow >
                                                    <CCol sm={3} style={{ marginTop: "5%", paddingLeft: "5%" }}>
                                                        <Link style={{ textDecoration: "none" }}
                                                            to={{
                                                                pathname: '/pages/master/module',
                                                                state: { courseName: option.courseName, courseId: option._id, published: option.published, instituteId: instituteId, instituteName: instituteName }
                                                            }}>
                                                            <CCardImage style={{ width: 100, height: 100 }} orientation="top" src={`${ConfigData.SERVER_URL}/images/course1.png`} />
                                                        </Link>
                                                    </CCol>
                                                    <CCol sm={9}>
                                                        <CardHeader
                                                            title={
                                                                <Link style={{ textDecoration: "none", color: 'white', textTransform: "capitalize" }}
                                                                    to={{
                                                                        pathname: '/pages/master/module',
                                                                        state: { courseName: option.courseName, courseId: option._id, published: option.published, instituteId: instituteId, instituteName: instituteName }
                                                                    }}>

                                                                    {`${option.courseName}`}
                                                                </Link>}

                                                            action={
                                                                <IconButton aria-label="settings" onClick={handleClick(index)} style={{ color: 'rgb(214, 178, 49)' }}>
                                                                    <MoreVertIcon
                                                                        id="fade-button"
                                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={open ? 'true' : undefined}

                                                                    />
                                                                </IconButton>
                                                            }
                                                        />{role &&

                                                            <Menu
                                                                id="fade-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'fade-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open && index === openIndex}
                                                                onClose={handleClose}
                                                                TransitionComponent={Fade}
                                                            >
                                                                <MenuItem onClick={() => { editCourse(option), setAnchorEl(null) }}>Edit</MenuItem>
                                                                <MenuItem onClick={() => { deleteCourse(option), setAnchorEl(null) }}>Delete</MenuItem>
                                                            </Menu>
                                                        }
                                                        <Link style={{ textDecoration: "none", display: "flex", marginLeft: "20px" }}
                                                            to={{
                                                                pathname: '/pages/master/module',
                                                                state: { courseName: option.courseName, qualificationEligibility: option.qualificationEligibility, courseId: option._id, published: option.published, instituteId: instituteId, instituteName: instituteName }
                                                            }}>
                                                            <label style={{ marginTop: 0, color: "black" }} className='text-sm'>Eligibility : </label>
                                                            <span style={{ marginTop: 0, color: "black", fontWeight: "bold" }}>{'  '} {option.qualificationEligibility}</span>
                                                        </Link>

                                                        <Link style={{ textDecoration: "none", display: "flex", marginLeft: "20px" }}
                                                            to={{
                                                                pathname: '/pages/master/module',
                                                                state: { courseName: option.courseName, courseId: option._id, published: option.published, instituteId: instituteId, instituteName: instituteName }
                                                            }}>
                                                            <label style={{ marginTop: 0, color: "black" }} className='text-sm'>Description : </label>
                                                            <span style={{ marginTop: 0, color: "black", fontWeight: "bold" }}> {option.description.substring(0, 70)}{option.description.length > 70 ? "..." : ""}</span>
                                                        </Link>
                                                    </CCol>
                                                </CRow>
                                            </CCard>
                                        </div>
                                    ))}
                            </div>

                        </div>
                    }
                    {
                        !loading && courses.length == 0 && !error && <h1> No Courses Added</h1>
                    }
                    {
                        !loading && error && <h1>{error}</h1>
                    }
                </CRow>

            </div >
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    );
}


export default Course;