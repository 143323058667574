import React, { useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import ConfigData from '../../../../config/constant.json'
import { CCard, CButton, CCardBody, CCol, CRow, CCardTitle, CFormInput, CSpinner } from '@coreui/react'
import Toaster from 'src/views/shared/Toaster';
import LoadingScreen from 'src/views/shared/Loading';
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ExcelFile from 'src/views/shared/ExcelFile';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { debounce } from 'lodash';

const Revenue = () => {
    const [transactions, setTransactions] = React.useState([])
    const [toast, addToast] = React.useState(false)
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [date, setDate] = useState(null);
    // Pagination logic
    const [pages, setPages] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [search, setSearch] = React.useState(false);
    const itemsPerPage = 10;
    const [loader, setLoader] = React.useState(false);//For search

    let history = useHistory();

    React.useEffect(async () => {
        // Get intital 10 transactions 
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getTransctions`)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            setTransactions(response.data.data)
            setPages(response.data.pages)
            setLoading(false)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, [])


    // Function to Add Toaster
    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    const handleExcelFile = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getExcelDataTransctions?dateRange=${date}`)
            if (response.data.status == true) {
                return response.data.data;
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    React.useMemo(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(pages / itemsPerPage));
    }, [itemOffset, itemsPerPage, pages]);

    //Display per pages transactions
    const handlePageClick = async (event) => {
        try {
            const newOffset = (event.selected * itemsPerPage) % pages;
            setItemOffset(newOffset);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getTransctions?page=${event.selected}&limit=${itemsPerPage}`)
            setTransactions(response.data.data)
        } catch (e) {
            setError(`${e.message} :(`)
            setLoading(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    };

    //Searches transations by orderId,name,email
    const handleSearch = debounce(async (e) => {
        try {
            let search = e.target.value.replace('(', '').replace('[', '').replace('?', '').replace('\\', '').replace('*', '');
            setLoader(true);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/analytics/getTransctionsSearch?search=${search}`)
            if (response.data.message) {
                setTransactions([])
                setLoader(false)
                setSearch(response.data.message)
            } else {
                setTransactions(response.data.data)
                setLoader(false)
                setSearch(false)
            }
        } catch (e) {
            setLoader(false)
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            addToaster(toast)
        }
    }, 400);
    return (
        <>
            <CRow>
                <CCol sm={12}>
                    <CCard style={{ background: "#3c4b64" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={3}><CButton onClick={() => history.goBack()} style={{ color: "black", backgroundColor: "white" }} >Back</CButton> </CCol>
                                <CCol sm={6}><CCardTitle style={{ color: "white" }}>User Revenue 😀 </CCardTitle></CCol>
                                <CCol sm={3}>
                                    <div style={{ width: '100%', marginBottom: '1%', display: 'flex', flexDirection: 'row' }}>
                                        {loader && <div style={{ marginRight: '6%' }} ><CSpinner component="span" size='sm' color='white' aria-hidden="true" /></div>}

                                        <CFormInput
                                            placeholder="Search"
                                            autoComplete="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </CCol>

                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            {
                loading && <LoadingScreen></LoadingScreen>
            }

            {!loading &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '1%' }}>
                    <div style={{ marginRight: '1%' }}>
                        <DateRangePicker onChange={setDate} value={date} />
                    </div>

                    <div>
                        <ExcelFile data={handleExcelFile} />
                    </div>
                </div>

            }

            {
                !loading && !error &&
                <div>
                    <h5 style={{ marginBottom: '1%' }}>Filtered Revenue count:<span><b>{pages}</b></span></h5>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>order Id</th>
                                <th>Transaction Name</th>
                                <th>User Email</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <tr key={item._id}>
                                        <td>{item.order_id}</td>
                                        <td>{item.billing_name}</td>
                                        <td>{item.merchant_param2}</td>
                                        <td>{'₹' + item.amount}</td>
                                        <td>{item.status}</td>
                                        <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    {
                        !loader && search && transactions.length == 0 && <h3 style={{ margin: '3%', textAlign: 'center' }}>{search}</h3>
                    }
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />

                </div>
            }
            {
                !loading && error && <h1>{error}</h1>
            }

            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }

        </>
    );
}

export default Revenue;